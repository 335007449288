"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomProfileFormType } from "@v2/feature/custom-fields/custom-profile-fields.dto";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { capitalize, keyBy } from "lodash";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserCustomField } from "@/v2/feature/user/components/user-custom-field.component";
import { useUserFieldsContext } from "@/v2/feature/user/context/user-fields.context";
import { UserAPI, UserEndpoints } from "@/v2/feature/user/user.api";
import { ProfilePicture } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/components/profile-picture.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { translateCustomField, translateCustomFieldCategory } from "@/v2/infrastructure/i18n/translate.util";
import { spacing } from "@/v2/styles/spacing.styles";
const BasicInfoSchema = (defaultFieldsMap, polyglot) => Yup.object().shape({
  firstName: defaultFieldsMap["firstName"] && defaultFieldsMap["firstName"].isRequired ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired(),
  lastName: defaultFieldsMap["lastName"] && defaultFieldsMap["lastName"].isRequired ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired(),
  middleName: defaultFieldsMap["middleName"] && defaultFieldsMap["middleName"].isRequired ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().nullable().notRequired(),
  displayName: defaultFieldsMap["displayName"] && defaultFieldsMap["displayName"].isRequired ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired()
});
export const UserBasicInfoSection = ({ onNext, sx, userId }) => {
  var _a, _b, _c, _d, _e, _f;
  const { data: basicInfo } = useApiClient(UserEndpoints.getUserBasicInfo(userId), {
    suspense: false
  });
  const { polyglot } = usePolyglot();
  const { getCachedDefaultFieldsByFormName, getCachedCustomFieldsByFormName } = useUserFieldsContext();
  const autoPopulateDisplayName = React.useMemo(() => !(basicInfo == null ? void 0 : basicInfo.displayName), [basicInfo == null ? void 0 : basicInfo.displayName]);
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const defaultFields = getCachedDefaultFieldsByFormName(CustomProfileFormType.Basic);
  const customFields = getCachedCustomFieldsByFormName(CustomProfileFormType.Basic);
  const defaultFieldsMap = useMemo(() => {
    return keyBy(defaultFields, "fieldStub");
  }, [defaultFields]);
  const customFieldIds = useMemo(() => new Set((customFields != null ? customFields : []).map((field) => field.fieldId)), [customFields]);
  const initialValues = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2;
    const values = {};
    if (defaultFieldsMap["firstName"]) values.firstName = (_a2 = basicInfo == null ? void 0 : basicInfo.firstName) != null ? _a2 : "";
    if (defaultFieldsMap["lastName"]) values.lastName = basicInfo == null ? void 0 : basicInfo.lastName;
    if (defaultFieldsMap["middleName"]) values.middleName = (_b2 = basicInfo == null ? void 0 : basicInfo.middleName) != null ? _b2 : "";
    if (defaultFieldsMap["displayName"]) values.displayName = (_c2 = basicInfo == null ? void 0 : basicInfo.displayName) != null ? _c2 : "";
    values.customUpdates = (_e2 = (_d2 = basicInfo == null ? void 0 : basicInfo.customUpdates) == null ? void 0 : _d2.filter((update) => customFieldIds.has(update.fieldId))) != null ? _e2 : [];
    return values;
  }, [defaultFieldsMap, basicInfo, customFieldIds]);
  const formik = useFormik({
    initialValues,
    validationSchema: BasicInfoSchema(defaultFieldsMap, polyglot),
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        yield UserAPI.patchUserBasicInfo(userId, values);
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.basicInfoNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  useEffect(() => {
    if (!autoPopulateDisplayName || !formik.values.firstName || !formik.values.lastName) return;
    const newDisplayName = `${formik.values.firstName.trim()} ${formik.values.lastName.trim()}`;
    if (newDisplayName === formik.values.displayName) return;
    formik.setFieldValue("displayName", `${formik.values.firstName} ${formik.values.lastName}`);
  }, [autoPopulateDisplayName, formik]);
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: (_a = capitalize(translateCustomFieldCategory(CustomProfileFormType.Basic, polyglot))) != null ? _a : polyglot.t("OnboardingByUser.basicInfo") }),
    /* @__PURE__ */ jsx(Suspense, { fallback: /* @__PURE__ */ jsx(Fragment, {}), children: /* @__PURE__ */ jsx(ProfilePicture, { userId, disabled: saving }) }),
    defaultFieldsMap["firstName"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "firstName",
        label: (_b = translateCustomField(defaultFieldsMap["firstName"].fieldName, polyglot)) != null ? _b : polyglot.t("OnboardingByUser.firstName"),
        value: formik.values.firstName,
        onChange: formik.handleChange,
        disabled: saving,
        clearText: () => formik.setFieldValue("firstName", ""),
        helperText: hasSubmitted && formik.errors.firstName,
        error: hasSubmitted && !!formik.errors.firstName,
        autoFocus: true,
        required: defaultFieldsMap["firstName"].isRequired
      }
    ),
    defaultFieldsMap["lastName"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "lastName",
        label: (_c = translateCustomField(defaultFieldsMap["lastName"].fieldName, polyglot)) != null ? _c : polyglot.t("OnboardingByUser.lastName"),
        value: formik.values.lastName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("lastName", ""),
        helperText: hasSubmitted && formik.errors.lastName,
        error: hasSubmitted && !!formik.errors.lastName,
        required: defaultFieldsMap["lastName"].isRequired
      }
    ),
    defaultFieldsMap["middleName"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "middleName",
        label: (_d = translateCustomField(defaultFieldsMap["middleName"].fieldName, polyglot)) != null ? _d : polyglot.t("OnboardingByUser.middleName"),
        value: formik.values.middleName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("middleName", ""),
        helperText: hasSubmitted && formik.errors.middleName,
        error: hasSubmitted && !!formik.errors.middleName,
        required: defaultFieldsMap["middleName"].isRequired
      }
    ),
    defaultFieldsMap["displayName"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "displayName",
        label: (_e = translateCustomField(defaultFieldsMap["displayName"].fieldName, polyglot)) != null ? _e : polyglot.t("OnboardingByUser.displayName"),
        value: formik.values.displayName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("displayName", ""),
        helperText: hasSubmitted && formik.errors.displayName,
        error: hasSubmitted && !!formik.errors.displayName,
        required: defaultFieldsMap["displayName"].isRequired
      }
    ),
    /* @__PURE__ */ jsx(
      UserCustomField,
      {
        values: (_f = formik.values.customUpdates) != null ? _f : [],
        formFields: customFields != null ? customFields : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        loading: saving,
        sizeVariant: "large",
        colorVariant: "primary",
        fullWidth: true
      }
    )
  ] }) }) });
};
