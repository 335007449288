"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ListItem } from "@/v2/feature/growth/goals/components/list-item.component";
export const KeyResultListItem = ({ keyResult, hoverState, onClick, sx }) => {
  return /* @__PURE__ */ jsx(
    "div",
    {
      onClick: () => {
        if (onClick) {
          onClick(keyResult);
        }
      },
      children: /* @__PURE__ */ jsx(
        ListItem,
        {
          hoverState,
          progressBar: { progress: keyResult.lastProgress },
          details: {
            title: keyResult.name,
            description: keyResult.description ? keyResult.description : /* @__PURE__ */ jsx("br", {})
          },
          endSection: /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(
            ChipComponent,
            {
              name: keyResult.status,
              textVariant: "title4",
              backgroundColor: "white",
              textColor: "TextPrimary",
              border: "black2"
            }
          ) }),
          sx
        }
      )
    }
  );
};
