"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { useUserIdParam } from "@/hooks/userid-param.hook";
import { ReactComponent as Close } from "@/images/app-icons/Close.svg";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { USER_ONBOARDING_SUMMARY_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { StepperComponent } from "@/v2/components/stepper.component";
import { useUserFieldsContext } from "@/v2/feature/user/context/user-fields.context";
import { CustomProfileFormType } from "@/v2/feature/user/features/user-profile/details/user-profile.interface";
import { UserAboutSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-about.section";
import { UserAddressSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-address.section";
import { UserBankAccountSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-bank-account.section";
import { UserBasicInfoSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-basic-info.section";
import { UserCustomSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-custom.section";
import { UserEmergencyContactSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-emergency-contact.section";
import { UserFamilySection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-family.section";
import { UserPersonalInfoSection } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/sections/user-personal-info.section";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const iconSize = { width: 14, height: 14 };
const checkScopesForOnboardingFlow = (currentUser) => {
  const { userId } = currentUser;
  const hasBankingScope = checkScopes(currentUser, ["user.bankAccount"], { userId });
  const hasAddressScope = checkScopes(currentUser, ["user.address"], { userId });
  const hasEmergencyContactScope = checkScopes(currentUser, ["user.emergencyContact"], { userId });
  const hasAboutScope = checkScopes(currentUser, ["user.about"], { userId });
  return { hasBankingScope, hasAddressScope, hasEmergencyContactScope, hasAboutScope };
};
const removeStepsFromArray = (steps, stepsToRemove) => {
  return steps.filter((step) => !stepsToRemove.has(step));
};
const getStepsToRemove = (hasBankingScope, hasAddressScope, hasEmergencyContactScope, hasAboutScope) => {
  const stepsToRemove = /* @__PURE__ */ new Set();
  if (!hasBankingScope) {
    stepsToRemove.add("bank");
  }
  if (!hasAddressScope) {
    stepsToRemove.add("address");
  }
  if (!hasEmergencyContactScope) {
    stepsToRemove.add("emergency");
  }
  if (!hasAboutScope) {
    stepsToRemove.add("about");
  }
  return stepsToRemove;
};
export const OnboardingBasicFlow = (_) => {
  var _a;
  const userId = useUserIdParam();
  const [globalState] = useContext(GlobalContext);
  const { hasBankingScope, hasAddressScope, hasEmergencyContactScope, hasAboutScope } = checkScopesForOnboardingFlow(
    globalState.user
  );
  const routerHistory = useHistory();
  const { getCachedCustomForms, getCachedDefaultForms, forms } = useUserFieldsContext();
  const customForms = getCachedCustomForms();
  const defaultForms = getCachedDefaultForms();
  const OnboardingStepperSteps = useMemo(() => {
    var _a2, _b;
    let initialSteps = [
      ...(_a2 = defaultForms == null ? void 0 : defaultForms.map((form) => form.formName)) != null ? _a2 : [],
      ...(_b = customForms == null ? void 0 : customForms.map((form) => form.formName)) != null ? _b : []
    ];
    const defaultStepOrder = [
      CustomProfileFormType.Basic,
      CustomProfileFormType.Details,
      CustomProfileFormType.Family,
      CustomProfileFormType.About,
      CustomProfileFormType.BankAccount,
      CustomProfileFormType.Address,
      CustomProfileFormType.Emergency
    ];
    initialSteps.sort((a, b) => {
      const aIndex = defaultStepOrder.indexOf(a);
      const bIndex = defaultStepOrder.indexOf(b);
      if (aIndex === -1 && bIndex === -1) {
        return 0;
      } else if (aIndex === -1) {
        return 1;
      } else if (bIndex === -1) {
        return -1;
      } else {
        return aIndex - bIndex;
      }
    });
    const stepsToRemove = getStepsToRemove(hasBankingScope, hasAddressScope, hasEmergencyContactScope, hasAboutScope);
    return removeStepsFromArray(initialSteps, stepsToRemove);
  }, [hasAboutScope, hasAddressScope, hasBankingScope, hasEmergencyContactScope, defaultForms, customForms]);
  const [activeStep, setActiveStep] = useState((_a = OnboardingStepperSteps[0]) != null ? _a : CustomProfileFormType.Basic);
  const isFirstStep = useCallback(() => {
    return OnboardingStepperSteps.indexOf(activeStep) === 0;
  }, [activeStep, OnboardingStepperSteps]);
  const handleNext = useCallback(() => {
    const currentStepIdx = OnboardingStepperSteps.indexOf(activeStep);
    if (currentStepIdx >= OnboardingStepperSteps.length - 1) {
      routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }));
      return;
    }
    setActiveStep(OnboardingStepperSteps[currentStepIdx + 1]);
  }, [activeStep, routerHistory, userId, OnboardingStepperSteps]);
  const handleBack = useCallback(() => {
    setActiveStep((currentStep) => {
      const currentStepIdx = OnboardingStepperSteps.indexOf(currentStep);
      return OnboardingStepperSteps[Math.max(currentStepIdx - 1, 0)];
    });
  }, [OnboardingStepperSteps]);
  const stepRenderFunctions = useMemo(() => {
    if (!forms) {
      return {};
    }
    const defaultSection = {
      [CustomProfileFormType.Basic]: () => /* @__PURE__ */ jsx(
        UserBasicInfoSection,
        {
          onNext: () => {
            handleNext();
          },
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
          userId
        }
      ),
      [CustomProfileFormType.Details]: () => /* @__PURE__ */ jsx(
        UserPersonalInfoSection,
        {
          onNext: () => {
            handleNext();
          },
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
          userId
        }
      ),
      [CustomProfileFormType.Family]: () => /* @__PURE__ */ jsx(
        UserFamilySection,
        {
          onNext: () => {
            handleNext();
          },
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
          userId
        }
      ),
      [CustomProfileFormType.BankAccount]: () => /* @__PURE__ */ jsx(
        UserBankAccountSection,
        {
          onNext: () => {
            handleNext();
          },
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
          userId
        }
      ),
      [CustomProfileFormType.Address]: () => /* @__PURE__ */ jsx(
        UserAddressSection,
        {
          onNext: () => {
            handleNext();
          },
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
          userId
        }
      ),
      [CustomProfileFormType.Emergency]: () => /* @__PURE__ */ jsx(
        UserEmergencyContactSection,
        {
          onNext: () => {
            handleNext();
          },
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
          userId
        }
      ),
      [CustomProfileFormType.About]: () => /* @__PURE__ */ jsx(
        UserAboutSection,
        {
          onNext: () => {
            handleNext();
          },
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 },
          userId
        }
      )
    };
    let customSectionsSteps = customForms.reduce((acc, form) => {
      acc[form.formName] = () => /* @__PURE__ */ jsx(
        UserCustomSection,
        {
          onNext: () => {
            handleNext();
          },
          form,
          userId,
          sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 }
        },
        form.formName
      );
      return acc;
    }, {});
    return __spreadValues(__spreadValues({}, defaultSection), customSectionsSteps);
  }, [handleNext, userId, customForms, forms]);
  const renderStep = (step) => {
    const renderFunction = stepRenderFunctions[step];
    return renderFunction ? renderFunction() : null;
  };
  const labelMap = {
    [CustomProfileFormType.Basic]: "Basic",
    [CustomProfileFormType.Details]: "Details",
    [CustomProfileFormType.Family]: "Family",
    [CustomProfileFormType.About]: "About",
    [CustomProfileFormType.BankAccount]: "Bank account",
    [CustomProfileFormType.Address]: "Address",
    [CustomProfileFormType.Emergency]: "Emergency"
  };
  for (const form of customForms) {
    labelMap[form.formName] = form.formName;
  }
  function labelMapper(label) {
    var _a2;
    return (_a2 = labelMap[label]) != null ? _a2 : label;
  }
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, pt: spacing.p30 }, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", width: "70%", mx: "auto" }, children: [
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", left: spacing.m50 }),
          onClick: handleBack,
          disabled: isFirstStep(),
          children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize))
        }
      ),
      /* @__PURE__ */ jsx(
        StepperComponent,
        {
          sx: { flex: 1 },
          steps: OnboardingStepperSteps,
          activeStep,
          labelMapper
        }
      ),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", right: spacing.m50 }),
          onClick: () => routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId })),
          children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey }))
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Stack, { sx: { overflow: "hidden auto", pb: spacing.p30 }, children: renderStep(activeStep) })
  ] });
};
