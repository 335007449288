"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { KeyResultListItem } from "@/v2/feature/growth/goals/components/key-result-list-item.component";
import { ListItem } from "@/v2/feature/growth/goals/components/list-item.component";
import {
  KeyResultProgressAPI,
  KeyResultProgressEndpoints
} from "@/v2/feature/growth/key-result-progress/key-result-progress.api";
import {
  KeyResultStatus,
  keyResultStatusOptions
} from "@/v2/feature/growth/key-results/components/key-result.interface";
import { KeyResultAPI } from "@/v2/feature/growth/key-results/components/key-results.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const KeyResultDrawer = ({ keyResult, isOpen, onClosed, onKeyResultUpdated }) => {
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen: (isOpenVal) => {
        if (!isOpenVal) onClosed == null ? void 0 : onClosed();
      },
      widthPercentage: 96,
      onClose: () => {
        onClosed == null ? void 0 : onClosed();
      },
      children: /* @__PURE__ */ jsx(KeyResultDrawerContent, { keyResult, isOpen, onKeyResultUpdated })
    }
  );
};
export const KeyResultDrawerContent = ({ keyResult, isOpen, onKeyResultUpdated }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { data: progressItems, mutate: refreshProgressItems } = useApiClient(
    KeyResultProgressEndpoints.getKeyResultProgressItems((_a = keyResult == null ? void 0 : keyResult.goalId) != null ? _a : "", (_b = keyResult == null ? void 0 : keyResult.id) != null ? _b : ""),
    {
      suspense: false
    },
    Boolean(isOpen && (keyResult == null ? void 0 : keyResult.goalId) && keyResult.id)
  );
  const formik = useFormik({
    initialValues: {
      value: 0,
      comment: "",
      keyResultStatus: (_c = keyResult == null ? void 0 : keyResult.status) != null ? _c : KeyResultStatus.Active
    },
    validationSchema: yup.object({
      value: yup.number().required(polyglot.t("KeyResultDrawer.errorMessages.valueRequired")),
      comment: yup.string().notRequired(),
      keyResultStatus: yup.string()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      var _a2, _b2, _c2, _d;
      try {
        yield Promise.all([
          KeyResultProgressAPI.createKeyResultProgress((_a2 = keyResult == null ? void 0 : keyResult.goalId) != null ? _a2 : "", (_b2 = keyResult == null ? void 0 : keyResult.id) != null ? _b2 : "", {
            comment: values.comment,
            value: Number(values.value)
          }),
          KeyResultAPI.patchKeyResult((_c2 = keyResult == null ? void 0 : keyResult.goalId) != null ? _c2 : "", (_d = keyResult == null ? void 0 : keyResult.id) != null ? _d : "", {
            status: values.keyResultStatus
          })
        ]).catch((error) => {
          showMessage(nestErrorMessage(error), "error");
        });
        onKeyResultUpdated == null ? void 0 : onKeyResultUpdated();
        refreshProgressItems == null ? void 0 : refreshProgressItems();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("KeyResultDrawer.keyResult") }),
        actions: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "primary",
            onClick: () => {
              formik.handleSubmit();
            },
            children: polyglot.t("KeyResultDrawer.update")
          }
        ),
        showAction: true,
        sx: { display: "flex", justifyContent: "space-between", width: "100%" }
      }
    ),
    keyResult ? /* @__PURE__ */ jsx(KeyResultListItem, { keyResult, hoverState: false, sx: { padding: "1rem 0" } }) : null,
    /* @__PURE__ */ jsx(
      Box,
      {
        className: "sub-title",
        sx: {
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        children: /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("KeyResultDrawer.progress") })
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "3rem", marginTop: "1rem" }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "value",
          label: polyglot.t("KeyResultDrawer.formFields.progress"),
          value: formik.values.value,
          onChange: formik.handleChange,
          error: formik.touched.value && !!formik.errors.value,
          helperText: formik.touched.value && formik.errors.value,
          endAdornment: "none",
          fullWidth: true
        }
      ),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "keyResultStatus",
          label: polyglot.t("KeyResultDrawer.formFields.status"),
          options: keyResultStatusOptions,
          value: formik.values.keyResultStatus,
          onChange: formik.handleChange,
          compareValue: formik.values.keyResultStatus,
          error: !!formik.errors.keyResultStatus && formik.touched.keyResultStatus,
          helperText: formik.touched.keyResultStatus && formik.errors.keyResultStatus
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      Box,
      {
        className: "sub-title",
        sx: {
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        },
        children: /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("KeyResultDrawer.comments") })
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "0.75rem" }, children: [
      /* @__PURE__ */ jsx(UserCell, { sx: { flex: "auto" }, userId: user.userId }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("KeyResultDrawer.whatsNew") })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { marginTop: "1rem" }, children: /* @__PURE__ */ jsx(
      RichTextField,
      {
        value: "",
        onChange: (value) => formik.setFieldValue("comment", value),
        error: formik.touched.comment && !!formik.errors.comment,
        helperText: formik.touched.comment && formik.errors.comment
      }
    ) }),
    progressItems == null ? void 0 : progressItems.map((progressItem) => /* @__PURE__ */ jsx(
      ListItem,
      {
        details: {
          description: progressItem.comment,
          chips: /* @__PURE__ */ jsx(UserCell, { userId: progressItem.createdBy })
        },
        sx: { padding: "1rem 0" },
        hoverState: false
      },
      progressItem.id
    ))
  ] }) });
};
