"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import ReactApexChart from "react-apexcharts";
import {
  DEFAULT_CHART_HEIGHT,
  DEFAULT_CHART_FONT
} from "@/v2/components/charts/chart.interface";
import { normaliseYAxis } from "@/v2/components/charts/utils/chart.util";
import { themeColors, PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR } from "@/v2/styles/colors.styles";
import "@/v2/components/charts/styles/apex-charts.styles.css";
export function MixedChart({
  series,
  categories,
  yAxis,
  categoriesType = "category",
  options: inputOptions
}) {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const defaultOptions = {
    yAxisOptions: void 0,
    horizontal: false,
    percentage: false,
    tooltip: {
      enabled: true
    },
    legend: { show: series.length > 1, align: "right", position: "top" }
  };
  const options = __spreadProps(__spreadValues(__spreadValues({}, defaultOptions), inputOptions), {
    height: (_a = inputOptions == null ? void 0 : inputOptions.height) != null ? _a : DEFAULT_CHART_HEIGHT,
    colors: ((_b = inputOptions == null ? void 0 : inputOptions.colors) == null ? void 0 : _b.length) ? inputOptions.colors : [...PRIMARY_CHART_COLOR, ...SECONDARY_CHART_COLOR],
    noDataTitle: (_c = inputOptions == null ? void 0 : inputOptions.noDataTitle) != null ? _c : polyglot.t("Charts.noData")
  });
  const yAxisConfig = yAxis.map(
    (_, index) => {
      var _a2, _b2;
      return normaliseYAxis(series, (_a2 = options.percentage) != null ? _a2 : false, (_b2 = options.yAxisOptions) == null ? void 0 : _b2[index]);
    }
  );
  const yAxisArray = yAxis.map((yAxis2, index) => __spreadValues(__spreadValues({
    tickAmount: 5,
    labels: {
      style: {
        fontSize: "12px"
      }
    }
  }, yAxis2), yAxisConfig[index]));
  const strokeColorsArray = series.map(
    (series2, index) => series2.type === "bar" ? "transparent" : options.colors[index]
  );
  const chartOptions = {
    chart: {
      fontFamily: DEFAULT_CHART_FONT,
      stacked: false,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: options.horizontal,
        borderRadius: 5,
        borderRadiusApplication: "end"
      }
    },
    xaxis: {
      type: categoriesType,
      categories,
      labels: {
        show: categories.length > 0,
        style: {
          colors: Array(categories.length).fill(themeColors.Grey),
          fontWeight: 400,
          fontSize: "12px"
        }
      }
    },
    yaxis: yAxisArray,
    tooltip: {
      enabled: (_d = options.tooltip) == null ? void 0 : _d.enabled,
      onDatasetHover: {
        highlightDataSeries: false
      },
      style: { fontWeight: 400, fontSize: "12px" },
      custom: (_e = options.tooltip) == null ? void 0 : _e.custom
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      colors: strokeColorsArray,
      width: 4
    },
    legend: {
      show: (_f = options.legend) == null ? void 0 : _f.show,
      horizontalAlign: (_g = options.legend) == null ? void 0 : _g.align,
      position: (_h = options.legend) == null ? void 0 : _h.position,
      fontWeight: 400,
      fontSize: "12px",
      labels: { colors: [themeColors.DarkGrey] },
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: "#fff",
        radius: 16
      }
    },
    colors: options.colors,
    noData: { text: options.noDataTitle },
    states: {
      hover: {
        filter: {
          type: "none"
        }
      }
    }
  };
  return /* @__PURE__ */ jsx(ReactApexChart, { type: "line", series, options: chartOptions, height: options.height });
}
