"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Switch } from "react-router-dom";
import { GoalsListPage } from "./pages/goals-list.page";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { GROWTH_COMPANY_GOALS_ROUTE, GROWTH_ME_GOALS_ROUTE, GROWTH_TEAM_GOALS_ROUTE } from "@/lib/routes";
export const GoalsCompanyRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const context = getScopesContext(globalState.user);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context,
        scopes: ["goals:all"],
        path: GROWTH_COMPANY_GOALS_ROUTE,
        component: GoalsListPage
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context,
        scopes: ["goals:manager"],
        path: GROWTH_TEAM_GOALS_ROUTE,
        component: GoalsListPage
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { context, scopes: ["goals"], path: GROWTH_ME_GOALS_ROUTE, component: GoalsListPage })
  ] });
};
