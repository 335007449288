"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { TimePickerComponent } from "@v2/components/forms/time-picker.component";
import { SingleUserSelect } from "@v2/components/forms/user-select/single-user-select.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import {
  DevicePossessionType,
  DeviceShippingMethod,
  EnrolmentType,
  WipeMethod
} from "@v2/feature/device/device.interface";
import { DeviceShippingMethodsOptions, sanitizeDepAccountName } from "@v2/feature/device/device.util";
import { EnrollmentStatus } from "@v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { UserAddressAPI } from "@v2/feature/user/features/user-forms/user-address/user-address.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { isValidTimeString } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { StyledRadio } from "@v2/styles/radio.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { formatAddress } from "@v2/util/user-data.util";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { SiteAPI } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReassignDeviceUserForm = ({
  currentDevicePossession,
  reassignDevice,
  isInventory
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const [loading, setLoading] = useState(false);
  const [senderAddress, setSenderAddress] = useState("");
  const [sitesOptions, setSitesOptions] = useState([]);
  const { hasScopes, getScopesContext } = useScopes();
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const { polyglot } = usePolyglot();
  const containsSpecialCharacters = (input) => {
    const regex = /[^a-z0-9]/;
    return regex.test(input.toLowerCase());
  };
  const getAccountName = (newUserId) => {
    var _a2, _b2;
    return sanitizeDepAccountName((_b2 = (_a2 = getCachedUserById(newUserId)) == null ? void 0 : _a2.firstName) != null ? _b2 : "testZelt");
  };
  const validationSchema = yup.object({
    shippingMethod: yup.string().required(polyglot.t("ReassignDevice.validationShippingMethodRequired")),
    newUserId: yup.number().required(polyglot.t("ReassignDevice.validationNewUserRequired")),
    deliveryAddress: yup.string().trim().when("shippingMethod", {
      is: (shippingMethod) => shippingMethod === DeviceShippingMethod.RequestZeltShipping && !isInventory,
      then: (schema) => schema.required(polyglot.t("ReassignDevice.validationDestinationNotAssigned")).min(1, polyglot.t("ReassignDevice.validationDestinationCannotBeEmpty")),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    deviceAccountName: yup.string().nullable().notRequired().test("no-special-chars", polyglot.t("ReassignDevice.validationDeviceAccountNameInvalid"), (value) => {
      if (!value) return true;
      return !containsSpecialCharacters(value);
    }),
    wipeMethod: yup.string().required(polyglot.t("ReassignDevice.validationWipeMethodRequired")),
    wipeLaterDate: yup.string().nullable().when("wipeMethod", {
      is: WipeMethod.WipeLater,
      then: (schema) => schema.required(polyglot.t("ReassignDevice.validationWipeDateRequired")),
      otherwise: (schema) => schema.notRequired()
    }),
    wipeLaterTime: yup.string().nullable().when("wipeMethod", {
      is: WipeMethod.WipeLater,
      then: (schema) => schema.required(polyglot.t("ReassignDevice.validationWipeTimeRequired")),
      otherwise: (schema) => schema.notRequired()
    })
  });
  const formik = useFormik({
    initialValues: {
      shippingMethod: currentDevicePossession.possessionType === DevicePossessionType.ZeltStorage ? DeviceShippingMethod.RequestZeltShipping : DeviceShippingMethod.NoTrackingReassignInstantly,
      newUserId: 0,
      senderAddress,
      deliveryAddress: "",
      deviceAccountName: "",
      wipeMethod: ((_a = currentDevicePossession == null ? void 0 : currentDevicePossession.device) == null ? void 0 : _a.inHouseMdm) && ((_b = currentDevicePossession == null ? void 0 : currentDevicePossession.device) == null ? void 0 : _b.enrollmentStatus) === "ENROLMENT_FINISHED" ? WipeMethod.WipeNow : WipeMethod.WipeManually,
      inventory: isInventory,
      wipeLaterDate: dayjs().format("YYYY-MM-DD"),
      wipeLaterTime: null
    },
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      const {
        shippingMethod,
        newUserId,
        wipeMethod,
        deliveryAddress,
        deviceAccountName,
        inventory,
        wipeLaterTime,
        wipeLaterDate
      } = values;
      if (currentDevicePossession.possessionType === DevicePossessionType.User && currentDevicePossession.possessionId === newUserId) {
        showMessage(polyglot.t("ReassignDevice.theSameSenderAndReceiverError"), "error");
        return;
      }
      if (!inventory && !newUserId) {
        showMessage(polyglot.t("ReassignDevice.noReceiverSelectedError"), "error");
        return;
      }
      const reassignDeviceData = {
        shippingMethod,
        newUserId,
        senderAddress,
        deliveryAddress,
        wipeMethod,
        deviceAccountName,
        inventory,
        wipeLaterDate: wipeMethod === WipeMethod.WipeLater ? wipeLaterDate : null,
        wipeLaterTime: wipeMethod === WipeMethod.WipeLater ? wipeLaterTime : null
      };
      setLoading(true);
      yield reassignDevice(currentDevicePossession.id, reassignDeviceData);
      setLoading(false);
    }),
    enableReinitialize: true,
    validateOnMount: true
  });
  const handleDeviceAccountNameChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = sanitizeDepAccountName(inputValue);
    if (sanitizedValue !== inputValue) {
      showMessage(polyglot.t("ReassignDevice.deviceAccountNameInvalidChars"), "error");
    }
    formik.setFieldValue("deviceAccountName", sanitizedValue);
  };
  const fetchOwnerAddress = useCallback(
    (devicePossession) => __async(void 0, null, function* () {
      try {
        if (devicePossession.possessionType === DevicePossessionType.User) {
          const hasReadAddressScope = hasScopes(
            ["user.address:read"],
            getScopesContext({ userId: devicePossession.possessionId })
          );
          if (!hasReadAddressScope) return "";
          const userAddress = yield UserAddressAPI.findByUserId(devicePossession.possessionId);
          if (userAddress && userAddress.effectiveRecord) {
            return formatAddress(userAddress.effectiveRecord);
          }
        }
      } catch (error) {
        showMessage(
          polyglot.t("ReassignDevice.errorRetrievingUserDetails", {
            error: nestErrorMessage(error)
          }),
          "error"
        );
      }
      return "";
    }),
    [getScopesContext, hasScopes, showMessage, polyglot]
  );
  const fetchReceiverAddress = useCallback(
    (possessionId) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const userAddress = yield UserAddressAPI.findByUserId(possessionId);
        if (userAddress && userAddress.effectiveRecord) {
          return formatAddress(userAddress.effectiveRecord);
        }
      } catch (error) {
        console.error(polyglot.t("ReassignDevice.errorRetrievingUserAddress"));
      } finally {
        setLoading(false);
      }
      return "";
    }),
    [polyglot]
  );
  const setOwnerAddress = useCallback(() => __async(void 0, null, function* () {
    const ownerAddress = yield fetchOwnerAddress(currentDevicePossession);
    setSenderAddress(ownerAddress);
  }), [fetchOwnerAddress, currentDevicePossession]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      if (!isInventory) return;
      try {
        const sites = yield SiteAPI.listSites();
        const defaultSite = [
          {
            value: -1,
            label: polyglot.t("ReassignDevice.inventoryStorageUk"),
            address: ""
          },
          {
            value: -2,
            label: polyglot.t("ReassignDevice.inventoryStorageEu"),
            address: ""
          }
        ];
        setSitesOptions([
          ...sites.map((site) => {
            var _a2;
            return {
              value: site.id,
              label: site.name,
              address: (_a2 = site.address) != null ? _a2 : ""
            };
          }),
          ...defaultSite
        ]);
      } catch (error) {
        showMessage(
          polyglot.t("ReassignDevice.errorRetrievingSitesList", {
            error: nestErrorMessage(error)
          }),
          "error"
        );
      }
    }))();
  }, [showMessage, isInventory, polyglot]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      yield setOwnerAddress();
    }))();
  }, [setOwnerAddress]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    isInventory ? /* @__PURE__ */ jsx(
      TitleSubHeaderActionComponent,
      {
        title: polyglot.t("ReassignDevice.titleStep1Inventory"),
        action: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "newUserId",
            label: polyglot.t("ReassignDevice.selectSiteLabel"),
            options: sitesOptions,
            value: formik.values.newUserId,
            compareValue: formik.values.newUserId,
            onChange: (e) => __async(void 0, null, function* () {
              var _a2, _b2;
              const siteId = Number(e.target.value);
              const receiverAddress = (_b2 = (_a2 = sitesOptions.find((s) => s.value === siteId)) == null ? void 0 : _a2.address) != null ? _b2 : "";
              yield formik.setFieldValue("newUserId", siteId);
              yield formik.setFieldValue("deliveryAddress", receiverAddress);
              if (siteId === -1 || siteId === -2) {
                yield formik.setFieldValue("shippingMethod", DeviceShippingMethod.RequestZeltShipping);
              } else {
                yield formik.setFieldValue("shippingMethod", DeviceShippingMethod.NoTrackingReassignInstantly);
              }
            }),
            placeholder: polyglot.t("ReassignDevice.selectSitePlaceholder")
          }
        )
      }
    ) : /* @__PURE__ */ jsx(
      TitleSubHeaderActionComponent,
      {
        title: polyglot.t("ReassignDevice.titleStep1NewUser"),
        action: /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(
            SingleUserSelect,
            {
              name: "newUserId",
              options: "company",
              value: formik.values.newUserId === 0 ? null : formik.values.newUserId,
              onChange: (_, x) => __async(void 0, null, function* () {
                var _a2;
                const userId = (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null;
                yield formik.setFieldValue("newUserId", userId);
                if (userId && userId !== 0) {
                  const receiverAddress = yield fetchReceiverAddress(userId);
                  yield formik.setFieldValue("deliveryAddress", receiverAddress);
                  yield formik.setFieldValue("deviceAccountName", getAccountName(userId));
                } else {
                  yield formik.setFieldValue("deliveryAddress", "");
                }
              }),
              label: polyglot.t("ReassignDevice.selectEmployeeLabel"),
              error: Boolean(formik.errors.newUserId),
              helperText: formik.errors.newUserId,
              placeholder: polyglot.t("ReassignDevice.selectEmployeePlaceholder")
            }
          ),
          ((_c = currentDevicePossession == null ? void 0 : currentDevicePossession.device) == null ? void 0 : _c.inHouseMdm) && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "deviceAccountName",
              label: polyglot.t("ReassignDevice.deviceAccountNameLabel"),
              value: (_d = formik.values.deviceAccountName) != null ? _d : "",
              type: "text",
              onChange: handleDeviceAccountNameChange,
              error: formik.touched.deviceAccountName && !!formik.errors.deviceAccountName,
              helperText: polyglot.t("ReassignDevice.deviceAccountNameHelperText"),
              clearText: () => formik.setFieldValue("deviceAccountName", "")
            }
          ) })
        ] })
      }
    ),
    /* @__PURE__ */ jsx(
      TitleSubHeaderActionComponent,
      {
        title: "Step 2: Shipping",
        subheader: polyglot.t("ReassignDevice.subheaderStep2Shipping"),
        action: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "shippingMethod",
            label: polyglot.t("ReassignDevice.methodLabel"),
            options: DeviceShippingMethodsOptions,
            value: formik.values.shippingMethod,
            compareValue: formik.values.shippingMethod,
            error: !!formik.errors.shippingMethod && formik.touched.shippingMethod,
            onChange: formik.handleChange,
            disabled: formik.values.newUserId === -1 || formik.values.newUserId === -2 || currentDevicePossession.possessionType === DevicePossessionType.ZeltStorage,
            helperText: formik.touched.shippingMethod && formik.errors.shippingMethod
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      TitleSubHeaderActionComponent,
      {
        title: "Step 3: Wipe",
        subheader: polyglot.t("ReassignDevice.subheaderStep3Wipe"),
        action: /* @__PURE__ */ jsxs(FormControl, { sx: { width: "100%", marginTop: "-8px" }, children: [
          /* @__PURE__ */ jsxs(
            RadioGroup,
            {
              "aria-labelledby": "wipe-method-group-label",
              name: "wipeMethod",
              value: formik.values.wipeMethod,
              onChange: formik.handleChange,
              sx: { gap: "10px" },
              children: [
                ((_e = currentDevicePossession == null ? void 0 : currentDevicePossession.device) == null ? void 0 : _e.inHouseMdm) && ((_f = currentDevicePossession == null ? void 0 : currentDevicePossession.device) == null ? void 0 : _f.enrollmentStatus) === "ENROLMENT_FINISHED" && /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    labelPlacement: "end",
                    value: "wipeNow",
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ReassignDevice.wipeNowLabel") })
                  },
                  "wipeNow"
                ),
                /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    labelPlacement: "end",
                    value: "wipeManually",
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ReassignDevice.wipeManuallyLabel") })
                  },
                  "wipeManually"
                ),
                /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    labelPlacement: "end",
                    value: "wipeLater",
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ReassignDevice.wipeLaterLabel") })
                  },
                  "wipeLater"
                ),
                ((_g = currentDevicePossession.device) == null ? void 0 : _g.enrolmentType.toString()) === EnrolmentType.OPEN_ENROLMENT && ((_h = currentDevicePossession.device) == null ? void 0 : _h.enrollmentStatus) === EnrollmentStatus.ENROLMENT_FINISHED.toString() && /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    labelPlacement: "end",
                    value: "disenrol",
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ReassignDevice.disenrolLabel") })
                  },
                  "disenrol"
                )
              ]
            }
          ),
          formik.values.wipeMethod === "wipeLater" && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g20, width: "100%" }, children: [
            /* @__PURE__ */ jsx(Box, { sx: { flex: 1 }, children: /* @__PURE__ */ jsx(
              DatePickerComponent,
              {
                inputFormat: "DD/MM/YYYY",
                value: (_i = formik.values.wipeLaterDate) != null ? _i : dayjs(),
                onChange: (value) => {
                  if (dayjs(value).isValid()) {
                    formik.setFieldValue("wipeLaterDate", value);
                  }
                },
                name: "wipeLaterDate",
                label: polyglot.t("ReassignDevice.dateLabel"),
                error: formik.touched.wipeLaterDate && !!formik.errors.wipeLaterDate,
                helperText: formik.touched.wipeLaterDate && formik.errors.wipeLaterDate,
                disablePast: true
              }
            ) }),
            /* @__PURE__ */ jsx(Box, { sx: { flex: 1 }, children: /* @__PURE__ */ jsx(
              TimePickerComponent,
              {
                label: polyglot.t("ReassignDevice.timeLabel"),
                textFieldKey: "wipeLaterTime",
                value: (_j = formik.values.wipeLaterTime) != null ? _j : "",
                onChange: (event) => {
                  const time = event.target.value;
                  if (isValidTimeString(time)) {
                    formik.setFieldValue("wipeLaterTime", time);
                  }
                },
                error: formik.touched.wipeLaterTime && !!formik.errors.wipeLaterTime,
                helperText: formik.touched.wipeLaterTime && formik.errors.wipeLaterTime ? formik.errors.wipeLaterTime : void 0,
                fullWidth: true
              }
            ) })
          ] })
        ] })
      }
    ),
    formik.submitCount > 0 && Object.keys(formik.errors).length > 0 && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ReassignDevice.labelPleaseFixErrors") }),
      /* @__PURE__ */ jsx("ul", { children: Object.values(formik.errors).map((error, index) => /* @__PURE__ */ jsx("li", { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: error }) }, index)) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Save",
        colorVariant: "primary",
        sizeVariant: "medium",
        type: "submit",
        loading,
        fullWidth: true,
        children: polyglot.t("ReassignDevice.saveButtonText")
      }
    ) })
  ] }) });
};
const TitleSubHeaderActionComponent = ({
  title,
  subheader,
  action
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.sm }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: title }),
      subheader && /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: subheader })
    ] }),
    action
  ] });
};
