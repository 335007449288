"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { forwardRef, useCallback } from "react";
import { InputAdornment } from "@mui/material";
import { StyledTextfieldNumber } from "@v2/styles/textfield-number.styles";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as QuestionIcon } from "@/images/side-bar-icons/Question.svg";
import { nestErrorMessage } from "@/lib/errors";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
const iconSize = { width: "14px", height: "14px" };
const defaultTo = (value, defaultValue) => typeof value === "undefined" ? defaultValue : value;
export const TextfieldNumberComponent = forwardRef(
  (_a, ref) => {
    var _b = _a, {
      id,
      value,
      onBlur,
      onChange,
      autoComplete,
      autoFocus,
      spellCheck,
      minValue,
      maxValue,
      tooltip,
      InputProps = {}
    } = _b, textFieldProps = __objRest(_b, [
      "id",
      "value",
      "onBlur",
      "onChange",
      "autoComplete",
      "autoFocus",
      "spellCheck",
      "minValue",
      "maxValue",
      "tooltip",
      "InputProps"
    ]);
    const [showMessage] = useMessage();
    const checkAutoTrim = useCallback(
      (e) => {
        const { min, max, value: value2 } = e.target;
        let parsedValue;
        try {
          parsedValue = parseInt(value2);
        } catch (e2) {
          showMessage(`Something went wrong. ${nestErrorMessage(e2)}`, "error");
          return;
        }
        if (!parsedValue) {
          return;
        }
        let trimmedValue = parsedValue;
        if (min !== void 0 && parsedValue < parseInt(min)) {
          trimmedValue = parseInt(min);
        }
        if (max !== void 0 && parsedValue > parseInt(max)) {
          trimmedValue = parseInt(max);
        }
        if (trimmedValue !== parsedValue) {
          e.target.value = trimmedValue.toString();
          onChange == null ? void 0 : onChange(e);
        }
      },
      [onChange, showMessage]
    );
    return /* @__PURE__ */ jsx(
      StyledTextfieldNumber,
      __spreadProps(__spreadValues({}, textFieldProps), {
        fullWidth: true,
        value: value === 0 ? "" : value,
        variant: "standard",
        inputRef: ref,
        type: "number",
        onBlur: (e) => {
          checkAutoTrim(e);
          onBlur == null ? void 0 : onBlur(e);
        },
        onChange,
        inputProps: {
          autoComplete,
          autoFocus,
          spellCheck: (
            // normally, spellCheck on inputs is on by default, but for most of our
            // editable text fields, it doesn't make sense to spell check the data
            defaultTo(spellCheck, false)
          ),
          max: maxValue,
          min: minValue,
          tooltip: { tooltip }
        },
        InputProps: __spreadProps(__spreadValues({}, InputProps), {
          id,
          endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: tooltip && /* @__PURE__ */ jsx(StyledTooltip, { title: tooltip, placement: "top", children: /* @__PURE__ */ jsx(QuestionIcon, __spreadValues({}, iconSize)) }) })
        })
      })
    );
  }
);
