"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/system";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { useNewDocumentOptions } from "@/v2/feature/document-new/hooks/use-new-document-options.hook";
import {
  ActionView
} from "@/v2/feature/document-new/interfaces/document-new.interface";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const NewDocumentAction = ({
  document,
  actions,
  view
}) => {
  const options = useNewDocumentOptions(document, actions, view);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-end", width: "100%", gap: spacing.g10 }, children: [
    view === ActionView.Table && document.showUpload && actions.onUpload && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        colorVariant: "primary",
        sizeVariant: "small",
        style: { minWidth: 60 },
        onClick: (e) => {
          actions.onUpload(document);
          e.stopPropagation();
        },
        children: "Upload"
      }
    ) }),
    view === ActionView.Table && document.showSign && actions.onSign && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        colorVariant: "primary",
        sizeVariant: "small",
        style: { minWidth: 60 },
        onClick: (e) => {
          actions.onSign(document);
          e.stopPropagation();
        },
        children: "Sign"
      }
    ) }),
    /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options,
        actionButtonDetails: {
          type: "iconButton",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: "actions",
          icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
        }
      }
    )
  ] });
};
