"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { LocalDate } from "@v2/util/local-date";
import axios from "axios";
export class DashboardAPI {
  static updateWidgetSettings(update) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.patch(`/apiv2/dashboard/widgets/update`, update)) == null ? void 0 : _a.data;
    });
  }
  static sendWishes(update) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.post(`/apiv2/users/notifications/wishes`, update)) == null ? void 0 : _a.data;
    });
  }
  static getGIFS(query) {
    return __async(this, null, function* () {
      var _a;
      return (_a = yield axios.get(`/apiv2/dashboard/widgets/GIFS?query=${query}`)) == null ? void 0 : _a.data;
    });
  }
}
export class DashboardEndpoints {
  static getUserWidgets() {
    const today = new LocalDate().toDateString();
    const reach = "personal";
    return {
      url: `/apiv2/dashboard/widgets/user?today=${today}&todoreach=${reach}`
    };
  }
  static getUserConfiguration() {
    return {
      url: `/apiv2/dashboard/widgets/preferences`
    };
  }
  static getAllWidgets() {
    const today = new LocalDate().toDateString();
    const reach = "personal";
    return {
      url: `/apiv2/dashboard/widgets/all?today=${today}&todoreach=${reach}`
    };
  }
  static getAllActionItems(reach, status, timeUnitStatus) {
    const today = new LocalDate().toDateString();
    const queryParams = new URLSearchParams({
      today,
      todoreach: reach,
      status,
      timeUnitStatus
    });
    return {
      url: `/apiv2/dashboard/widgets/action-items?${queryParams.toString()}`
    };
  }
}
