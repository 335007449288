"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { SUPER_ADMIN_HELPER_ROUTE } from "@v2/feature/super-admin/features/helper-dashboard/helper.router";
import { matchPath } from "react-router-dom";
import { ReactComponent as Settings } from "@/images/fields/Settings.svg";
import { ReactComponent as IDBig } from "@/images/onboarding-step-icons/IDBig.svg";
import { ReactComponent as TaskBig } from "@/images/onboarding-step-icons/TaskBig.svg";
import { ReactComponent as Analytyics } from "@/images/side-bar-icons/Analytics.svg";
import { ReactComponent as AnalytyicsFull } from "@/images/side-bar-icons/AnalyticsFull.svg";
import { ReactComponent as Apps } from "@/images/side-bar-icons/Apps.svg";
import { ReactComponent as AppsFull } from "@/images/side-bar-icons/AppsFull.svg";
import { ReactComponent as Payment } from "@/images/side-bar-icons/Bill.svg";
import { ReactComponent as PaymentFull } from "@/images/side-bar-icons/BillFull.svg";
import { ReactComponent as Coin } from "@/images/side-bar-icons/Coin.svg";
import { ReactComponent as Device } from "@/images/side-bar-icons/Device.svg";
import { ReactComponent as DeviceFull } from "@/images/side-bar-icons/DeviceFull.svg";
import { ReactComponent as Pension } from "@/images/side-bar-icons/Pension.svg";
import { ReactComponent as PensionFull } from "@/images/side-bar-icons/PensionFull.svg";
import { ReactComponent as People } from "@/images/side-bar-icons/People.svg";
import { ReactComponent as PeopleFull } from "@/images/side-bar-icons/PeopleFull.svg";
import { ReactComponent as Star } from "@/images/side-bar-icons/Star.svg";
import { ReactComponent as StarFull } from "@/images/side-bar-icons/StarFull.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { ReactComponent as WaitingFull } from "@/images/side-bar-icons/WaitingFull.svg";
import {
  DASHBOARD_ROUTE,
  SUPER_ADMIN_APPS_ROUTE,
  SUPER_ADMIN_BILLING_ROUTE,
  SUPER_ADMIN_BILLING_V2_ROUTE,
  SUPER_ADMIN_COMPANIES,
  SUPER_ADMIN_DEVICES_ROUTE,
  SUPER_ADMIN_ID_CHECKS_ROUTE,
  SUPER_ADMIN_INSURANCE_ROUTE,
  SUPER_ADMIN_LINKEDIN_USER_DATA_FETCH_ROUTE,
  SUPER_ADMIN_MDM_MIGRATION_ROUTE,
  SUPER_ADMIN_MONITORING_ROUTE,
  SUPER_ADMIN_REFINANCING_ROUTE,
  SUPER_ADMIN_SALARY_ROUTE,
  SUPER_ADMIN_STATISTICS_ROUTE,
  SUPER_ADMIN_USERS,
  SUPER_ADMIN_PARTNER_APP_PUBLISH_REQUEST_API_ROUTE
} from "@/lib/routes";
import { themeColors } from "@/v2/styles/colors.styles";
const iconSize = { width: 14, height: 14 };
export const getAdminSidebarConfig = () => {
  return [
    {
      subheader: "",
      items: [
        {
          title: "",
          path: [
            SUPER_ADMIN_USERS,
            SUPER_ADMIN_COMPANIES,
            SUPER_ADMIN_DEVICES_ROUTE,
            SUPER_ADMIN_APPS_ROUTE,
            SUPER_ADMIN_BILLING_ROUTE,
            SUPER_ADMIN_BILLING_V2_ROUTE,
            SUPER_ADMIN_STATISTICS_ROUTE,
            SUPER_ADMIN_MONITORING_ROUTE
          ],
          isHidden: false,
          children: [
            {
              title: "Users",
              path: SUPER_ADMIN_USERS,
              icon: /* @__PURE__ */ jsx(People, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(PeopleFull, __spreadValues({}, iconSize))
            },
            {
              title: "Companies",
              path: SUPER_ADMIN_COMPANIES,
              icon: /* @__PURE__ */ jsx(Pension, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(PensionFull, __spreadValues({}, iconSize))
            },
            {
              title: "Devices",
              path: SUPER_ADMIN_DEVICES_ROUTE,
              icon: /* @__PURE__ */ jsx(Device, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(DeviceFull, __spreadValues({}, iconSize))
            },
            {
              title: "Apps",
              path: SUPER_ADMIN_APPS_ROUTE,
              icon: /* @__PURE__ */ jsx(Apps, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(AppsFull, __spreadValues({}, iconSize))
            },
            {
              title: "Billing",
              path: SUPER_ADMIN_BILLING_ROUTE,
              icon: /* @__PURE__ */ jsx(Payment, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(PaymentFull, __spreadValues({}, iconSize))
            },
            {
              title: "Billing V2",
              path: SUPER_ADMIN_BILLING_V2_ROUTE,
              icon: /* @__PURE__ */ jsx(Payment, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(PaymentFull, __spreadValues({}, iconSize))
            },
            {
              title: "Statistics",
              path: SUPER_ADMIN_STATISTICS_ROUTE,
              icon: /* @__PURE__ */ jsx(Analytyics, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(AnalytyicsFull, __spreadValues({}, iconSize))
            },
            {
              title: "LinkedIn data",
              path: SUPER_ADMIN_LINKEDIN_USER_DATA_FETCH_ROUTE,
              icon: /* @__PURE__ */ jsx(People, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(PeopleFull, __spreadValues({}, iconSize))
            },
            {
              title: "Monitoring",
              path: SUPER_ADMIN_MONITORING_ROUTE,
              icon: /* @__PURE__ */ jsx(WaitingFull, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(Waiting, __spreadValues({}, iconSize))
            },
            {
              title: "Id checks",
              path: SUPER_ADMIN_ID_CHECKS_ROUTE,
              icon: /* @__PURE__ */ jsx(IDBig, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.middleGrey } })),
              iconActive: /* @__PURE__ */ jsx(IDBig, __spreadValues({}, iconSize))
            },
            {
              title: "Publish Requests",
              path: SUPER_ADMIN_PARTNER_APP_PUBLISH_REQUEST_API_ROUTE,
              icon: /* @__PURE__ */ jsx(TaskBig, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(TaskBig, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.black } }))
            },
            {
              title: "Salary",
              path: SUPER_ADMIN_SALARY_ROUTE,
              icon: /* @__PURE__ */ jsx(Pension, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(Pension, __spreadValues({}, iconSize))
            },
            {
              title: "Insurance",
              path: SUPER_ADMIN_INSURANCE_ROUTE,
              icon: /* @__PURE__ */ jsx(Star, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(StarFull, __spreadValues({}, iconSize))
            },
            {
              title: "Refinancing",
              path: SUPER_ADMIN_REFINANCING_ROUTE,
              icon: /* @__PURE__ */ jsx(Coin, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(Coin, __spreadValues({}, iconSize))
            },
            {
              title: "Helper board",
              path: SUPER_ADMIN_HELPER_ROUTE,
              icon: /* @__PURE__ */ jsx(Settings, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(Settings, __spreadValues({}, iconSize))
            },
            {
              title: "MDM Migration",
              path: SUPER_ADMIN_MDM_MIGRATION_ROUTE,
              icon: /* @__PURE__ */ jsx(Device, __spreadValues({}, iconSize)),
              iconActive: /* @__PURE__ */ jsx(DeviceFull, __spreadValues({}, iconSize))
            }
          ]
        }
      ]
    }
  ];
};
export function getActiveSub(currentPathname, itemPath) {
  if (itemPath === DASHBOARD_ROUTE) return currentPathname === DASHBOARD_ROUTE;
  if (currentPathname !== DASHBOARD_ROUTE)
    return !!matchPath(itemPath, currentPathname) || currentPathname.startsWith(itemPath);
  return false;
}
