"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { forwardRef } from "react";
import {
  Autocomplete,
  Box,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDownACIcon } from "@/images/fields/ArrowDown.svg";
import { ReactComponent as Edit } from "@/images/fields/Edit.svg";
import { ReactComponent as ChoseIcon } from "@/images/side-bar-icons/Chose.svg";
import { ClearIcon } from "@/v2/components/theme-components/clear-icon.component";
import { StyledAuto, StyledAutoTextfield } from "@/v2/styles/autocomplete.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledMenuItem } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize = { width: "14px", height: "14px" };
const FooterComponent = ({ editList }) => /* @__PURE__ */ jsx(
  Box,
  {
    sx: { padding: spacing.m10, borderTop: "1px solid", borderColor: "divider", cursor: "pointer" },
    onMouseDown: (event) => {
      event.preventDefault();
    },
    onClick: () => {
      var _a;
      (_a = editList.handler) == null ? void 0 : _a.call(editList);
    },
    children: /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
      /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
            color: themeColors.Grey
          }),
          children: "Edit list"
        }
      )
    ] })
  }
);
const CustomPaperComponent = (_a) => {
  var _b = _a, { children, sx, editList } = _b, rest = __objRest(_b, ["children", "sx", "editList"]);
  return /* @__PURE__ */ jsxs(Paper, __spreadProps(__spreadValues({}, rest), { sx: __spreadProps(__spreadValues(__spreadValues({}, sx), StyledAuto), { paddingBottom: "0 !important", zIndex: 1 }), children: [
    children,
    editList && !editList.isHidden && /* @__PURE__ */ jsx(FooterComponent, { editList })
  ] }));
};
export const AutocompleteComponent = React.memo(
  forwardRef(
    (_c, ref) => {
      var _d = _c, {
        name,
        label,
        options,
        value,
        compareValue,
        error,
        helperText,
        required,
        freeSolo,
        placeholder,
        editList
      } = _d, autoCompleteProps = __objRest(_d, [
        "name",
        "label",
        "options",
        "value",
        "compareValue",
        "error",
        "helperText",
        "required",
        "freeSolo",
        "placeholder",
        "editList"
      ]);
      const PaperComponentWithEditList = (paperProps) => /* @__PURE__ */ jsx(CustomPaperComponent, __spreadProps(__spreadValues({}, paperProps), { editList }));
      return /* @__PURE__ */ jsx(
        Autocomplete,
        __spreadProps(__spreadValues({
          ref
        }, autoCompleteProps), {
          options,
          inputlabelprops: { shrink: true },
          getOptionLabel: (option) => option.label,
          value,
          freeSolo,
          renderOption: (props, option) => {
            return /* @__PURE__ */ jsxs(
              StyledMenuItem,
              __spreadProps(__spreadValues({}, props), {
                sx: {
                  width: "100%"
                },
                children: [
                  /* @__PURE__ */ jsx(
                    Box,
                    {
                      sx: __spreadValues({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        height: "100%",
                        width: "100%",
                        color: themeColors.DarkGrey
                      }, option.value !== compareValue ? themeFonts.caption : themeFonts.title4),
                      children: option.label
                    }
                  ),
                  option.value === compareValue && /* @__PURE__ */ jsx(ChoseIcon, __spreadValues({}, iconSize))
                ]
              })
            );
          },
          renderInput: (params) => /* @__PURE__ */ jsx(
            StyledAutoTextfield,
            __spreadProps(__spreadValues({}, params), {
              fullWidth: true,
              InputLabelProps: { shrink: true },
              value,
              variant: "standard",
              placeholder,
              size: "small",
              label,
              name,
              error,
              helperText,
              required
            })
          ),
          PaperComponent: PaperComponentWithEditList,
          popupIcon: /* @__PURE__ */ jsx(ArrowDownACIcon, __spreadValues({}, iconSize)),
          clearIcon: /* @__PURE__ */ jsx(ClearIcon, __spreadValues({}, iconSize))
        })
      );
    }
  )
);
