"use strict";
import { useEffect, useRef, useState } from "react";
import { parseISO, differenceInSeconds } from "date-fns";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { timeAgo } from "@/v2/util/date-format.util";
const ONE_MINUTE = 60;
const ONE_HOUR = 60 * 60;
export function useDynamicUpdate(dateStr) {
  const [label, setLabel] = useState(dateStr ? timeAgo(dateStr) : null);
  const timerRef = useRef();
  const { polyglot } = usePolyglot();
  useEffect(() => {
    if (!dateStr) {
      setLabel(null);
      return;
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    const scheduleNextUpdate = () => {
      const diffInSeconds = differenceInSeconds(/* @__PURE__ */ new Date(), parseISO(dateStr));
      setLabel(diffInSeconds < ONE_MINUTE ? polyglot.t("RefreshLabel.justNow") : timeAgo(dateStr));
      let delayMs;
      if (diffInSeconds < ONE_HOUR) {
        const secondsIntoThisMinute = diffInSeconds % ONE_MINUTE;
        const nextMinute = ONE_MINUTE - secondsIntoThisMinute;
        delayMs = nextMinute * 1e3;
      } else {
        const secondsIntoThisHour = diffInSeconds % ONE_HOUR;
        const nextHour = ONE_HOUR - secondsIntoThisHour;
        delayMs = nextHour * 1e3;
      }
      timerRef.current = window.setTimeout(scheduleNextUpdate, delayMs);
    };
    scheduleNextUpdate();
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [dateStr, polyglot]);
  return label;
}
