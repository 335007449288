"use strict";
export var TaskStatuses = /* @__PURE__ */ ((TaskStatuses2) => {
  TaskStatuses2["INCOMPLETE"] = "Incomplete";
  TaskStatuses2["COMPLETE"] = "Complete";
  return TaskStatuses2;
})(TaskStatuses || {});
export var TaskOnboardState = /* @__PURE__ */ ((TaskOnboardState2) => {
  TaskOnboardState2["TASKS"] = "tasks";
  return TaskOnboardState2;
})(TaskOnboardState || {});
export var TaskTabFilter = /* @__PURE__ */ ((TaskTabFilter2) => {
  TaskTabFilter2["InSevenDays"] = "7";
  TaskTabFilter2["InThirtyDays"] = "30";
  TaskTabFilter2["All"] = "all";
  TaskTabFilter2["Done"] = "done";
  return TaskTabFilter2;
})(TaskTabFilter || {});
export var ActionItemsTabFilter = /* @__PURE__ */ ((ActionItemsTabFilter2) => {
  ActionItemsTabFilter2["All"] = "all";
  ActionItemsTabFilter2["Approved"] = "approved";
  ActionItemsTabFilter2["RequestedByTeam"] = "requestedByTeam";
  ActionItemsTabFilter2["PendingApprovalFromTeam"] = "pendingApprovalFromTeam";
  ActionItemsTabFilter2["RequestedByMe"] = "requestedByMe";
  ActionItemsTabFilter2["PendingApprovalFromMe"] = "pendingApprovalFromMe";
  return ActionItemsTabFilter2;
})(ActionItemsTabFilter || {});
export var ActionItemsDateFilter = /* @__PURE__ */ ((ActionItemsDateFilter2) => {
  ActionItemsDateFilter2["InSevenDays"] = "7";
  ActionItemsDateFilter2["InThirtyDays"] = "30";
  ActionItemsDateFilter2["Overdue"] = "overdue";
  return ActionItemsDateFilter2;
})(ActionItemsDateFilter || {});
