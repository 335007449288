"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { ProgressBar } from "@/v2/components/progress-bar.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { GridDisplayBarV2 } from "@/v2/feature/growth/shared/components/grid-display-bar.component";
import { themeColors } from "@/v2/styles/colors.styles";
const ListItemDetails = ({ title, description, chips = null }) => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    title ? /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { fontWeight: 500 }, children: title }) : null,
    description ? /* @__PURE__ */ jsx(Box, { sx: { marginTop: "1rem", minHeight: "20px" }, children: /* @__PURE__ */ jsx(
      Typography,
      {
        variant: "caption",
        dangerouslySetInnerHTML: { __html: typeof description === "string" ? description : "" }
      }
    ) }) : null,
    /* @__PURE__ */ jsx(Box, { sx: { marginTop: "1rem", display: "flex", alignItems: "center", gap: "0.25rem" }, children: chips })
  ] });
};
export const ListItem = ({ progressBar, details, endSection, hoverState = true, sx }) => {
  const gridsXs = useMemo(() => {
    return [6, 5, 1];
  }, []);
  return /* @__PURE__ */ jsx(
    GridDisplayBarV2,
    {
      sx: __spreadValues({
        ".MuiGrid-item": { padding: 0 },
        marginTop: 0,
        marginLeft: 0,
        padding: "1rem 1.5rem",
        width: "100%",
        borderBottom: `1px solid ${themeColors.lightGrey2}`,
        "&:hover": hoverState ? {
          backgroundColor: themeColors.TableHover,
          cursor: "pointer"
        } : void 0
      }, sx),
      cells: [
        {
          content: /* @__PURE__ */ jsx(ListItemDetails, __spreadValues({}, details)),
          gridXs: gridsXs[0]
        },
        {
          content: /* @__PURE__ */ jsx(
            Box,
            {
              style: {
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "end",
                paddingRight: "2rem"
              },
              children: progressBar ? /* @__PURE__ */ jsxs(Fragment, { children: [
                /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { fontSize: "17px", fontWeight: "500" }, children: [
                  progressBar.progress,
                  "%"
                ] }),
                /* @__PURE__ */ jsx(Box, { sx: { width: "12.5rem", display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
                  ProgressBar,
                  {
                    label: ``,
                    progress: progressBar.progress,
                    color: themeColors.PositiveDark,
                    styles: { display: "inline-block" }
                  }
                ) })
              ] }) : null
            }
          ),
          gridXs: gridsXs[1]
        },
        {
          content: /* @__PURE__ */ jsx(Fragment, { children: endSection }),
          gridXs: gridsXs[2]
        }
      ]
    }
  );
};
