"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { GROWTH_COMPANY_GOALS_ROUTE, GROWTH_GOAL_BASE_ROUTE, GROWTH_GOAL_ROUTE } from "@/lib/routes";
import { GoalRouter } from "@/v2/feature/growth/goals/goal.router";
import { GoalsCompanyRouter } from "@/v2/feature/growth/goals/goals-company.router";
export const GoalsRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["goals:all"], path: GROWTH_COMPANY_GOALS_ROUTE, children: /* @__PURE__ */ jsx(GoalsCompanyRouter, {}) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["goals"], path: [GROWTH_GOAL_BASE_ROUTE, GROWTH_GOAL_ROUTE], children: /* @__PURE__ */ jsx(GoalRouter, {}) })
  ] });
};
