"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/system";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { UploadInput } from "@/component/forms/UploadInput";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { readFileAsBase64 } from "@/v2/feature/document-new/document-new-import/document-new-import.util";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const NewDocumentUploadModal = ({
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  selectedDocument
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(NewDocumentUploadContent, { refresh, onClose, selectedDocument }) });
};
const NewDocumentUploadContent = ({
  refresh,
  onClose,
  selectedDocument
}) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const formik = useFormik({
    initialValues: {
      fileData: null,
      fileName: null,
      contentType: null
    },
    validationSchema: yup.object({
      fileData: yup.string().nullable().required(),
      fileName: yup.string().nullable().required(),
      contentType: yup.string().nullable().required()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (selectedDocument) {
          yield DocumentNewAPI.uploadAttachment(values, selectedDocument.id);
          showMessage(polyglot.t("DocumentFormModal.successMessages.upload"), "success");
          yield refresh == null ? void 0 : refresh();
          onClose();
        } else {
          showMessage(polyglot.t("DocumentFormModal.errorMessages.upload"), "error");
        }
      } catch (error) {
        showMessage(
          polyglot.t("DocumentFormModal.errorMessages.upload", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setSubmitting(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: spacing.sm
        },
        children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Upload document" }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("DocumentFormModal.uploadDescription") })
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: "20px"
        },
        children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2, marginBottom: spacing.s2 }, children: [
          /* @__PURE__ */ jsx(
            UploadInput,
            {
              skipUpload: true,
              onChange: (_, file) => __async(void 0, null, function* () {
                try {
                  if (!file) return;
                  const fileData = yield readFileAsBase64(file);
                  formik.setFieldValue("fileData", fileData);
                  formik.setFieldValue("contentType", file.type);
                  formik.setFieldValue("fileName", file.name);
                } catch (error) {
                  showMessage("Error parsing file, please select a CSV file", "error");
                  console.error(":::: ERROR PARSING FILE :::::", error);
                }
              })
            }
          ),
          Boolean(formik.touched.fileData && !!formik.errors.fileData) && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "RedDark", children: formik.errors.fileData })
        ] })
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, fullWidth: true, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          name: polyglot.t("General.save"),
          loading: formik.isSubmitting,
          fullWidth: true
        }
      )
    ] })
  ] }) });
};
