"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DevicePossessionType, DeviceTransitStatus } from "@v2/feature/device/device.interface";
import { getDeviceOwnerAsSuperadminByDevicePossession, processMdmServerName } from "@v2/feature/device/device.util";
import { InHouseMdmAPI } from "@v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { spacing } from "@v2/styles/spacing.styles";
import { StyledTextfield } from "@v2/styles/textfield.styles";
import { LocalDate } from "@v2/util/local-date";
import { isToday } from "date-fns";
import locale from "date-fns/locale/en-GB";
import { saveAs } from "file-saver";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDownACIcon } from "@/images/side-bar-icons/ArrowDownAC.svg";
import { ReactComponent as ArrowRightSmall } from "@/images/side-bar-icons/NextBtn.svg";
import { nestErrorMessage } from "@/lib/errors";
import { themeColors } from "@/v2/styles/colors.styles";
export const SuperAdminDeviceTransitDrawer = ({
  isOpen,
  setIsOpen,
  deviceTransit,
  setDeviceTransit,
  companyNames,
  userNames,
  refresh,
  sites
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(
      SuperAdminDeviceTransitDrawerContent,
      {
        deviceTransit,
        companyNames,
        onDeliveryConfirm: () => __async(void 0, null, function* () {
          yield refresh();
          setDeviceTransit(void 0);
          setIsOpen(false);
        }),
        userNames,
        sites
      }
    )
  }
) });
const SuperAdminDeviceTransitDrawerContent = ({
  deviceTransit,
  onDeliveryConfirm,
  userNames,
  companyNames,
  sites
}) => {
  var _a, _b, _c, _d, _e, _f;
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(new LocalDate().toDateString());
  const [depAccountName, setDepAccountName] = useState(null);
  const [isRejectedConfirmOpen, setIsRejectedConfirmOpen] = useState(false);
  const [trackingLink, setTrackingLink] = useState((_a = deviceTransit.trackingLink) != null ? _a : null);
  const [showMessage] = useMessage();
  const confirmDeviceReturn = () => __async(void 0, null, function* () {
    try {
      setIsButtonLoading(true);
      yield DeviceAPI.markDeviceAsReturnedAsSuperAdmin(deviceTransit.id);
      yield onDeliveryConfirm();
    } catch (error) {
      showMessage(`Could not mark the device as returned. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsButtonLoading(false);
    }
  });
  const markStorageDeviceAsShippedBackToCompany = () => __async(void 0, null, function* () {
    try {
      setIsButtonLoading(true);
      yield DeviceAPI.markStorageDeviceAsShippedAsSuperAdmin(deviceTransit.id);
      yield onDeliveryConfirm();
    } catch (error) {
      showMessage(`Could not mark the device as returned. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsButtonLoading(false);
    }
  });
  const markStorageDeviceAsDeliveredToCompany = () => __async(void 0, null, function* () {
    try {
      setIsButtonLoading(true);
      yield DeviceAPI.markStorageDeviceAsDeliveredAsSuperAdmin(deviceTransit.id, deliveryDate);
      yield onDeliveryConfirm();
    } catch (error) {
      showMessage(`Could not mark the device as returned. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsButtonLoading(false);
    }
  });
  const getButtonLabel = () => {
    if (deviceTransit.isShippingManagedByZelt) {
      switch (deviceTransit.status) {
        case DeviceTransitStatus.Pending:
          return "Set in shipping";
        case DeviceTransitStatus.Shipping:
          return "Set as delivered";
        case DeviceTransitStatus.MangedByZelt:
          return "Set in pending";
        default:
          return "";
      }
    } else {
      return "";
    }
  };
  const markDeviceTransitManagedByZelt = () => __async(void 0, null, function* () {
    var _a2, _b2, _c2;
    try {
      setIsButtonLoading(true);
      if (deviceTransit.isShippingManagedByZelt && deviceTransit.status === DeviceTransitStatus.Pending) {
        yield updateTrackingLink();
        yield DeviceAPI.markDeviceTransitMangedByZeltAsSuperAdmin(deviceTransit.id, DeviceTransitStatus.Shipping);
        yield onDeliveryConfirm();
      } else if (deviceTransit.isShippingManagedByZelt && deviceTransit.status === DeviceTransitStatus.Shipping) {
        if (((_a2 = deviceTransit.receiver) == null ? void 0 : _a2.possessionType) === DevicePossessionType.ZeltStock) {
          yield confirmDeviceReturn();
        } else if (((_b2 = deviceTransit.receiver) == null ? void 0 : _b2.possessionType) === DevicePossessionType.ZeltStorage) {
          yield confirmDeviceReturn();
        } else if (((_c2 = deviceTransit.sender) == null ? void 0 : _c2.possessionType) === DevicePossessionType.ZeltStorage) {
          yield markStorageDeviceAsDeliveredToCompany();
        } else {
          yield markStorageDeviceAsDeliveredToCompany();
        }
      } else if (deviceTransit.isShippingManagedByZelt && deviceTransit.status === DeviceTransitStatus.MangedByZelt) {
        yield DeviceAPI.markDeviceTransitMangedByZeltAsSuperAdmin(deviceTransit.id, DeviceTransitStatus.Pending);
        yield onDeliveryConfirm();
      }
    } catch (error) {
      showMessage(`Could not set device transit status. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsButtonLoading(false);
    }
  });
  const markDeviceTransitAsRejected = () => __async(void 0, null, function* () {
    try {
      yield DeviceAPI.markDeviceTransitMangedByZeltAsSuperAdmin(deviceTransit.id, DeviceTransitStatus.Rejected);
      yield onDeliveryConfirm();
    } catch (error) {
      showMessage(`Could not mark the device as rejected. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsButtonLoading(false);
    }
  });
  const assignNewNameToDepDevice = () => __async(void 0, null, function* () {
    const device = deviceTransit.device;
    if (device && device.id && device.serialNumber && depAccountName) {
      const depEvent = {
        depDeviceUsername: depAccountName,
        depTokenName: processMdmServerName(deviceTransit.companyId + companyNames[deviceTransit.companyId]),
        deviceId: device.id,
        serialNumber: device.serialNumber
      };
      yield InHouseMdmAPI.assignDeviceToABM(depEvent);
      showMessage(`Action executed`, "success");
    } else {
      showMessage(`Serial number, device username and device id have to be provided to assign to DEP`, "error");
      return;
    }
  });
  const getEnrolmentProfile = () => __async(void 0, null, function* () {
    if (deviceTransit.deviceId) {
      try {
        setIsButtonLoading(true);
        return yield InHouseMdmAPI.getEnrolmentProfile(deviceTransit.deviceId);
      } catch (error) {
        showMessage(`Could not get device data. ${nestErrorMessage(error)}`, "error");
      } finally {
        setIsButtonLoading(false);
      }
    } else {
      showMessage(`Device id is necessary to generate profile.`, "error");
      return void 0;
    }
  });
  const updateTrackingLink = () => __async(void 0, null, function* () {
    try {
      setIsButtonLoading(true);
      let validatedLink = trackingLink;
      if (trackingLink) {
        validatedLink = trackingLink.startsWith("https://") ? trackingLink : `https://${trackingLink}`;
        showMessage("Tracking link updated successfully", "success");
      }
      yield DeviceAPI.updateDeviceTransitTrackingLink(deviceTransit.id, validatedLink);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsButtonLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Transit" }),
    deviceTransit.device && /* @__PURE__ */ jsxs(Box, { sx: { mb: 2 }, children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "title4", children: [
        "Model Name: ",
        deviceTransit.device.modelName
      ] }),
      deviceTransit.device.modelNumber && /* @__PURE__ */ jsxs(Typography, { variant: "title4", children: [
        "Model Number: ",
        deviceTransit.device.modelNumber
      ] }),
      deviceTransit.device.serialNumber && /* @__PURE__ */ jsxs(Typography, { variant: "title4", children: [
        "Serial Number: ",
        deviceTransit.device.serialNumber
      ] }),
      deviceTransit.isShippingManagedByZelt && /* @__PURE__ */ jsxs(Typography, { variant: "title4", children: [
        "Shipping managed by Zelt: ",
        deviceTransit.isShippingManagedByZelt ? "True" : "False"
      ] })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: 2, mb: 2, justifyContent: "space-between", alignItems: "center" }, children: [
      deviceTransit.sender && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", alignItems: "center" }, children: [
        deviceTransit.sender.possessionType !== DevicePossessionType.ZeltStock && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: deviceTransit.sender.companyId && companyNames[deviceTransit.sender.companyId] || "Unknown" }),
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: getDeviceOwnerAsSuperadminByDevicePossession(deviceTransit.sender, userNames, sites) })
      ] }),
      /* @__PURE__ */ jsx(ArrowRightSmall, { width: 32, height: 32 }),
      deviceTransit.receiver && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", alignItems: "center" }, children: [
        deviceTransit.receiver.possessionType !== DevicePossessionType.ZeltStock && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: deviceTransit.receiver.companyId && companyNames[deviceTransit.receiver.companyId] || "Unknown" }),
        /* @__PURE__ */ jsx(Typography, { variant: "title4", children: getDeviceOwnerAsSuperadminByDevicePossession(deviceTransit.receiver, userNames, sites) })
      ] })
    ] }),
    /* @__PURE__ */ jsxs(Box, { children: [
      ((_b = deviceTransit.sender) == null ? void 0 : _b.possessionType) === DevicePossessionType.ZeltStock && !deviceTransit.isShippingManagedByZelt && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 1, alignItems: "start" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "If the device has been delivered, you can mark it as delivered from the order drawer." }) }),
      ((_c = deviceTransit.receiver) == null ? void 0 : _c.possessionType) === DevicePossessionType.ZeltStock && !deviceTransit.isShippingManagedByZelt && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Mark as returned",
          loading: isButtonLoading,
          onClick: confirmDeviceReturn,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      ),
      ((_d = deviceTransit.receiver) == null ? void 0 : _d.possessionType) === DevicePossessionType.ZeltStorage && !deviceTransit.isShippingManagedByZelt && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 1, alignItems: "start" }, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Mark as received for storage",
          loading: isButtonLoading,
          onClick: confirmDeviceReturn,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      ) }),
      ((_e = deviceTransit.sender) == null ? void 0 : _e.possessionType) === DevicePossessionType.ZeltStorage && deviceTransit.status === DeviceTransitStatus.Pending && !deviceTransit.isShippingManagedByZelt && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 1, alignItems: "start" }, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Mark as shipped",
          loading: isButtonLoading,
          onClick: markStorageDeviceAsShippedBackToCompany,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      ) }),
      ((_f = deviceTransit.sender) == null ? void 0 : _f.possessionType) === DevicePossessionType.ZeltStorage && deviceTransit.status === DeviceTransitStatus.Shipping && !deviceTransit.isShippingManagedByZelt && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 1, alignItems: "start" }, children: [
        /* @__PURE__ */ jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, locale, children: /* @__PURE__ */ jsx(
          DatePicker,
          {
            components: {
              OpenPickerIcon: ArrowDownACIcon
            },
            label: "Delivery Date",
            value: deliveryDate,
            inputFormat: "dd/MM/yyyy",
            onChange: (date) => date ? setDeliveryDate(date) : setDeliveryDate(new LocalDate().toDateString()),
            disableFuture: true,
            shouldDisableDate: (date) => Boolean(
              date > new LocalDate().toDateString() || date < new LocalDate(deviceTransit.createdAt).toDateString()
            ) && !isToday(new LocalDate(date).getDate()),
            renderInput: (params) => /* @__PURE__ */ jsx(StyledTextfield, __spreadProps(__spreadValues({}, params), { size: "small", fullWidth: true, variant: "standard" }))
          }
        ) }, "delivery-date"),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Mark as delivered",
            loading: isButtonLoading,
            onClick: markStorageDeviceAsDeliveredToCompany,
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "primary"
          }
        )
      ] }),
      deviceTransit.isShippingManagedByZelt && ![DeviceTransitStatus.Delivered, DeviceTransitStatus.Rejected].includes(deviceTransit.status) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", mt: "10px" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "Shipping managed by Zelt" }),
        /* @__PURE__ */ jsxs(Box, { sx: { mt: "10px", display: "flex", flexDirection: "column", gap: "10px" }, children: [
          [DeviceTransitStatus.Shipping].includes(deviceTransit.status) && getButtonLabel() !== "" && /* @__PURE__ */ jsx(
            LocalizationProvider,
            {
              dateAdapter: AdapterDateFns,
              adapterLocale: locale,
              children: /* @__PURE__ */ jsx(
                DatePicker,
                {
                  components: {
                    OpenPickerIcon: ArrowDownACIcon
                  },
                  label: "Delivery Date",
                  value: deliveryDate,
                  inputFormat: "dd/MM/yyyy",
                  onChange: (date) => date ? setDeliveryDate(date) : setDeliveryDate(new LocalDate().toDateString()),
                  disableFuture: true,
                  shouldDisableDate: (date) => Boolean(
                    date > new LocalDate().toDateString() || date < new LocalDate(deviceTransit.createdAt).toDateString()
                  ) && !isToday(new LocalDate(date).getDate()),
                  renderInput: (params) => /* @__PURE__ */ jsx(StyledTextfield, __spreadProps(__spreadValues({}, params), { size: "small", fullWidth: true, variant: "standard" }))
                }
              )
            },
            "delivery-date-shipping-by-zelt"
          ),
          ![
            DeviceTransitStatus.Delivered,
            DeviceTransitStatus.Cancelled,
            DeviceTransitStatus.Rejected,
            DeviceTransitStatus.Pending
          ].includes(deviceTransit.status) && getButtonLabel() !== "" && /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: getButtonLabel(),
              loading: isButtonLoading,
              onClick: markDeviceTransitManagedByZelt,
              fullWidth: true,
              sizeVariant: "medium",
              colorVariant: "primary"
            }
          ),
          DeviceTransitStatus.Pending === deviceTransit.status && getButtonLabel() !== "" && /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: spacing.gap20, alignItems: "end" }, children: /* @__PURE__ */ jsx(
              TextfieldComponent,
              {
                label: "Tracking Link",
                name: "trackingLink",
                value: trackingLink,
                onChange: (event) => {
                  setTrackingLink(event.target.value);
                }
              }
            ) }),
            /* @__PURE__ */ jsx(
              LoaderButton,
              {
                name: getButtonLabel(),
                loading: isButtonLoading,
                onClick: markDeviceTransitManagedByZelt,
                fullWidth: true,
                sizeVariant: "medium",
                colorVariant: "primary"
              }
            )
          ] }),
          ![DeviceTransitStatus.Delivered, DeviceTransitStatus.Rejected].includes(deviceTransit.status) && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "secondary",
              fullWidth: true,
              onClick: () => setIsRejectedConfirmOpen(true),
              children: "Set as rejected"
            }
          )
        ] })
      ] }),
      /* @__PURE__ */ jsxs(Dialog, { open: isRejectedConfirmOpen, onClose: () => setIsRejectedConfirmOpen(false), children: [
        /* @__PURE__ */ jsx(DialogTitle, { children: "Confirm rejection" }),
        /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsx(DialogContentText, { children: "Are you sure you want to reject this device transit?" }) }),
        /* @__PURE__ */ jsxs(DialogActions, { children: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "secondary",
              onClick: () => setIsRejectedConfirmOpen(false),
              children: "No"
            }
          ),
          /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: "Yes",
              loading: isButtonLoading,
              onClick: () => __async(void 0, null, function* () {
                yield markDeviceTransitAsRejected();
                setIsRejectedConfirmOpen(false);
              }),
              sizeVariant: "medium",
              colorVariant: "primary"
            }
          )
        ] })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", mt: "15px" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title3", children: "MDM configuration" }),
        /* @__PURE__ */ jsxs(Box, { sx: { mt: "10px" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "Enrolment" }),
          /* @__PURE__ */ jsx(Box, { sx: { mt: "10px", gap: "5px" }, children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "primary",
              loading: isButtonLoading,
              onClick: () => __async(void 0, null, function* () {
                try {
                  const profile = yield getEnrolmentProfile();
                  if (profile) {
                    const blob = new Blob([Buffer.from(profile, "base64")], {
                      type: "application/x-apple-aspen-config"
                    });
                    saveAs(blob, "enrollment.mobileconfig");
                  }
                } catch (error) {
                  showMessage(`Could not download mdm config. ${nestErrorMessage(error)}`, "error");
                }
              }),
              fullWidth: true,
              children: "Download enrolment profile"
            }
          ) })
        ] })
      ] })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { mt: "10px" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: "DEP configuration" }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: "10px" }, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "deviceAccountName",
          label: "Device account name",
          value: depAccountName != null ? depAccountName : "",
          type: "text",
          onChange: (e) => {
            var _a2;
            setDepAccountName((_a2 = e.target.value) == null ? void 0 : _a2.toLowerCase());
          },
          tooltip: "To set up your device, enter a unique name for the account."
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: "10px" }, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Assign account name",
          loading: isButtonLoading,
          onClick: assignNewNameToDepDevice,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      ) })
    ] })
  ] });
};
