"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { CompanyAPI } from "@/api-client/index.api";
import { UploadProfilePicture } from "@/component/forms/UploadProfilePicture";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
const validationSchema = (polyglot) => yup.object({
  name: yup.string().required(polyglot.t("CompanySettingsGeneralEdit.errorMessages.displayNameRequired"))
});
export const CompanySettingsDetailsDrawer = ({
  generalSettings,
  refreshSettings,
  setEditMode,
  editMode
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen: editMode, setIsOpen: setEditMode, children: /* @__PURE__ */ jsx(
    CompanySettingsDetailsDrawerContent,
    {
      generalSettings,
      refreshSettings,
      setEditMode
    }
  ) });
};
const CompanySettingsDetailsDrawerContent = ({
  generalSettings,
  refreshSettings,
  setEditMode
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [state, dispatch] = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [localAvatar, setLocalAvatar] = useState();
  useEffect(() => {
    setLocalAvatar(state.user.company.avatar);
  }, [state.user.company.avatar, setLocalAvatar]);
  const hasAvatar = useMemo(() => {
    return state.publicURL && localAvatar ? true : false;
  }, [localAvatar, state.publicURL]);
  const formik = useFormik({
    initialValues: {
      name: (generalSettings == null ? void 0 : generalSettings.name) || ""
    },
    enableReinitialize: true,
    validationSchema: validationSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const update = {
          name: values.name,
          showWallpaper: generalSettings == null ? void 0 : generalSettings.showWallpaper
        };
        yield CompanyAPI.updateGeneralSettings(update);
        const res = yield AuthAPI.getAuthMe(false);
        yield refreshSettings == null ? void 0 : refreshSettings();
        setEditMode(false);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: res.user
        });
        showMessage(polyglot.t("CompanySettingsGeneralEdit.successMessages.update"), "success");
      } catch (error) {
        showMessage(
          `${polyglot.t("CompanySettingsGeneralEdit.errorMessages.update")}: ${nestErrorMessage(error)}`,
          "error"
        );
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("CompanySettingsGeneralEdit.editCompany") }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "5px" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.DarkGrey }, children: polyglot.t("CompanySettingsGeneralEdit.companyLogo") }),
      /* @__PURE__ */ jsx(
        UploadProfilePicture,
        {
          disabled: false,
          value: hasAvatar ? `${state.publicURL}/${localAvatar}` : void 0,
          onChange: (tempAvatar) => {
            setLocalAvatar(tempAvatar);
          },
          url: CompanyAPI.getCompanyUploadURL(),
          userId: null
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: polyglot.t("CompanySettingsGeneralEdit.displayName"),
        name: "name",
        value: formik.values.name,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_a = formik.touched.name && formik.errors.name) != null ? _a : " ",
        clearText: () => formik.setFieldValue("name", ""),
        autoFocus: true
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "secondary",
          disabled: loading,
          onClick: () => setEditMode(false),
          children: polyglot.t("General.cancel")
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          type: "submit",
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary",
          disabled: loading,
          loading
        }
      )
    ] })
  ] }) });
};
