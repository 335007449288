"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack } from "@mui/system";
import { Form, FormikProvider, useFormik } from "formik";
import { capitalize, keyBy } from "lodash";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { CustomProfileFormType } from "@/v2/feature/custom-fields/custom-profile-fields.dto";
import { UserCustomField } from "@/v2/feature/user/components/user-custom-field.component";
import { useUserFieldsContext } from "@/v2/feature/user/context/user-fields.context";
import { UserFamilyMembersSection } from "@/v2/feature/user/features/user-profile/details/components/user-family/user-family-members-section.component";
import {
  FAMILY_INFORMATION_MARITAL,
  getMaritalOptions
} from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { UserAPI, UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { translateCustomField, translateCustomFieldCategory } from "@/v2/infrastructure/i18n/translate.util";
import { spacing } from "@/v2/styles/spacing.styles";
const FamilySchema = (defaultFieldsMap) => Yup.object().shape({
  maritalStatus: defaultFieldsMap["maritalStatus"] && defaultFieldsMap["maritalStatus"].isRequired ? Yup.string().oneOf(FAMILY_INFORMATION_MARITAL).required() : Yup.string().nullable().notRequired()
});
export const UserFamilySection = ({ onNext, sx, userId }) => {
  var _a, _b, _c, _d;
  const { data: familyInfo } = useApiClient(UserEndpoints.getUserFamily(userId), {
    suspense: false
  });
  const { data: userFamilyMembers, mutate: refreshUserFamilyMembers } = useApiClient(
    UserEndpoints.getUserFamilyMembers(userId),
    { suspense: false }
  );
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedDefaultFieldsByFormName, getCachedCustomFieldsByFormName } = useUserFieldsContext();
  const defaultFields = getCachedDefaultFieldsByFormName(CustomProfileFormType.Family);
  const customFields = getCachedCustomFieldsByFormName(CustomProfileFormType.Family);
  const defaultFieldsMap = useMemo(() => {
    return keyBy(defaultFields, "fieldStub");
  }, [defaultFields]);
  const customFieldIds = useMemo(() => new Set((customFields != null ? customFields : []).map((field) => field.fieldId)), [customFields]);
  const validationSchema = useMemo(() => FamilySchema(defaultFieldsMap), [defaultFieldsMap]);
  const initialValues = useMemo(() => {
    var _a2, _b2, _c2;
    const values = {};
    if (defaultFieldsMap["maritalStatus"]) values.maritalStatus = (_a2 = familyInfo == null ? void 0 : familyInfo.maritalStatus) != null ? _a2 : null;
    values.customUpdates = (_c2 = (_b2 = familyInfo == null ? void 0 : familyInfo.customUpdates) == null ? void 0 : _b2.filter((update) => customFieldIds.has(update.fieldId))) != null ? _c2 : [];
    return values;
  }, [defaultFieldsMap, familyInfo, customFieldIds]);
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        yield UserAPI.patchUserFamilyInfo(userId, __spreadValues({}, values));
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.addressNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: (_a = capitalize(translateCustomFieldCategory(CustomProfileFormType.Family, polyglot))) != null ? _a : polyglot.t("OnboardingByUser.Family") }),
    defaultFieldsMap["maritalStatus"] && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "maritalStatus",
        label: (_b = translateCustomField(defaultFieldsMap["maritalStatus"].fieldName, polyglot)) != null ? _b : polyglot.t("FamilyForm.maritalStatus"),
        options: getMaritalOptions(polyglot),
        value: formik.values.maritalStatus,
        compareValue: formik.values.maritalStatus,
        error: !!formik.errors.maritalStatus && formik.touched.maritalStatus,
        onChange: formik.handleChange,
        helperText: formik.errors.maritalStatus && formik.touched.maritalStatus,
        required: (_c = defaultFieldsMap["maritalStatus"]) == null ? void 0 : _c.isRequired
      }
    ),
    /* @__PURE__ */ jsx(
      UserCustomField,
      {
        values: (_d = formik.values.customUpdates) != null ? _d : [],
        formFields: customFields != null ? customFields : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      UserFamilyMembersSection,
      {
        userId,
        userFamilyMembers: userFamilyMembers != null ? userFamilyMembers : [],
        refreshMembers: () => __async(void 0, null, function* () {
          if (refreshUserFamilyMembers) yield refreshUserFamilyMembers == null ? void 0 : refreshUserFamilyMembers();
        })
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        sizeVariant: "large",
        colorVariant: "primary",
        loading: saving,
        fullWidth: true
      }
    )
  ] }) }) });
};
