"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Cancel } from "@/images/side-bar-icons/Reject.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { RejectDrawer } from "@/v2/components/reject-drawer.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
export const ApprovalButtonSet = ({
  requestForm,
  onApprove,
  onForceApprove,
  onForceReject,
  onReject,
  onDelete,
  loading,
  sx
}) => {
  const { polyglot } = usePolyglot();
  const [isRejectDrawerOpen, setIsRejectDrawerOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ width: "100%" }, sx), children: [
    (!requestForm.canReject && requestForm.canForceReject || onReject && requestForm.canReject || onDelete && requestForm.canDelete) && /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: [
          ...onReject && requestForm.canReject ? [
            {
              icon: /* @__PURE__ */ jsx(Cancel, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey })),
              handler: () => setIsRejectDrawerOpen(true),
              label: polyglot.t("AbsenceViewerApprovalButtons.reject"),
              disabled: false
            }
          ] : [],
          ...!requestForm.canReject && requestForm.canForceReject ? [
            {
              icon: /* @__PURE__ */ jsx(Cancel, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey })),
              handler: () => __async(void 0, null, function* () {
                return yield onForceReject();
              }),
              label: polyglot.t("General.forceReject"),
              disabled: false
            }
          ] : [],
          ...onDelete && requestForm.canDelete ? [
            {
              icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
              handler: () => onDelete(),
              label: polyglot.t("General.delete"),
              disabled: false
            }
          ] : []
        ],
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "medium",
          title: polyglot.t("AbsenceViewerApprovalButtons.actions"),
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end",
          fullWidth: true
        },
        anchorOrigin: { horizontal: "left", vertical: "bottom" },
        transformOrigin: { horizontal: "left", vertical: "top" }
      }
    ) }),
    requestForm.canApprove && /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("AbsenceViewerApprovalButtons.approve"),
        fullWidth: true,
        loading,
        onClick: () => onApprove(),
        sizeVariant: "medium",
        colorVariant: "primary"
      },
      "approve"
    ),
    !requestForm.canApprove && requestForm.canForceApprove && /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.forceApprove"),
        fullWidth: true,
        loading,
        onClick: () => onForceApprove(),
        sizeVariant: "medium",
        colorVariant: "primary"
      },
      "approve"
    ),
    onReject && requestForm.canReject && /* @__PURE__ */ jsx(RejectDrawer, { isOpen: isRejectDrawerOpen, setIsOpen: setIsRejectDrawerOpen, onReject })
  ] });
};
