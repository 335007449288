"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, FormControl, IconButton, Stack } from "@mui/material";
import { AutocompleteComponent } from "@v2/components/forms/autocomplete.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { UserBankAccountFormSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { UserBankAccountAPI } from "@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api";
import {
  CustomFieldComponents,
  ProfileField
} from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import {
  drawerContentSx,
  editDeleteHeaderSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import {
  getCountryOptionsForSelectComponent,
  getEURegionCoutry,
  getUKRegionCountry
} from "@/v2/infrastructure/country/country.util";
import { popularCurrencyOptions } from "@/v2/infrastructure/currency/currency.interface";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const BankForm = ({
  initialValues,
  userId,
  defaultName,
  getUserBankAccounts,
  rowModalMode,
  handleSubmit,
  setFormCurrentlyEditing,
  setIsModalOpen,
  setLoading,
  loading,
  onClose,
  usedForDataImport = false,
  importHandler = () => {
  },
  showEmployee = false
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [rowForDeletion, setRowForDeletion] = useState(0);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const defaultBankModalValues = {
    userId,
    accountName: defaultName,
    accountNumber: "",
    sortCode: "",
    bankName: "",
    effectiveDate: new LocalDate().toDateString(),
    bankAccountId: 0,
    country: "",
    currency: "",
    customUpdates: []
  };
  const validationSchema = useMemo(() => UserBankAccountFormSchema(polyglot), [polyglot]);
  const formik = useFormik({
    initialValues: initialValues ? initialValues : defaultBankModalValues,
    // enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        if (!usedForDataImport) {
          if (!values.effectiveDate || !values.accountName || !values.accountNumber || !values.bankName || !values.sortCode || !values.country || !values.currency) {
            showMessage(polyglot.t("BankForm.errorMessages.updateCreate"), "error");
            return;
          }
          const data = {
            effectiveDate: values.effectiveDate,
            bankName: values.bankName,
            accountName: values.accountName,
            accountNumber: values.accountNumber,
            sortCode: values.sortCode,
            country: values.country,
            currency: values.currency,
            customUpdates: values.customUpdates
          };
          if ("bankAccountId" in values && values.bankAccountId) {
            yield UserBankAccountAPI.update(userId, values.bankAccountId, data);
          } else {
            yield UserBankAccountAPI.create(userId, data);
          }
          showMessage(polyglot.t("BankForm.successMessages.save"), "success");
          yield getUserBankAccounts();
          formik.resetForm();
          setIsModalOpen(false);
        } else {
          importHandler == null ? void 0 : importHandler(values);
        }
      } catch (error) {
        showMessage(polyglot.t("BankForm.errorMessages.update"), "error");
      } finally {
        setLoading(false);
        setFormCurrentlyEditing(false);
        handleSubmit(userId);
      }
    })
  });
  const handleDeleteRow = () => __async(void 0, null, function* () {
    try {
      if (rowForDeletion) {
        yield UserBankAccountAPI.deleteById(userId, rowForDeletion);
      }
      showMessage(polyglot.t("BankForm.successMessages.delete"), "success");
    } catch (error) {
      showMessage(polyglot.t("BankForm.errorMessages.delete"), "error");
    } finally {
      yield getUserBankAccounts();
      handleSubmit(userId);
      setFormCurrentlyEditing(false);
      setRowForDeletion(0);
      setIsModalOpen(false);
      formik.resetForm();
      setIsRemovalModalOpen(false);
    }
  });
  const deleteBankRow = (rowId) => {
    setIsRemovalModalOpen(true);
    setRowForDeletion(rowId);
  };
  const countryOptions = useMemo(() => getCountryOptionsForSelectComponent(), []);
  return /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
    /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
      rowModalMode === "add" ? /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("BankForm.new") }) : /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, editDeleteHeaderSx), { display: "flex", justifyContent: "space-between", width: "100%" }), children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("BankForm.edit") }),
        "bankAccountId" in formik.values && /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: iconCTAButtonSx,
            onClick: (event) => {
              if (!("bankAccountId" in formik.values)) return;
              setAnchorEl(event.currentTarget);
              setIsRemovalModalOpen(true);
              if (formik.values.bankAccountId) deleteBankRow(formik.values.bankAccountId);
            },
            children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
          }
        )
      ] }),
      showEmployee && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: polyglot.t("BankForm.employee") }),
        /* @__PURE__ */ jsx(UserCell, { userId, nameVariant: "title4" })
      ] }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "bankAccount.effectiveDate", children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: (_a = formik.values.effectiveDate) != null ? _a : null,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue("effectiveDate", value);
            }
          },
          name: "effectiveDate",
          label: polyglot.t("BankForm.effectiveDate"),
          error: !!formik.errors.effectiveDate && Boolean(formik.touched.effectiveDate),
          helperText: formik.errors.effectiveDate && Boolean(formik.touched.effectiveDate)
        }
      ) }) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "bankAccount.country", children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "country",
          label: polyglot.t("BankForm.country"),
          options: countryOptions,
          value: countryOptions.find(({ value }) => value === formik.values.country),
          compareValue: (_b = formik.values.country) != null ? _b : " ",
          onChange: (_, x) => {
            var _a2, _b2, _c2;
            formik.setFieldValue("country", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
            const EUCountries = (_b2 = getEURegionCoutry()) == null ? void 0 : _b2.options.map((o) => o.value);
            const isEUCountry = (x == null ? void 0 : x.value) && (EUCountries == null ? void 0 : EUCountries.includes(x.value));
            if (isEUCountry && !formik.values.currency) {
              formik.setFieldValue("currency", "EUR");
              return;
            }
            const UKCountries = (_c2 = getUKRegionCountry()) == null ? void 0 : _c2.options.map((o) => o.value);
            const isUKCountry = (x == null ? void 0 : x.value) && (UKCountries == null ? void 0 : UKCountries.includes(x.value));
            if (isUKCountry && !formik.values.currency) formik.setFieldValue("currency", "GBP");
          },
          error: !!formik.errors.country && Boolean(formik.touched.country),
          helperText: formik.touched.country ? formik.errors.country : ""
        }
      ) }) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "bankAccount.currency", children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "currency",
          label: polyglot.t("BankForm.currency"),
          options: popularCurrencyOptions,
          value: (_c = popularCurrencyOptions.find(({ value }) => value === formik.values.currency)) != null ? _c : null,
          compareValue: (_d = formik.values.currency) != null ? _d : null,
          onChange: (_, x) => {
            var _a2;
            return formik.setFieldValue("currency", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          },
          error: !!formik.errors.currency && formik.touched.currency,
          helperText: formik.touched.currency ? formik.errors.currency : ""
        }
      ) }) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "bankAccount.bankName", children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "bankName",
          label: polyglot.t("BankForm.bank"),
          value: formik.values.bankName,
          onChange: formik.handleChange,
          error: formik.touched.bankName && !!formik.errors.bankName,
          helperText: (_e = formik.touched.bankName && formik.errors.bankName) != null ? _e : " ",
          clearText: () => formik.setFieldValue("bankName", "")
        }
      ) }) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "bankAccount.accountName", children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "accountName",
          label: polyglot.t("BankForm.accountName"),
          value: formik.values.accountName,
          onChange: formik.handleChange,
          error: formik.touched.accountName && !!formik.errors.accountName,
          helperText: (_f = formik.touched.accountName && formik.errors.accountName) != null ? _f : " ",
          clearText: () => formik.setFieldValue("accountName", "")
        }
      ) }) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "bankAccount.accountNumber", children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "accountNumber",
          label: polyglot.t("BankForm.accountNumber"),
          value: formik.values.accountNumber,
          onChange: formik.handleChange,
          error: formik.touched.accountNumber && !!formik.errors.accountNumber,
          helperText: (_g = formik.touched.accountNumber && formik.errors.accountNumber) != null ? _g : " ",
          clearText: () => formik.setFieldValue("accountNumber", "")
        }
      ) }) }),
      /* @__PURE__ */ jsx(ProfileField, { fieldStub: "bankAccount.sortCode", children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "sortCode",
          label: polyglot.t("BankForm.sortCode"),
          value: formik.values.sortCode,
          onChange: formik.handleChange,
          error: formik.touched.sortCode && !!formik.errors.sortCode,
          helperText: (_h = formik.touched.sortCode && formik.errors.sortCode) != null ? _h : " ",
          clearText: () => formik.setFieldValue("sortCode", "")
        }
      ) }) }),
      /* @__PURE__ */ jsx(
        CustomFieldComponents,
        {
          values: formik.values.customUpdates,
          onChange: (values) => formik.setFieldValue("customUpdates", values),
          rowModalMode,
          formName: CustomProfileFormType.BankAccount
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
        /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("General.cancel") }),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "primary"
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isRemovalModalOpen,
        onClose: () => {
          setIsRemovalModalOpen(false);
          setRowForDeletion(0);
        },
        anchorEl,
        takeAction: handleDeleteRow,
        message: polyglot.t("BankForm.confirmDelete"),
        callToAction: polyglot.t("BankForm.callToAction")
      }
    )
  ] });
};
