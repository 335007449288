"use strict";
import { useCallback, useContext } from "react";
import { GlobalContext } from "@/GlobalState";
import { UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const useManagerTeam = () => {
  const [globalState] = useContext(GlobalContext);
  const { user: currentUser } = globalState;
  const { data: userSummary } = useApiClient(UserEndpoints.getUserSummaryById(currentUser.userId), { suspense: false });
  const usersContainManagersTeam = useCallback(
    (userIds) => {
      return userIds.some(
        (userId) => {
          var _a;
          return (_a = userSummary == null ? void 0 : userSummary.directReports) == null ? void 0 : _a.find((directReport) => directReport.userId === userId);
        }
      );
    },
    [userSummary]
  );
  return {
    usersContainManagersTeam
  };
};
