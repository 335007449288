"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE } from "@/lib/routes";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { PayrunListSideMenu } from "@/v2/feature/payroll/components/payrun-list-side-menu.component";
import { UpcomingPayrunTable } from "@/v2/feature/payroll/components/upcoming-payrun-table.component";
import { formatPayrunPeriod } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { GlobalPayrollTableByPeriod } from "@/v2/feature/payroll/features/payroll-global/components/global-payroll-table.component";
import { GlobalPayrollAPI } from "@/v2/feature/payroll/features/payroll-global/global-payroll.api";
import { PayrunStatus } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-status.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const GlobalPayrollPayruns = ({ payroll, refreshPayroll }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const loc = useLocation();
  const initialPayrunId = (_a = loc.state) == null ? void 0 : _a.payrunId;
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [selectedPayrunId, setSelectedPayrunId] = useState();
  const [loadingPayrun, setLoadingPayrun] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const selectedPayrun = useMemo(() => {
    var _a2;
    return (_a2 = payroll == null ? void 0 : payroll.globalPayruns.find(({ id }) => id === selectedPayrunId)) != null ? _a2 : null;
  }, [payroll == null ? void 0 : payroll.globalPayruns, selectedPayrunId]);
  const showPayrun = useCallback(
    (_0, _1) => __async(void 0, [_0, _1], function* (payrollId, { taxYear, payPeriod, period }) {
      setLoadingPayrun(true);
      routerHistory.push(
        generatePath(PAYROLL_COMPANY_PAYRUN_DETAILS_ROUTE, {
          payrollId,
          taxYear,
          payPeriod,
          periodNumber: period
        })
      );
    }),
    [routerHistory]
  );
  const createPayrun = useCallback(
    (_0, _1) => __async(void 0, [_0, _1], function* (payrollId, { taxYear, payPeriod }) {
      setLoadingPayrun(true);
      GlobalPayrollAPI.openPayrun(payrollId, taxYear, payPeriod).then(
        ({ payrollId: payrollId2, taxYear: taxYear2, payPeriod: payPeriod2, period }) => {
          showPayrun(payrollId2, { taxYear: taxYear2, payPeriod: payPeriod2, period });
        },
        (error) => {
          setLoadingPayrun(false);
          showMessage(nestErrorMessage(error), "error");
        }
      );
    }),
    [showMessage, showPayrun]
  );
  const selectInitialPayrun = useCallback(() => {
    if (typeof selectedPayrunId !== "undefined") return;
    if (typeof initialPayrunId === "number" && payroll.globalPayruns.some((p) => p.id === initialPayrunId)) {
      setSelectedPayrunId(initialPayrunId);
      return;
    }
    if (payroll.nextPayRun && !payroll.currentPayRun) {
      setSelectedPayrunId("next-payrun");
      return;
    }
    const openPayrun = payroll.globalPayruns.find((payrun) => payrun.state === "DRAFT");
    if (openPayrun) {
      setSelectedPayrunId(openPayrun.id);
      return;
    }
  }, [initialPayrunId, payroll, selectedPayrunId]);
  useEffect(selectInitialPayrun, [selectInitialPayrun]);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", flex: 1, gap: spacing.g20 }, children: [
    /* @__PURE__ */ jsx(
      PayrunListSideMenu,
      {
        payroll,
        selectedPayrunId,
        onPayrunSelected: (payrunId) => setSelectedPayrunId(payrunId),
        disabled: loadingPayrun,
        sx: { ml: "20px", height: "100vh", flexShrink: 0 }
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, gap: spacing.g20, height: "100vh", overflowX: "hidden" }, children: [
      selectedPayrunId === "next-payrun" && payroll.nextPayRun && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", justifyContent: "space-between", mr: "20px" }, children: [
          /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt20, gap: spacing.g2 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title2", children: formatPayrunPeriod(payroll.nextPayRun, polyglot.locale()) }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("CompanyPayroll.upcoming") })
          ] }),
          /* @__PURE__ */ jsx(
            LoaderButton,
            {
              loading: loadingPayrun,
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => payroll.nextPayRun && createPayrun(payroll.id, payroll.nextPayRun),
              children: polyglot.t("CompanyPayroll.startPayrun")
            }
          )
        ] }),
        /* @__PURE__ */ jsx(
          UpcomingPayrunTable,
          {
            disabled: loadingPayrun,
            payroll,
            nextPayrun: payroll.nextPayRun,
            searchQuery,
            setSearchQuery,
            refreshPayroll: () => __async(void 0, null, function* () {
              yield Promise.all([
                refreshPayroll == null ? void 0 : refreshPayroll()
                /* , refreshPayrun?.() */
              ]);
            }),
            selectedUsers: selectedUserIds,
            setSelectedUsers: (users) => setSelectedUserIds(users),
            noCurrencySymbol: true,
            sx: { display: "flex", flexFlow: "column", overflow: "hidden", mr: spacing.mr20 },
            stickyHeader: true,
            stickyHeaderHeight: "calc(100vh - 142px)",
            separateCurrencyColumn: true
          }
        )
      ] }),
      selectedPayrun && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", justifyContent: "space-between", mr: "20px" }, children: [
          /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt20, gap: spacing.g2 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title2", children: formatPayrunPeriod(selectedPayrun, polyglot.locale()) }),
            /* @__PURE__ */ jsx(PayrunStatus, { state: selectedPayrun.state })
          ] }),
          /* @__PURE__ */ jsx(
            LoaderButton,
            {
              loading: loadingPayrun,
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => showPayrun(selectedPayrun.payrollId, selectedPayrun),
              children: polyglot.t("CompanyPayroll.viewPayrun")
            }
          )
        ] }),
        /* @__PURE__ */ jsx(TableSearch, { query: searchQuery, handleChange: (e) => setSearchQuery(e.target.value) }),
        /* @__PURE__ */ jsx(
          GlobalPayrollTableByPeriod,
          {
            payrollId: selectedPayrun.payrollId,
            taxYear: selectedPayrun.taxYear,
            payPeriod: selectedPayrun.payPeriod,
            period: selectedPayrun.period,
            searchQuery,
            stickyHeaderHeight: "calc(100vh - 191px)"
          }
        )
      ] })
    ] })
  ] }) });
};
