"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState, useCallback } from "react";
import useMessage from "@/hooks/notification.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export function useSequentialDataLoader() {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fetchErrors, setFetchErrors] = useState({});
  const loadData = useCallback(
    (groups, fetchErrorPolyglotKey) => __async(this, null, function* () {
      setLoading(true);
      setError(null);
      setFetchErrors({});
      try {
        for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
          const group = groups[groupIndex];
          yield Promise.all(
            group.map((task) => __async(this, null, function* () {
              try {
                const result = yield task.fetchFunction();
                setData((prevData) => __spreadProps(__spreadValues({}, prevData), {
                  [task.key]: result
                }));
              } catch (taskError) {
                console.error(`Error in task ${String(task.key)}:`, taskError);
                setFetchErrors((prevTaskErrors) => __spreadProps(__spreadValues({}, prevTaskErrors), {
                  [task.key]: taskError.message
                }));
                showMessage(
                  polyglot.t(fetchErrorPolyglotKey, { errorMessage: `${String(task.key)}: ${taskError.message}` }),
                  "error"
                );
              }
            }))
          );
        }
      } catch (err) {
        console.error("Error during loading data:", err);
        const errorMessage = err.message;
        setError(errorMessage);
        showMessage(polyglot.t(fetchErrorPolyglotKey, { errorMessage }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, showMessage]
  );
  const refreshCache = useCallback(
    (refreshCacheFunction, fetchErrorPolyglotKey) => __async(this, null, function* () {
      try {
        yield refreshCacheFunction();
      } catch (err) {
        console.error("Error during cache refresh:", err);
        const errorMessage = err.message;
        setFetchErrors((prevTaskErrors) => __spreadProps(__spreadValues({}, prevTaskErrors), {
          refresh: errorMessage
        }));
        showMessage(polyglot.t(fetchErrorPolyglotKey, { errorMessage }), "error");
      }
    }),
    [polyglot, showMessage]
  );
  const clearData = useCallback(() => {
    setData({});
    setFetchErrors({});
    setError(null);
  }, []);
  return { data, loading, error, fetchErrors, loadData, refreshCache, clearData };
}
