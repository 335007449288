"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { getTaskStatus } from "@v2/feature/task/components/task.util";
import { TaskStatuses } from "@v2/feature/task/task.interface";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { urlify } from "@v2/util/string.util";
import { useLocation, useParams } from "react-router-dom";
import { getLinkToUploadedFile } from "@/api-client/routes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DocumentIcon } from "@/images/documents/PinnedBlack.svg";
import { ReactComponent as Choice } from "@/images/fields/Choice.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Clock } from "@/images/side-bar-icons/Clock.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { checkScopes } from "@/lib/scopes";
import { stringToAttachmentComponentValue } from "@/v2/components/forms/attachment.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { InlineLink } from "@/v2/components/inline-link.component";
import { NotFoundBlock } from "@/v2/components/not-found-block.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { previewFileInNewTab } from "@/v2/feature/documents/documents.util";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const TaskViewModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  task,
  action,
  deleteTask,
  remindAboutTask,
  editTask,
  onboardingClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(
    TaskViewContent,
    {
      task,
      onClose,
      action,
      deleteTask,
      remindAboutTask,
      editTask,
      onboardingClose
    }
  ) });
};
const TaskViewContent = ({
  task,
  onClose,
  action,
  deleteTask,
  remindAboutTask,
  editTask,
  onboardingClose
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const { pathname } = useLocation();
  const inOnboarding = pathname.includes("onboarding");
  const params = useParams();
  const onboardingUserId = inOnboarding ? Number(params.userId) : 0;
  const taskBelongsToOnboardingUser = inOnboarding && (task == null ? void 0 : task.assignedUserId) === onboardingUserId;
  const [showMessage] = useMessage();
  const hasTaskScope = useMemo(
    () => (task == null ? void 0 : task.assignedUserId) ? checkScopes(user, ["task"], { userId: task == null ? void 0 : task.assignedUserId }) : false,
    [task == null ? void 0 : task.assignedUserId, user]
  );
  const hasTaskAdminOrManagerScope = useMemo(
    () => (task == null ? void 0 : task.assignedUserId) ? checkScopes(user, ["task:all", "task:manager"], { userId: task == null ? void 0 : task.assignedUserId }) : false,
    [task == null ? void 0 : task.assignedUserId, user]
  );
  const handleStatusChange = useCallback(() => __async(void 0, null, function* () {
    if (!(task == null ? void 0 : task.id) || !action || !(task == null ? void 0 : task.assignedUserId)) return;
    const statusChange = task.status === TaskStatuses.COMPLETE ? TaskStatuses.INCOMPLETE : TaskStatuses.COMPLETE;
    yield action(task.id, task.assignedUserId, statusChange);
    onClose();
    if (taskBelongsToOnboardingUser && statusChange === TaskStatuses.COMPLETE) {
      if (onboardingClose) yield onboardingClose(true);
    } else {
      if (onboardingClose) yield onboardingClose(false);
    }
  }), [action, onboardingClose, taskBelongsToOnboardingUser, task, onClose]);
  const getLinktoUploadDocument = (id) => __async(void 0, null, function* () {
    try {
      yield DocumentAPI.downloadViaId(id).then(({ url }) => {
        previewFileInNewTab(url);
      });
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
    }
  });
  const tasksOptions = useMemo(() => {
    const options = [];
    if (deleteTask && (task == null ? void 0 : task.id) && hasTaskAdminOrManagerScope) {
      options.push({
        icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          if (task == null ? void 0 : task.id) yield deleteTask([task.id]);
        }),
        label: polyglot.t("General.delete")
      });
    }
    if (editTask && hasTaskAdminOrManagerScope) {
      options.push({
        icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
        handler: () => editTask(),
        label: polyglot.t("General.edit")
      });
    }
    if (remindAboutTask && hasTaskAdminOrManagerScope && task && task.status === TaskStatuses.INCOMPLETE) {
      options.push({
        icon: /* @__PURE__ */ jsx(Clock, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          if (task == null ? void 0 : task.id) yield remindAboutTask(task.id);
        }),
        label: polyglot.t("General.remind")
      });
    }
    if (((task == null ? void 0 : task.assignedUserId) === user.userId || taskBelongsToOnboardingUser) && hasTaskScope) {
      options.push({
        icon: /* @__PURE__ */ jsx(Choice, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          return yield handleStatusChange();
        }),
        label: polyglot.t("General.complete")
      });
    }
    return options;
  }, [
    deleteTask,
    editTask,
    remindAboutTask,
    handleStatusChange,
    hasTaskAdminOrManagerScope,
    taskBelongsToOnboardingUser,
    task,
    user.userId,
    hasTaskScope,
    polyglot
  ]);
  const renderUserDetails = (labelKey, userId) => {
    if (!userId) return null;
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: polyglot.t(labelKey) }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m5, display: "flex", alignItems: "center", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(UserAvatar, { userId, showName: true, nameVariant: "title4", size: "xxsmall" }) })
    ] });
  };
  if (!task) {
    return /* @__PURE__ */ jsx(NotFoundBlock, { title: "Oops", subtitle: polyglot.t("TaskViewModal.notFound") });
  }
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center"
        },
        children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: (_a = task == null ? void 0 : task.name) != null ? _a : "Task" })
      }
    ),
    (task == null ? void 0 : task.description) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: polyglot.t("TaskViewModal.description") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", dangerouslySetInnerHTML: { __html: (_b = urlify(task.description)) != null ? _b : "" } })
    ] }),
    (task == null ? void 0 : task.dueDate) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("TaskViewModal.dueDate") }),
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.title4), {
            color: themeColors.DarkGrey,
            display: "flex",
            alignItem: "center",
            gap: spacing.g10
          }),
          children: getDateString(task.dueDate)
        }
      )
    ] }),
    (task == null ? void 0 : task.completedOn) && task.status === TaskStatuses.COMPLETE && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: polyglot.t("TaskViewModal.completedOn") }),
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.title4), {
            color: themeColors.DarkGrey,
            display: "flex",
            alignItem: "center",
            gap: spacing.g10
          }),
          children: getDateString(task.completedOn)
        }
      )
    ] }),
    (task == null ? void 0 : task.assignedUserId) && renderUserDetails("TaskViewModal.assignedTo", task == null ? void 0 : task.assignedUserId),
    (task == null ? void 0 : task.requestedForId) && renderUserDetails("TaskViewModal.requestedFor", task == null ? void 0 : task.requestedForId),
    (task == null ? void 0 : task.status) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("TaskViewModal.status") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getTaskStatus(task, polyglot) })
    ] }),
    (task == null ? void 0 : task.attachmentValues) && (() => {
      const value = stringToAttachmentComponentValue(task.attachmentValues);
      if (!value) return " ";
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.Grey }, children: polyglot.t("TaskViewModal.attachment") }),
        /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
          value.type === "file" && /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)),
          value.type === "link" || value.type === "file" ? /* @__PURE__ */ jsx(
            InlineLink,
            {
              href: value.type === "link" ? value.name : getLinkToUploadedFile(value.uuid),
              title: value.name,
              style: __spreadProps(__spreadValues({}, themeFonts.title4), {
                color: themeColors.DarkGrey,
                textDecoration: "underline"
              }),
              openInNewWindow: true
            }
          ) : /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "textUnderline",
              sizeVariant: "small",
              onClick: () => __async(void 0, null, function* () {
                yield getLinktoUploadDocument(value.id);
              }),
              children: value.name
            }
          )
        ] })
      ] });
    })(),
    /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: tasksOptions,
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "medium",
          title: polyglot.t("TaskViewModal.actions"),
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end",
          fullWidth: true
        },
        anchorOrigin: { horizontal: "left", vertical: "bottom" },
        transformOrigin: { horizontal: "left", vertical: "top" }
      }
    ) })
  ] });
};
