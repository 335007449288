"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const StyledTextfieldNumber = styled(TextField)(() => ({
  //this class hides background if form is preselected
  "input:-webkit-autofill,input:-webkit-autofill:focus": {
    transition: "background-color 600000s 0s, color 600000s 0s"
  },
  // this hides default time picker icon on chromium based browsers
  'input[type="time"]::-webkit-calendar-picker-indicator': {
    background: "none",
    display: "none"
  },
  "&.MuiTextField-root": {
    //normal placeholder label
    "& label.MuiInputLabel-root.Mui-error": __spreadValues({
      color: `${themeColors.Grey} !important`
    }, themeFonts.caption),
    // label for untoched fields
    "& label.MuiFormLabel-root.MuiInputLabel-root": __spreadValues({
      color: `${themeColors.Grey} !important`
    }, themeFonts.caption),
    //shrunk placeholder on top
    "& label.MuiInputLabel-shrink.MuiInputLabel-sizeSmall": __spreadProps(__spreadValues({
      color: `${themeColors.Grey} !important`
    }, themeFonts.caption), {
      transform: "none",
      marginTop: "2px"
    }),
    //typed in value
    "& input.MuiInputBase-input.MuiInput-input": __spreadProps(__spreadValues({
      color: `${themeColors.DarkGrey} !important`
    }, themeFonts.title4), {
      padding: "0px 0 6px 0px",
      "&.MuiInputBase-inputAdornedEnd": {
        paddingRight: 12
      },
      '&[value=""]': {
        fontWeight: 300
        // lighter placeholder text
      }
    }),
    //
    "& div.MuiInputBase-root.MuiInput-root": __spreadValues({
      color: `${themeColors.DarkGrey} !important`
    }, themeFonts.title4),
    //underlines variations
    // 1. normal state (non active)
    ".MuiInput-underline:before": {
      borderBottom: "1px solid",
      borderBottomColor: themeColors.Grey
    },
    ".MuiInput-underline:after": {
      borderBottom: "1px solid",
      borderBottomColor: themeColors.Grey
    },
    ".MuiInput-underline:not(.Mui-disabled):hover:before": {
      borderBottom: "1px solid",
      borderBottomColor: themeColors.DarkGrey
    },
    // 2. normal state (active)
    ".MuiInput-underline.Mui-focused:after": {
      borderBottom: "1px solid",
      borderBottomColor: themeColors.DarkGrey
    },
    // 2. normal state (active:error)
    ".MuiInput-underline.Mui-error:after": {
      borderBottom: "1px solid",
      borderBottomColor: themeColors.darkRed
    },
    // normal helper text
    "& p.MuiFormHelperText-root": __spreadProps(__spreadValues({}, themeFonts.caption), {
      color: themeColors.Grey,
      marginTop: spacing.m5
    }),
    // error helper text
    "& p.MuiFormHelperText-root.Mui-error": {
      color: themeColors.darkRed
    },
    //input adornment buttons
    "& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium": {
      padding: 0,
      position: "absolute",
      right: 0,
      marginRight: 0
    },
    // hides background of selects auto selection
    "& div.MuiSelect-select": {
      backgroundColor: "transparent"
    },
    // select value font
    "& div.MuiSelect-select.MuiInputBase-input.MuiInput-input": __spreadValues({
      color: `${themeColors.DarkGrey} !important`
    }, themeFonts.title4),
    "& svg.MuiSelect-icon.MuiSelect-iconStandard": {
      fill: `${themeColors.Grey} !important`
    },
    // endorment component alignment
    "& div.MuiAutocomplete-endAdornment": {
      top: "calc(50% - 5px)",
      display: "flex",
      alignItems: "center"
    },
    //clear indictaor autocomplete
    "& button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator": {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      visibility: "visible"
    },
    //hidden label field
    "& .MuiInputBase-hiddenLabel": {
      paddingTop: "0px !important"
    }
  },
  "& div.MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-standard.MuiInputAdornment-sizeSmall": {
    fill: themeColors.Grey,
    marginBottom: spacing.m10
  },
  "& button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator": {
    fill: themeColors.Grey
  },
  "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
    display: "none"
  }
}));
