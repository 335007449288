"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomProfileFormType } from "@v2/feature/custom-fields/custom-profile-fields.dto";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { capitalize, keyBy } from "lodash";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserCustomField } from "@/v2/feature/user/components/user-custom-field.component";
import { useUserFieldsContext } from "@/v2/feature/user/context/user-fields.context";
import { UserAPI, UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { translateCustomField, translateCustomFieldCategory } from "@/v2/infrastructure/i18n/translate.util";
import { spacing } from "@/v2/styles/spacing.styles";
const UserEmergencyContactSchema = (defaultFieldsMap, polyglot) => Yup.object().shape({
  emergencyName: defaultFieldsMap["user.emergencyName"] && defaultFieldsMap["user.emergencyName"].isRequired ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired(),
  emergencyNumber: defaultFieldsMap["user.emergencyNumber"] && defaultFieldsMap["user.emergencyNumber"].isRequired ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired(),
  emergencyRelationship: defaultFieldsMap["user.emergencyRelationship"] && defaultFieldsMap["user.emergencyRelationship"].isRequired ? Yup.string().trim().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired()
});
export const UserEmergencyContactSection = ({ onNext, sx, userId }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const { data: emergencyInfo } = useApiClient(UserEndpoints.getUserEmergencyContact(userId), {
    suspense: false
  });
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedDefaultFieldsByFormName, getCachedCustomFieldsByFormName } = useUserFieldsContext();
  const defaultFields = getCachedDefaultFieldsByFormName(CustomProfileFormType.Emergency);
  const customFields = getCachedCustomFieldsByFormName(CustomProfileFormType.Emergency);
  const defaultFieldsMap = useMemo(() => {
    return keyBy(defaultFields, "fieldStub");
  }, [defaultFields]);
  const customFieldIds = useMemo(() => new Set((customFields != null ? customFields : []).map((field) => field.fieldId)), [customFields]);
  const initialValues = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2;
    const values = {};
    if (defaultFieldsMap["user.emergencyName"]) values.emergencyName = (_a2 = emergencyInfo == null ? void 0 : emergencyInfo.emergencyName) != null ? _a2 : "";
    if (defaultFieldsMap["user.emergencyNumber"]) values.emergencyNumber = (_b2 = emergencyInfo == null ? void 0 : emergencyInfo.emergencyNumber) != null ? _b2 : "";
    if (defaultFieldsMap["user.emergencyRelationship"])
      values.emergencyRelationship = (_c2 = emergencyInfo == null ? void 0 : emergencyInfo.emergencyRelationship) != null ? _c2 : "";
    values.customUpdates = (_e2 = (_d2 = emergencyInfo == null ? void 0 : emergencyInfo.customUpdates) == null ? void 0 : _d2.filter((update) => customFieldIds.has(update.fieldId))) != null ? _e2 : [];
    return values;
  }, [defaultFieldsMap, emergencyInfo, customFieldIds]);
  const formik = useFormik({
    initialValues,
    validationSchema: UserEmergencyContactSchema(defaultFieldsMap, polyglot),
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        yield UserAPI.patchUserEmergencyContactInfo(userId, values);
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.emergencyContactNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: (_a = capitalize(translateCustomFieldCategory(CustomProfileFormType.Emergency, polyglot))) != null ? _a : polyglot.t("EmergencyContactForm.emergencyContact") }),
    defaultFieldsMap["user.emergencyName"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "emergencyName",
        label: (_b = translateCustomField(defaultFieldsMap["user.emergencyName"].fieldName, polyglot)) != null ? _b : polyglot.t("EmergencyContactForm.fullName"),
        value: formik.values.emergencyName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("emergencyName", ""),
        helperText: hasSubmitted && formik.errors.emergencyName,
        error: hasSubmitted && !!formik.errors.emergencyName,
        autoFocus: true,
        required: (_c = defaultFieldsMap["user.emergencyName"]) == null ? void 0 : _c.isRequired
      }
    ),
    defaultFieldsMap["user.emergencyNumber"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "emergencyNumber",
        label: (_d = translateCustomField(defaultFieldsMap["user.emergencyNumber"].fieldName, polyglot)) != null ? _d : polyglot.t("EmergencyContactForm.phone"),
        type: "tel",
        value: formik.values.emergencyNumber,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("emergencyNumber", ""),
        helperText: hasSubmitted && formik.errors.emergencyNumber,
        error: hasSubmitted && !!formik.errors.emergencyNumber,
        required: (_e = defaultFieldsMap["user.emergencyNumber"]) == null ? void 0 : _e.isRequired
      }
    ),
    defaultFieldsMap["user.emergencyRelationship"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "emergencyRelationship",
        label: (_f = translateCustomField(defaultFieldsMap["user.emergencyRelationship"].fieldName, polyglot)) != null ? _f : polyglot.t("EmergencyContactForm.relationship"),
        value: formik.values.emergencyRelationship,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("emergencyRelationship", ""),
        helperText: hasSubmitted && formik.errors.emergencyRelationship,
        error: hasSubmitted && !!formik.errors.emergencyRelationship,
        required: (_g = defaultFieldsMap["user.emergencyRelationship"]) == null ? void 0 : _g.isRequired
      }
    ),
    /* @__PURE__ */ jsx(
      UserCustomField,
      {
        values: (_h = formik.values.customUpdates) != null ? _h : [],
        formFields: customFields != null ? customFields : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        sizeVariant: "large",
        colorVariant: "primary",
        loading: saving,
        fullWidth: true
      }
    )
  ] }) }) });
};
