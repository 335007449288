"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/system";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { BasicServerTable } from "@/v2/components/table/server-side-table.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import { NewDocumentAction } from "@/v2/feature/document-new/components/new-document-action.component";
import {
  getBelongsToByMemberRule,
  getCustomRuleString,
  getImageByFileName
} from "@/v2/feature/document-new/document-new.util";
import {
  ActionView
} from "@/v2/feature/document-new/interfaces/document-new.interface";
import { DisplayDocumentNewStatus } from "@/v2/feature/document-new/utils/new-document-status.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
export const NewDocumentTable = ({
  documentTypesLookup,
  documentList,
  handleRowClick,
  loading,
  pagination,
  setPagination,
  actions,
  customRuleDataset,
  totalPages,
  totalItems,
  selectColumn
}) => {
  const { polyglot } = usePolyglot();
  const columnsWithoutSelection = useMemo(
    () => [
      {
        header: () => polyglot.t("DocumentTable.name"),
        accessorFn: (row) => row.name,
        id: "name",
        enableSorting: false,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              alignItems: "center",
              gap: spacing.s1,
              boxSizing: "border-box"
            },
            children: [
              /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: getImageByFileName(original) }),
              original.name ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.name }) : /* @__PURE__ */ jsx(EmptyCell, {})
            ]
          }
        )
      },
      {
        header: () => polyglot.t("DocumentTable.type"),
        accessorFn: (row) => row,
        id: "typeId",
        enableSorting: false,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => documentTypesLookup && original.typeId && documentTypesLookup[original.typeId] ? /* @__PURE__ */ jsx("div", { children: documentTypesLookup[original.typeId].label }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("DocumentTable.belongsTo"),
        accessorFn: (row) => row,
        id: "userId",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a;
          const ruleString = original && original.membersRule === UserSelectFiltersOptions.CustomRule && original.customRule ? getCustomRuleString(
            original.customRule,
            customRuleDataset.sites,
            customRuleDataset.departments,
            (_a = customRuleDataset.entties) != null ? _a : []
          ) : void 0;
          return /* @__PURE__ */ jsx("div", { children: original ? getBelongsToByMemberRule(original, "caption", ruleString) : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Status",
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return original ? /* @__PURE__ */ jsx(DisplayDocumentNewStatus, { document: original }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => polyglot.t("DocumentTable.createdOn"),
        accessorFn: (row) => row,
        id: "createdAt",
        enableSorting: true,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx("div", { children: getDateString(original.createdAt) });
        }
      },
      {
        header: () => polyglot.t("DocumentTable.expiryDate"),
        accessorFn: (row) => row,
        id: "expiryDate",
        enableSorting: false,
        sortingFn: (a, b) => sortNumeric(
          a,
          b,
          (item) => {
            var _a, _b;
            return ((_a = item.documentSettings) == null ? void 0 : _a.expiryDate) ? new Date((_b = item.documentSettings) == null ? void 0 : _b.expiryDate).getTime() : 0;
          }
        ),
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: ((_a = original.documentSettings) == null ? void 0 : _a.expiryDate) ? dateAPItoDisplay((_b = original.documentSettings) == null ? void 0 : _b.expiryDate) : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "",
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const showAction = original.showSign || original.showEdit || original.showDownload || original.showCancel || original.showDelete || original.showRemind || original.showUpload;
          return showAction ? /* @__PURE__ */ jsx(NewDocumentAction, { document: original, actions, view: ActionView.Table }) : null;
        }
      }
    ],
    [polyglot, documentTypesLookup, actions, customRuleDataset]
  );
  const columns = useMemo(() => {
    return [...selectColumn != null ? selectColumn : [], ...columnsWithoutSelection];
  }, [selectColumn, columnsWithoutSelection]);
  return /* @__PURE__ */ jsx(
    BasicServerTable,
    {
      rowData: documentList != null ? documentList : [],
      columnData: columns,
      loading,
      pagination,
      setPagination,
      totalPages,
      totalItems,
      stickyHeader: true,
      stickyHeaderHeight: "calc(100vh - 255px)",
      rowClick: handleRowClick,
      fixedSecondColumn: !!selectColumn
    }
  );
};
