"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { CalculationMethodMonth1, CalculationMethodStandard } from "@/lib/payroll";
import { FieldStructure } from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { definitionListSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { booleanValues } from "@/v2/util/array.util";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
import { toTitleCase } from "@/v2/util/string.util";
export const UserProfileTaxDetailsUK = ({ payrollValues, payrollId }) => {
  const formatStudentLoans = useCallback((record) => {
    const combinedLoans = [];
    if (record.studentLoan) combinedLoans.push(`${record.studentLoan}`);
    if (record.postgradLoan) combinedLoans.push(`Postgraduate`);
    return combinedLoans.length > 0 ? combinedLoans.join(", ") : void 0;
  }, []);
  const combinedStudentLoanAndPostgradLoan = useMemo(() => payrollValues && formatStudentLoans(payrollValues), [
    payrollValues,
    formatStudentLoans
  ]);
  const formatDirectorshipStint = useCallback((record) => {
    let directorshipDates = "";
    try {
      if ((record == null ? void 0 : record.directorFrom) && (record == null ? void 0 : record.directorTo))
        directorshipDates = `(${dateAPItoDisplay(record == null ? void 0 : record.directorFrom)} - ${dateAPItoDisplay(record == null ? void 0 : record.directorTo)})`;
      if ((record == null ? void 0 : record.directorFrom) && !(record == null ? void 0 : record.directorTo))
        directorshipDates = `(${dateAPItoDisplay(record == null ? void 0 : record.directorFrom)} - ?)`;
    } catch (error) {
      console.error("Error formatting directorship dates:", error);
      directorshipDates = "(Invalid Dates)";
    }
    return directorshipDates;
  }, []);
  const directorshipStint = useMemo(() => payrollValues && formatDirectorshipStint(payrollValues), [
    payrollValues,
    formatDirectorshipStint
  ]);
  return /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
    payrollId && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "Payroll ID", fieldValue: payrollId }),
    payrollValues.taxCode && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "Tax code", fieldValue: payrollValues.taxCode }),
    booleanValues.has(payrollValues.week1Month1) && /* @__PURE__ */ jsx(
      FieldStructure,
      {
        fieldTitle: "Calculation method",
        fieldValue: payrollValues.week1Month1 ? CalculationMethodMonth1 : CalculationMethodStandard
      }
    ),
    payrollValues.starterDeclaration && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "Starter declaration", fieldValue: payrollValues.starterDeclaration }),
    payrollValues.niTable && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "NI category", fieldValue: payrollValues.niTable }),
    payrollValues.niNumber && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "NI number", fieldValue: payrollValues.niNumber }),
    combinedStudentLoanAndPostgradLoan && combinedStudentLoanAndPostgradLoan !== "None" && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "Student Loan", fieldValue: combinedStudentLoanAndPostgradLoan }),
    payrollValues.legalGender && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "Legal gender", fieldValue: toTitleCase(payrollValues.legalGender) }),
    payrollValues.isDirector && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "Directorship", fieldValue: `Yes ${directorshipStint}` })
  ] });
};
