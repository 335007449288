"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box, FormControl } from "@mui/material";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const MultipleChoiceQuestion = ({
  question,
  answerArray,
  handleAnswerChange,
  entry,
  showError
}) => {
  var _a;
  const { currentAnswer, currentComment } = useMemo(() => {
    const answerObject = (answerArray == null ? void 0 : answerArray.find((ans) => ans.questionId === question.id)) || { answer: "", comment: "" };
    return { currentAnswer: answerObject.answer, currentComment: answerObject.comment };
  }, [answerArray, question.id]);
  if (!question || !entry) return /* @__PURE__ */ jsx(Fragment, {});
  const handleChoiceChange = (value) => {
    handleAnswerChange(question.id, { updatedAnswer: value, comment: currentComment }, entry);
  };
  const handleCommentChange = (value) => {
    handleAnswerChange(question.id, { updatedAnswer: currentAnswer, comment: value }, entry);
  };
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24 }, children: [
    /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%", margin: 0 }, error: false, disabled: false, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: question && (question == null ? void 0 : question.choiceOptions) && ((_a = Object.keys(question == null ? void 0 : question.choiceOptions)) == null ? void 0 : _a.map((a) => /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: a,
        label: question == null ? void 0 : question.choiceOptions[+a],
        disabled: false,
        checked: Boolean(currentAnswer == null ? void 0 : currentAnswer.split(",").find((s) => s === a)),
        onChange: () => {
          var _a2;
          let finalArray = [];
          let selectedValues = (_a2 = currentAnswer == null ? void 0 : currentAnswer.split(",").filter((v) => v)) != null ? _a2 : [];
          if (selectedValues.includes(a)) {
            finalArray = selectedValues.filter((value) => value !== a);
          } else {
            finalArray = [...selectedValues, a];
          }
          handleChoiceChange(finalArray.join(","));
        }
      }
    ))) }) }),
    showError && !currentAnswer && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: showError ? themeColors.darkRed : themeColors.DarkGrey }, children: "This field is required" }),
    question.hasComment && /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: spacing.g8,
          pt: spacing.p16,
          borderTop: `${borders.background}`
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Add comment" }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: question.isCommentRequired ? "Required" : "Optional" })
          ] }),
          /* @__PURE__ */ jsx(
            RichTextField,
            {
              value: currentComment,
              onChange: (value) => handleCommentChange(value),
              error: showError && question.hasComment && question.isCommentRequired,
              helperText: showError && question.hasComment && question.isCommentRequired && !currentComment ? "This field is required" : ""
            },
            question.id
          )
        ]
      }
    )
  ] });
};
