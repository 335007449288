"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class SurveyEntryAPI {
  static updateSurveyEntry(surveyEntryBody) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/survey-entry`, surveyEntryBody)).data;
    });
  }
  static remindParticipantByEntryId(entryId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/survey-entry/${entryId}/remind`)).data;
    });
  }
}
export class SurveyEntryEndpoints {
  static getUserEntries(search) {
    const urlParams = new URLSearchParams(__spreadValues({}, search && { search }));
    return {
      url: `/apiv2/survey-entry/user?${urlParams.toString()}`
    };
  }
  static getSurveyCycleLaunchDetailsForCompanyAndUser() {
    return {
      url: "/apiv2/survey-cycle/launch/ongoing-details"
    };
  }
  static getSurveyEntryDetail(entryId, cycleId) {
    return {
      url: `/apiv2/survey-entry/${entryId}/${cycleId}/detail`
    };
  }
}
