"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { TaskFormModal } from "@v2/feature/task/components/task-add-modal/task-form-modal.component";
import { TaskTable } from "@v2/feature/task/components/task-table/task-table.component";
import { TaskStatuses, TaskTabFilter } from "@v2/feature/task/task.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useParams } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TaskLaunchModal } from "@/v2/feature/task/components/task-launch/task-launch-modal.component";
import { TaskViewModal } from "@/v2/feature/task/components/task-view/task-view-modal.component";
import { TaskAPI, TaskEndpoints } from "@/v2/feature/task/task.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PersonalTask = ({
  userId,
  setIsTaskFormModalOpen,
  isTaskFormModalOpen,
  reach,
  loading,
  forOffboarding = false,
  refreshOffboarding
}) => {
  const { polyglot } = usePolyglot();
  const TabFilter = useMemo(
    () => [
      { name: polyglot.t("TasksListPage.inSevenDays"), value: TaskTabFilter.InSevenDays },
      { name: polyglot.t("TasksListPage.inThirtyDays"), value: TaskTabFilter.InThirtyDays },
      { name: polyglot.t("TasksListPage.all"), value: TaskTabFilter.All },
      { name: polyglot.t("TasksListPage.done"), value: TaskTabFilter.Done }
    ],
    [polyglot]
  );
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [isLaunchModalOpen, setIsLaunchModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isTaskViewModalOpen, setIsTaskViewModalOpen] = useState(false);
  const [paginationState, setPaginationState] = useState({ pageIndex: 1, pageSize: 100 });
  const [sortingState, setSortingState] = useState([]);
  const [tableState, setTableState] = useState({
    filterValue: TaskTabFilter.InSevenDays,
    searchInput: ""
  });
  const { filterValue, searchInput } = tableState;
  const { data: allTasks, isLoading, mutate: refreshTasks } = useApiClient(
    TaskEndpoints.listTasks(userId, paginationState, {
      sortField: sortingState[0],
      status: filterValue,
      search: searchInput,
      forOffboarding
    }),
    {
      suspense: false
    }
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [showMessage] = useMessage();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const params = useParams();
  const targetUserId = Number(params.userId);
  const { getScopesContext, hasScopes } = useScopes();
  const hasTaskManagerScopes = hasScopes(["task:manager"], getScopesContext({ userId: targetUserId }));
  const hasTaskAdminScopes = hasScopes(["task:all"], getScopesContext(user));
  const isManagerOfTargetUserId = useMemo(() => {
    return hasTaskManagerScopes && user.reports.includes(targetUserId);
  }, [hasTaskManagerScopes, targetUserId, user.reports]);
  const hasVisibility = useMemo(() => hasTaskAdminScopes || isManagerOfTargetUserId || forOffboarding, [
    hasTaskAdminScopes,
    isManagerOfTargetUserId,
    forOffboarding
  ]);
  const updateTableState = useCallback((newState) => {
    setTableState((prevState) => {
      if ("filterValue" in newState || "searchInput" in newState) {
        setPaginationState((p) => __spreadProps(__spreadValues({}, p), { pageIndex: 1 }));
      }
      return __spreadValues(__spreadValues({}, prevState), newState);
    });
  }, []);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    if (refreshTasks) yield refreshTasks();
    if (refreshOffboarding) yield refreshOffboarding();
  }), [refreshTasks, refreshOffboarding]);
  const closeTaskFormModal = () => {
    setTaskToEdit(null);
    setIsTaskFormModalOpen(false);
  };
  const updateTaskStatus = (taskId, assignedUserId, status) => __async(void 0, null, function* () {
    try {
      yield TaskAPI.updateTaskStatus(taskId, assignedUserId, { status });
      showMessage(polyglot.t("PersonalTask.successMessages.updateStatus"), "success");
      yield refresh();
      if (selectedTask) setSelectedTask(__spreadProps(__spreadValues({}, selectedTask), { status }));
    } catch (error) {
      showMessage(
        polyglot.t("PersonalTask.errorMessages.updateStatus", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  const deleteTask = (taskId) => __async(void 0, null, function* () {
    try {
      yield TaskAPI.removeTask(taskId);
      showMessage(polyglot.t("PersonalTask.successMessages.delete"), "success");
      yield refresh();
    } catch (error) {
      showMessage(polyglot.t("PersonalTask.errorMessages.remove", { errorMessage: nestErrorMessage(error) }), "error");
    }
  });
  const remindAboutTask = (taskId) => __async(void 0, null, function* () {
    try {
      yield TaskAPI.remindAboutTask(taskId);
      showMessage(polyglot.t("PersonalTask.successMessages.remind"), "success");
      yield refresh();
    } catch (error) {
      showMessage(polyglot.t("PersonalTask.errorMessages.remind", { errorMessage: nestErrorMessage(error) }), "error");
    }
  });
  const confirmDeleteTask = (event) => {
    setAnchorEl(event.currentTarget);
    setIsRemovalModalOpen(true);
  };
  const deleteTaskAfterConfirmation = useCallback(
    (tasksIds) => __async(void 0, null, function* () {
      try {
        setIsRemovalModalOpen(false);
        setAnchorEl(null);
        yield TaskAPI.removeTasks(tasksIds, forOffboarding);
        showMessage(polyglot.t("PersonalTask.successMessages.deleteMult"), "success");
        setSelectionModel([]);
        yield refresh();
      } catch (error) {
        showMessage(
          polyglot.t("PersonalTask.errorMessages.removeMult", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [forOffboarding, showMessage, polyglot, refresh]
  );
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: loading || isLoading, sx: __spreadProps(__spreadValues({}, spacing.pt20), { overflow: "hidden" }), children: [
    /* @__PURE__ */ jsxs(Box, { sx: {}, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
        forOffboarding ? /* @__PURE__ */ jsx(Box, {}) : /* @__PURE__ */ jsx(
          TabFilterButtons,
          {
            filters: TabFilter,
            setFilterValue: (filterValue2) => updateTableState({ filterValue: filterValue2 }),
            filterValue,
            hasSearch: true,
            onFilterChange: ({ filterValue: filterValue2, searchInput: searchInput2 }) => {
              updateTableState({
                filterValue: filterValue2,
                searchInput: searchInput2
              });
            }
          }
        ),
        /* @__PURE__ */ jsx(Box, { children: (hasTaskAdminScopes || isManagerOfTargetUserId) && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end", alignItems: "right", gap: spacing.g15 }, children: selectionModel.length > 0 ? /* @__PURE__ */ jsxs(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "secondary",
            style: { display: "flex", gap: spacing.g5 },
            onClick: (event) => {
              confirmDeleteTask(event);
            },
            children: [
              /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
              " ",
              /* @__PURE__ */ jsx(Fragment, { children: polyglot.t("General.delete") })
            ]
          }
        ) : reach === "userProfile" && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: () => setIsLaunchModalOpen(true),
              children: "Checklist"
            }
          ),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => setIsTaskFormModalOpen(true),
              children: polyglot.t("PersonalTask.new")
            }
          )
        ] }) }) })
      ] }),
      allTasks && /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
        TaskTable,
        {
          tasks: allTasks,
          pagination: [paginationState, setPaginationState],
          sorting: [sortingState, setSortingState],
          openSelectedTaskModal: (task) => {
            setSelectedTask(task);
            setIsTaskViewModalOpen(true);
          },
          openEditTaskModal: (task) => {
            setTaskToEdit(task);
            setIsTaskFormModalOpen(true);
          },
          markAsComplete: (task) => __async(void 0, null, function* () {
            if (task.id && task.assignedUserId)
              yield updateTaskStatus(task.id, task.assignedUserId, TaskStatuses.COMPLETE);
          }),
          showUserLink: false,
          loading,
          setSelectionModel,
          selectionModel,
          currentUserId: user.userId,
          deleteAction: deleteTask,
          remindAction: remindAboutTask,
          reach: "me",
          stickyHeader: true
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      TaskFormModal,
      {
        isOpen: isTaskFormModalOpen,
        setIsOpen: setIsTaskFormModalOpen,
        onClose: closeTaskFormModal,
        userId,
        formData: taskToEdit,
        refresh
      }
    ),
    selectedTask && /* @__PURE__ */ jsx(
      TaskViewModal,
      {
        isOpen: isTaskViewModalOpen,
        setIsOpen: setIsTaskViewModalOpen,
        onClose: () => {
          setSelectedTask(null);
          setIsTaskViewModalOpen(false);
        },
        task: selectedTask,
        action: updateTaskStatus,
        remindAboutTask,
        deleteTask: () => __async(void 0, null, function* () {
          if (!selectedTask) return;
          yield deleteTaskAfterConfirmation([selectedTask.id]);
          setIsTaskViewModalOpen(false);
        }),
        editTask: () => {
          setTaskToEdit(selectedTask);
          setIsTaskFormModalOpen(true);
          setIsTaskViewModalOpen(false);
        }
      }
    ),
    hasVisibility && /* @__PURE__ */ jsx(
      TaskLaunchModal,
      {
        isOpen: isLaunchModalOpen,
        setIsOpen: setIsLaunchModalOpen,
        onClose: () => setIsLaunchModalOpen(false),
        refresh,
        assignUserId: userId,
        reach: hasTaskAdminScopes ? "company" : hasTaskManagerScopes ? "team" : "user"
      }
    ),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isRemovalModalOpen,
        onClose: () => setIsRemovalModalOpen(false),
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          return deleteTaskAfterConfirmation(selectionModel);
        }),
        message: polyglot.t("PersonalTask.confirmDelete", { smart_count: selectionModel ? selectionModel.length : 0 }),
        callToAction: polyglot.t("PersonalTask.delete", { smart_count: selectionModel ? selectionModel.length : 0 })
      }
    )
  ] });
};
