"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { GrowthChip } from "@v2/feature/growth/shared/components/growth-chip.component";
import { generatePath, Link } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "@/images/icons/calendar.svg";
import { ReactComponent as KeyResultsIcon } from "@/images/icons/key-results.svg";
import { GROWTH_GOAL_ROUTE } from "@/lib/routes";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ListItem } from "@/v2/feature/growth/goals/components/list-item.component";
import { LocalDate } from "@/v2/util/local-date";
export const GoalListItem = ({ goal, showDescription, hoverState }) => {
  const chips = useMemo(() => {
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        GrowthChip,
        {
          options: {
            text: goal.keyResultsCount,
            icon: /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: /* @__PURE__ */ jsx(KeyResultsIcon, {}) })
          }
        }
      ),
      /* @__PURE__ */ jsx(
        GrowthChip,
        {
          options: {
            text: new LocalDate(goal.dueDate).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" }),
            icon: /* @__PURE__ */ jsx(CalendarIcon, { width: "12px" })
          }
        }
      ),
      /* @__PURE__ */ jsx(
        GrowthChip,
        {
          options: {
            text: "Factor",
            icon: "#000"
          }
        }
      )
    ] });
  }, [goal.dueDate, goal.keyResultsCount]);
  return /* @__PURE__ */ jsx(
    Link,
    {
      to: generatePath(GROWTH_GOAL_ROUTE, { goalId: goal.id }),
      title: goal.name,
      style: {
        width: "100%",
        textDecoration: "none",
        color: "inherit"
      },
      children: /* @__PURE__ */ jsx(
        ListItem,
        {
          progressBar: { progress: goal.lastProgress },
          details: {
            title: goal.name,
            description: showDescription ? goal.description : null,
            chips
          },
          hoverState,
          endSection: /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(
            ChipComponent,
            {
              name: goal.status,
              textVariant: "title4",
              backgroundColor: "white",
              textColor: "TextPrimary",
              border: "black2"
            }
          ) })
        }
      )
    }
  );
};
