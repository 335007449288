"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Autocomplete, Box, FormControl, Paper, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import { capitalize, keyBy } from "lodash";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDownACIcon } from "@/images/side-bar-icons/ArrowDownAC.svg";
import { ReactComponent as ChoseIcon } from "@/images/side-bar-icons/Chose.svg";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { ClearIcon } from "@/v2/components/theme-components/clear-icon.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserCustomField } from "@/v2/feature/user/components/user-custom-field.component";
import { useUserFieldsContext } from "@/v2/feature/user/context/user-fields.context";
import {
  UserAddressAPI,
  UserBankAccountEndpoints
} from "@/v2/feature/user/features/user-forms/user-address/user-address.api";
import { areAllValuesNull } from "@/v2/feature/user-onboarding/user-onboarding.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { getCountriesForNationality } from "@/v2/infrastructure/country/country.util";
import { dateFieldTest } from "@/v2/infrastructure/date/date-format.util";
import { translateCustomField, translateCustomFieldCategory } from "@/v2/infrastructure/i18n/translate.util";
import { StyledAuto, StyledAutoTextfield } from "@/v2/styles/autocomplete.styles";
import { iconSize, StyledMenuItem } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
const countryOptions = pipe(
  getCountriesForNationality(),
  RA.map(
    (r) => {
      var _a;
      return pipe(
        (_a = r.options) != null ? _a : [],
        RA.map((x) => __spreadProps(__spreadValues({}, x), { group: r.label }))
      );
    }
  ),
  RA.flatten
);
const UserAddressSchema = (defaultFieldsMap, polyglot) => Yup.object().shape({
  effectiveDate: defaultFieldsMap["address.effectiveDate"] && defaultFieldsMap["address.effectiveDate"].isRequired ? Yup.string().test(dateFieldTest).nullable().required(
    polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.effectiveDate") })
  ) : Yup.string().notRequired(),
  addressLine1: defaultFieldsMap["address.addressLine1"] && defaultFieldsMap["address.addressLine1"].isRequired ? Yup.string().max(
    30,
    polyglot.t("AddressCard.schema.maxLength", {
      field: polyglot.t("AddressCard.addressLine1"),
      maxLength: 30
    })
  ).nullable().required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.addressLine1") })) : Yup.string().nullable().notRequired(),
  addressLine2: defaultFieldsMap["address.addressLine2"] && defaultFieldsMap["address.addressLine2"].isRequired ? Yup.string().max(
    30,
    polyglot.t("AddressCard.schema.maxLength", {
      field: polyglot.t("AddressCard.addressLine2"),
      maxLength: 30
    })
  ).nullable().required() : Yup.string().max(
    30,
    polyglot.t("AddressCard.schema.maxLength", {
      field: polyglot.t("AddressCard.addressLine2"),
      maxLength: 30
    })
  ).nullable().notRequired(),
  postcode: defaultFieldsMap["address.postcode"] && defaultFieldsMap["address.postcode"].isRequired ? Yup.string().max(
    20,
    polyglot.t("AddressCard.schema.maxLength", { field: polyglot.t("AddressCard.postcode"), maxLength: 20 })
  ).nullable().required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.postcode") })) : Yup.string().nullable().notRequired(),
  city: defaultFieldsMap["address.city"] && defaultFieldsMap["address.city"].isRequired ? Yup.string().max(
    30,
    polyglot.t("AddressCard.schema.maxLength", { field: polyglot.t("AddressCard.city"), maxLength: 30 })
  ).nullable().required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.city") })) : Yup.string().nullable().notRequired(),
  country: defaultFieldsMap["address.country"] && defaultFieldsMap["address.country"].isRequired ? Yup.string().max(
    50,
    polyglot.t("AddressCard.schema.maxLength", { field: polyglot.t("AddressCard.country"), maxLength: 50 })
  ).nullable().required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.country") })) : Yup.string().nullable().notRequired()
});
export const UserAddressSection = ({ onNext, sx, userId }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const { data: addressInfo } = useApiClient(UserBankAccountEndpoints.findCurrentByUserId(userId), {
    suspense: false
  });
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedDefaultFieldsByFormName, getCachedCustomFieldsByFormName } = useUserFieldsContext();
  const defaultFields = getCachedDefaultFieldsByFormName(CustomProfileFormType.Address);
  const customFields = getCachedCustomFieldsByFormName(CustomProfileFormType.Address);
  const defaultFieldsMap = useMemo(() => {
    return keyBy(defaultFields, "fieldStub");
  }, [defaultFields]);
  const customFieldIds = useMemo(() => new Set((customFields != null ? customFields : []).map((field) => field.fieldId)), [customFields]);
  const validationSchema = useMemo(() => UserAddressSchema(defaultFieldsMap, polyglot), [polyglot, defaultFieldsMap]);
  const initialValues = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2;
    const values = {};
    if (defaultFieldsMap["address.addressLine1"]) values.addressLine1 = (_a2 = addressInfo == null ? void 0 : addressInfo.addressLine1) != null ? _a2 : null;
    if (defaultFieldsMap["address.addressLine2"]) values.addressLine2 = (_b2 = addressInfo == null ? void 0 : addressInfo.addressLine2) != null ? _b2 : null;
    if (defaultFieldsMap["address.city"]) values.city = (_c2 = addressInfo == null ? void 0 : addressInfo.city) != null ? _c2 : null;
    if (defaultFieldsMap["address.postcode"]) values.postcode = (_d2 = addressInfo == null ? void 0 : addressInfo.postcode) != null ? _d2 : null;
    if (defaultFieldsMap["address.country"]) values.country = (_e2 = addressInfo == null ? void 0 : addressInfo.country) != null ? _e2 : void 0;
    if (defaultFieldsMap["address.effectiveDate"]) values.effectiveDate = (_f2 = addressInfo == null ? void 0 : addressInfo.effectiveDate) != null ? _f2 : void 0;
    values.customUpdates = (_h2 = (_g2 = addressInfo == null ? void 0 : addressInfo.customUpdates) == null ? void 0 : _g2.filter((update) => customFieldIds.has(update.fieldId))) != null ? _h2 : [];
    return values;
  }, [defaultFieldsMap, addressInfo, customFieldIds]);
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2;
      setSaving(true);
      const allValuesNull = areAllValuesNull(values);
      try {
        if (allValuesNull) {
          onNext();
        } else {
          const valuesWithEffectiveDates = __spreadProps(__spreadValues({}, values), {
            effectiveDate: (_a2 = addressInfo == null ? void 0 : addressInfo.effectiveDate) != null ? _a2 : new LocalDate().toDateString()
          });
          if ("userAddressId" in values) {
            yield UserAddressAPI.update(userId, valuesWithEffectiveDates);
          } else {
            yield UserAddressAPI.create(userId, valuesWithEffectiveDates);
          }
          onNext();
        }
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.addressNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: (_a = capitalize(translateCustomFieldCategory(CustomProfileFormType.Address, polyglot))) != null ? _a : polyglot.t("AddressForm.homeAddress") }),
    defaultFieldsMap["address.addressLine1"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "addressLine1",
        label: (_b = translateCustomField(defaultFieldsMap["address.addressLine1"].fieldName, polyglot)) != null ? _b : polyglot.t("AddressForm.addressLine1"),
        value: formik.values.addressLine1,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("addressLine1", ""),
        helperText: hasSubmitted && formik.errors.addressLine1,
        error: hasSubmitted && !!formik.errors.addressLine1,
        maxLength: 30,
        autoFocus: true,
        required: (_c = defaultFieldsMap["address.addressLine1"]) == null ? void 0 : _c.isRequired
      }
    ),
    defaultFieldsMap["address.addressLine2"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "addressLine2",
        label: (_d = translateCustomField(defaultFieldsMap["address.addressLine2"].fieldName, polyglot)) != null ? _d : polyglot.t("AddressForm.addressLine2"),
        value: formik.values.addressLine2,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("addressLine2", ""),
        helperText: hasSubmitted && formik.errors.addressLine2,
        error: hasSubmitted && !!formik.errors.addressLine2,
        maxLength: 30,
        required: (_e = defaultFieldsMap["address.addressLine2"]) == null ? void 0 : _e.isRequired
      }
    ),
    defaultFieldsMap["address.city"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "city",
        label: (_f = translateCustomField(defaultFieldsMap["address.city"].fieldName, polyglot)) != null ? _f : polyglot.t("AddressForm.city"),
        value: formik.values.city,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("city", ""),
        helperText: hasSubmitted && formik.errors.city,
        error: hasSubmitted && !!formik.errors.city,
        maxLength: 30,
        required: (_g = defaultFieldsMap["address.city"]) == null ? void 0 : _g.isRequired
      }
    ),
    defaultFieldsMap["address.postcode"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "postcode",
        label: (_h = translateCustomField(defaultFieldsMap["address.postcode"].fieldName, polyglot)) != null ? _h : polyglot.t("AddressForm.postCode"),
        value: formik.values.postcode,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("postcode", ""),
        helperText: hasSubmitted && formik.errors.postcode,
        error: hasSubmitted && !!formik.errors.postcode,
        maxLength: 20,
        required: (_i = defaultFieldsMap["address.postcode"]) == null ? void 0 : _i.isRequired
      }
    ),
    defaultFieldsMap["address.country"] && /* @__PURE__ */ jsx(FormControl, { fullWidth: true, children: /* @__PURE__ */ jsx(
      Autocomplete,
      {
        fullWidth: true,
        options: countryOptions,
        getOptionLabel: (option) => option.label,
        value: (_j = countryOptions.find(({ value }) => value === formik.values.country)) != null ? _j : null,
        renderOption: (props, option) => {
          return option.value === formik.values.country ? /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
              },
              children: [
                /* @__PURE__ */ jsx(Typography, { variant: "title4", children: option.label }),
                /* @__PURE__ */ jsx(ChoseIcon, __spreadValues({}, iconSize))
              ]
            }
          ) })) : /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: option.label }) }));
        },
        groupBy: (option) => option.group,
        onChange: (_, x) => {
          var _a2;
          return formik.setFieldValue("country", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : "");
        },
        renderInput: (params) => {
          var _a2, _b2;
          return /* @__PURE__ */ jsx(
            StyledAutoTextfield,
            __spreadProps(__spreadValues({}, params), {
              variant: "standard",
              size: "small",
              value: (_a2 = countryOptions.find(({ value }) => value === formik.values.country)) != null ? _a2 : null,
              InputLabelProps: { shrink: true },
              label: polyglot.t("AddressForm.country"),
              name: "country",
              required: (_b2 = defaultFieldsMap["address.country"]) == null ? void 0 : _b2.isRequired,
              error: formik.touched.country && !!formik.errors.country,
              helperText: formik.touched.country && formik.errors.country
            })
          );
        },
        PaperComponent: ({ children }) => /* @__PURE__ */ jsx(Paper, { sx: StyledAuto, children }),
        popupIcon: /* @__PURE__ */ jsx(ArrowDownACIcon, {}),
        clearIcon: /* @__PURE__ */ jsx(ClearIcon, {})
      }
    ) }),
    /* @__PURE__ */ jsx(
      UserCustomField,
      {
        values: (_k = formik.values.customUpdates) != null ? _k : [],
        formFields: customFields != null ? customFields : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        sizeVariant: "large",
        colorVariant: "primary",
        loading: saving,
        fullWidth: true
      }
    )
  ] }) }) });
};
