"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { getReportShareURL } from "@v2/feature/reports/util/report.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { StyledRadio } from "@v2/styles/radio.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@/v2/styles/menu.styles";
export const ReportCopyShareLinkDrawer = ({ isOpen, setIsOpen, selectedColumns, selectedFilters }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    ReportCopyShareLinkDrawerContent,
    {
      isOpen,
      setIsOpen,
      selectedColumns,
      selectedFilters
    }
  ) });
};
const ReportCopyShareLinkDrawerContent = ({ isOpen, setIsOpen, selectedColumns, selectedFilters }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      scope: "team"
    },
    validationSchema: yup.object({
      scope: yup.string().oneOf(["team", "company"]).required("Required field")
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        const { success, url, errorMessage } = getReportShareURL(selectedColumns, selectedFilters, values.scope);
        if (!success) {
          showMessage(
            polyglot.t("ReportCopyShareLinkDrawer.errorMessages.generateUrl", {
              errorMessage
            }),
            "error"
          );
          return;
        }
        yield navigator.clipboard.writeText(url);
        showMessage(polyglot.t("ReportCopyShareLinkDrawer.successMessages.copyLink"), "info");
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("ReportCopyShareLinkDrawer.errorMessages.copyLink", {
            errorMessage: error.message
          }),
          "error"
        );
      }
    })
  });
  useEffect(() => {
    if (!isOpen) {
      const timer = setTimeout(() => {
        formik.resetForm();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isOpen, formik]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ReportCopyShareLinkDrawer.title") }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ReportCopyShareLinkDrawer.description") }),
    /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsxs(RadioGroup, { name: "scope", value: formik.values.scope, onChange: formik.handleChange, children: [
      /* @__PURE__ */ jsx(
        FormControlLabel,
        {
          labelPlacement: "end",
          value: "team",
          control: /* @__PURE__ */ jsx(StyledRadio, {}),
          label: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: formik.values.scope === "team" ? "title4" : "caption", children: polyglot.t("ReportCopyShareLinkDrawer.teamLabel") }) })
        }
      ),
      /* @__PURE__ */ jsx(
        FormControlLabel,
        {
          labelPlacement: "end",
          value: "company",
          control: /* @__PURE__ */ jsx(StyledRadio, {}),
          label: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: formik.values.scope === "company" ? "title4" : "caption", children: polyglot.t("ReportCopyShareLinkDrawer.companyLabel") }) })
        }
      )
    ] }) }),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        "aria-label": polyglot.t("ReportCopyShareLinkDrawer.copyLink"),
        colorVariant: "secondary",
        sizeVariant: "medium",
        type: "submit",
        startIcon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
        children: polyglot.t("ReportCopyShareLinkDrawer.copyLink")
      }
    )
  ] }) });
};
