"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Autocomplete, ListItemIcon, ListItemText, Paper } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { ReactComponent as ArrowDownACIcon } from "@/images/side-bar-icons/ArrowDownAC.svg";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { ClearIcon } from "@/v2/components/theme-components/clear-icon.component";
import { StyledAuto, StyledAutoTextfield } from "@/v2/styles/autocomplete.styles";
import { StyledMenuItem } from "@/v2/styles/menu.styles";
const iconSize = { width: "12px", height: "12px" };
export function MultipleSelectCheckbox(props) {
  const {
    id,
    limitTags,
    options,
    value,
    onChange,
    isOptionEqualToValue,
    getOptionLabel,
    label,
    placeholder,
    disabled,
    required,
    disableClearable = false,
    helperText,
    error = false,
    hidePlaceholder = false
  } = props;
  const [selected, setSelected] = useState([]);
  return /* @__PURE__ */ jsx(
    Autocomplete,
    {
      id,
      multiple: true,
      limitTags,
      value,
      fullWidth: true,
      options,
      disableCloseOnSelect: true,
      disableClearable,
      onChange,
      isOptionEqualToValue,
      getOptionLabel,
      renderInput: (params) => {
        return /* @__PURE__ */ jsx(
          StyledAutoTextfield,
          __spreadProps(__spreadValues({}, params), {
            label,
            fullWidth: true,
            error,
            helperText,
            variant: "standard",
            size: "small",
            required: required && !value.length,
            InputLabelProps: { shrink: true },
            placeholder: !params.InputProps.startAdornment && !hidePlaceholder ? placeholder || (typeof label === "string" ? label : void 0) : void 0
          })
        );
      },
      renderOption: (props2, option) => {
        const idx = options.indexOf(option);
        return /* @__PURE__ */ jsxs(
          StyledMenuItem,
          __spreadProps(__spreadValues({
            disabled: disabled || option.disabled,
            value: idx
          }, props2), {
            children: [
              /* @__PURE__ */ jsx(ListItemText, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "DarkGrey", children: option.label }) }),
              /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  label: void 0,
                  name: `${id}-entry-${idx}`,
                  disabled,
                  checked: !!value.find((eachOption) => eachOption.value === option.value),
                  value: `${id}-entry-${idx}`,
                  onChange: () => {
                    let finalArray = [];
                    if (selected.find((eachOption) => eachOption.value === option.value)) {
                      finalArray = selected.filter((sm) => sm.value !== option.value);
                    } else finalArray = [...selected, option];
                    setSelected(finalArray);
                  }
                }
              ) })
            ]
          }),
          `${id}-menu-${option.value}`
        );
      },
      renderTags: (options2) => {
        return options2.map((o) => o.label).join(", ");
      },
      PaperComponent: ({ children }) => /* @__PURE__ */ jsx(Paper, { sx: StyledAuto, children }),
      popupIcon: /* @__PURE__ */ jsx(ArrowDownACIcon, __spreadValues({}, iconSize)),
      clearIcon: /* @__PURE__ */ jsx(ClearIcon, __spreadValues({}, iconSize)),
      disabled
    }
  );
}
