"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { keyBy } from "lodash";
import * as Yup from "yup";
import { EquityAPI } from "@/api-client/equity.api";
import useMessage from "@/hooks/notification.hook";
import { equityTypeOptions } from "@/lib/employment";
import { nestErrorMessage } from "@/lib/errors";
import { CurrencySelect } from "@/v2/components/currency-select.component";
import { MoneyTextfieldComponent } from "@/v2/components/forms/money-textfield.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TypeableDateComponent } from "@/v2/components/forms/typeable-date.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { CustomProfileFormType } from "@/v2/feature/custom-fields/custom-profile-fields.dto";
import { useAdminOnboardingFieldsContext } from "@/v2/feature/onboarding/context/admin-onboarding-fields.context";
import { dateFieldTest } from "@/v2/infrastructure/date/date-format.util";
import { spacing } from "@/v2/styles/spacing.styles";
const NewEmployeeEquitySchema = (defaultFieldsMap, polyglot) => {
  return Yup.object().shape({
    type: defaultFieldsMap["equity.type"] && defaultFieldsMap["equity.type"].isRequired ? Yup.string().trim().required(polyglot.t("NewEmployeeEquitySection.errorMessages.equityTypeRequired")) : Yup.string().trim().nullable().notRequired(),
    grantDate: Yup.string().when("type", {
      is: (type) => type !== "none" && defaultFieldsMap["equity.grantDate"] && defaultFieldsMap["equity.grantDate"].isRequired,
      then: (schema) => schema.trim().test(dateFieldTest).required(polyglot.t("NewEmployeeEquitySection.errorMessages.grantDateRequired")),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    amount: Yup.number().when("type", {
      is: (type) => type !== "none" && defaultFieldsMap["equity.amount"] && defaultFieldsMap["equity.amount"].isRequired,
      then: (schema) => schema.required(polyglot.t("NewEmployeeEquitySection.errorMessages.amountRequired")),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    vestingStart: Yup.string().when("type", {
      is: (type) => type !== "none" && defaultFieldsMap["equity.vestingStart"] && defaultFieldsMap["equity.vestingStart"].isRequired,
      then: (schema) => schema.trim().test(dateFieldTest).required(polyglot.t("NewEmployeeEquitySection.errorMessages.vestingDateRequired")),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    vestingPeriod: Yup.number().when("type", {
      is: (type) => type !== "none" && defaultFieldsMap["equity.vestingPeriod"] && defaultFieldsMap["equity.vestingPeriod"].isRequired,
      then: (schema) => schema.required(polyglot.t("NewEmployeeEquitySection.errorMessages.vestingPeriodRequired")),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    vestingCliff: Yup.number().when("type", {
      is: (type) => type !== "none" && defaultFieldsMap["equity.vestingCliff"] && defaultFieldsMap["equity.vestingCliff"].isRequired,
      then: (schema) => schema.required(polyglot.t("NewEmployeeEquitySection.errorMessages.vestingCliffRequired")),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    unitPrice: Yup.number().when("type", {
      is: (type) => type !== "none" && defaultFieldsMap["equity.unitPrice"] && defaultFieldsMap["equity.unitPrice"].isRequired,
      then: (schema) => schema.required(polyglot.t("NewEmployeeEquitySection.errorMessages.unitPriceRequired")),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    currency: Yup.string().when("type", {
      is: (type) => type !== "none" && defaultFieldsMap["equity.currency"] && defaultFieldsMap["equity.currency"].isRequired,
      then: (schema) => schema.required(polyglot.t("NewEmployeeEquitySection.errorMessages.currencyRequired")),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
    reason: Yup.string().when("type", {
      is: (type) => type !== "none" && defaultFieldsMap["equity.reason"] && defaultFieldsMap["equity.reason"].isRequired,
      then: (schema) => schema.required(polyglot.t("validation.requiredField")),
      otherwise: (schema) => schema.nullable().notRequired()
    })
  });
};
export const NewEmployeeEquitySection = ({
  companyId,
  equity,
  onNext,
  sx,
  newUserId,
  currentUserId
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p;
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedDefaultFieldsByFormName } = useAdminOnboardingFieldsContext();
  const defaultFields = getCachedDefaultFieldsByFormName(CustomProfileFormType.Equity);
  const defaultFieldsMap = useMemo(() => {
    return keyBy(defaultFields, "fieldStub");
  }, [defaultFields]);
  const formik = useFormik({
    initialValues: {
      type: (equity == null ? void 0 : equity.type) || "none",
      grantDate: equity == null ? void 0 : equity.grantDate,
      amount: equity == null ? void 0 : equity.amount,
      vestingStart: equity == null ? void 0 : equity.vestingStart,
      vestingPeriod: equity == null ? void 0 : equity.vestingPeriod,
      vestingCliff: equity == null ? void 0 : equity.vestingCliff,
      unitPrice: equity == null ? void 0 : equity.unitPrice,
      currency: equity == null ? void 0 : equity.currency,
      reason: equity == null ? void 0 : equity.reason
    },
    validateOnMount: true,
    validationSchema: NewEmployeeEquitySchema(defaultFieldsMap, polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        if (values.type === "none") {
          if (equity) {
            yield EquityAPI.deleteById(newUserId, equity.id);
          }
        } else if (equity) {
          yield EquityAPI.updateById(__spreadValues(__spreadValues({}, equity), values));
        } else {
          yield EquityAPI.create(__spreadProps(__spreadValues({}, values), {
            userId: newUserId,
            companyId,
            createdBy: currentUserId,
            updatedBy: currentUserId,
            reason: ""
          }));
        }
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t("NewEmployeeEquitySection.errorMessages.save", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("NewEmployeeEquitySection.equity") }),
    defaultFieldsMap["equity.type"] && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: polyglot.t("NewEmployeeEquitySection.type"),
        options: [...equityTypeOptions(polyglot), { label: "None", value: "none" }],
        value: formik.values.type,
        onChange: formik.handleChange,
        error: hasSubmitted && !!formik.errors.type,
        helperText: hasSubmitted && formik.errors.type,
        required: (_a = defaultFieldsMap["equity.type"]) == null ? void 0 : _a.isRequired
      }
    ),
    formik.values.type !== "none" && /* @__PURE__ */ jsxs(Fragment, { children: [
      defaultFieldsMap["equity.grantDate"] && /* @__PURE__ */ jsx(
        TypeableDateComponent,
        {
          name: "grantDate",
          label: polyglot.t("NewEmployeeEquitySection.grantDate"),
          value: formik.values.grantDate,
          onChange: (value) => formik.setFieldValue("grantDate", value),
          error: hasSubmitted && !!formik.errors.grantDate,
          helperText: hasSubmitted && formik.errors.grantDate,
          disabled: saving,
          required: (_b = defaultFieldsMap["equity.grantDate"]) == null ? void 0 : _b.isRequired
        }
      ),
      defaultFieldsMap["equity.amount"] && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "amount",
          label: polyglot.t("NewEmployeeEquitySection.amount"),
          type: "tel",
          value: (_d = (_c = formik.values.amount) == null ? void 0 : _c.toString()) != null ? _d : "",
          disabled: saving,
          onChange: (e) => {
            var _a2, _b2;
            const newValue = Number((_b2 = (_a2 = e.target.value.match(/^\d{1,9}/)) == null ? void 0 : _a2[0]) != null ? _b2 : "nan");
            formik.setFieldValue("amount", Number.isInteger(newValue) ? newValue : void 0);
          },
          clearText: () => formik.setFieldValue("amount", void 0),
          helperText: hasSubmitted && formik.errors.amount,
          error: hasSubmitted && !!formik.errors.amount,
          required: (_e = defaultFieldsMap["equity.amount"]) == null ? void 0 : _e.isRequired
        }
      ),
      defaultFieldsMap["equity.vestingStart"] && /* @__PURE__ */ jsx(
        TypeableDateComponent,
        {
          name: "vestingStart",
          label: polyglot.t("NewEmployeeEquitySection.vestingStart"),
          value: formik.values.vestingStart,
          onChange: (value) => formik.setFieldValue("vestingStart", value),
          error: hasSubmitted && !!formik.errors.vestingStart,
          helperText: hasSubmitted && formik.errors.vestingStart,
          disabled: saving,
          required: (_f = defaultFieldsMap["equity.vestingStart"]) == null ? void 0 : _f.isRequired
        }
      ),
      defaultFieldsMap["equity.vestingPeriod"] && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "vestingPeriod",
          label: polyglot.t("NewEmployeeEquitySection.vestingPeriod"),
          type: "tel",
          value: (_h = (_g = formik.values.vestingPeriod) == null ? void 0 : _g.toString()) != null ? _h : "",
          disabled: saving,
          onChange: (e) => {
            var _a2, _b2;
            const newValue = Number((_b2 = (_a2 = e.target.value.match(/^\d{1,3}/)) == null ? void 0 : _a2[0]) != null ? _b2 : "nan");
            formik.setFieldValue("vestingPeriod", Number.isInteger(newValue) ? newValue : void 0);
          },
          clearText: () => formik.setFieldValue("vestingPeriod", void 0),
          helperText: hasSubmitted && formik.errors.vestingPeriod,
          error: hasSubmitted && !!formik.errors.vestingPeriod,
          required: (_i = defaultFieldsMap["equity.vestingPeriod"]) == null ? void 0 : _i.isRequired
        }
      ),
      defaultFieldsMap["equity.vestingCliff"] && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "vestingCliff",
          label: polyglot.t("NewEmployeeEquitySection.vestingCliff"),
          type: "tel",
          value: (_k = (_j = formik.values.vestingCliff) == null ? void 0 : _j.toString()) != null ? _k : "",
          disabled: saving,
          onChange: (e) => {
            var _a2, _b2;
            const newValue = Number((_b2 = (_a2 = e.target.value.match(/^\d{1,3}/)) == null ? void 0 : _a2[0]) != null ? _b2 : "nan");
            formik.setFieldValue("vestingCliff", Number.isInteger(newValue) ? newValue : void 0);
          },
          clearText: () => formik.setFieldValue("vestingCliff", void 0),
          helperText: hasSubmitted && formik.errors.vestingCliff,
          error: hasSubmitted && !!formik.errors.vestingCliff,
          required: (_l = defaultFieldsMap["equity.vestingCliff"]) == null ? void 0 : _l.isRequired
        }
      ),
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g20 }, children: [
        defaultFieldsMap["equity.unitPrice"] && /* @__PURE__ */ jsx(
          MoneyTextfieldComponent,
          {
            name: "unitPrice",
            label: polyglot.t("NewEmployeeEquitySection.unitPrice"),
            value: formik.values.unitPrice,
            onChange: (newValue) => {
              formik.setFieldValue("unitPrice", newValue);
            },
            helperText: hasSubmitted && formik.errors.unitPrice,
            error: hasSubmitted && !!formik.errors.unitPrice,
            required: (_m = defaultFieldsMap["equity.unitPrice"]) == null ? void 0 : _m.isRequired
          }
        ),
        defaultFieldsMap["equity.currency"] && /* @__PURE__ */ jsx(
          CurrencySelect,
          {
            name: "currency",
            onChange: (currency) => {
              formik.setFieldValue("currency", currency);
            },
            value: formik.values.currency,
            helperText: hasSubmitted && formik.errors.currency,
            error: hasSubmitted && !!formik.errors.currency,
            sx: { flexBasis: "40%" },
            required: (_n = defaultFieldsMap["equity.currency"]) == null ? void 0 : _n.isRequired
          }
        )
      ] }),
      defaultFieldsMap["equity.reason"] && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "reason",
          label: polyglot.t("EquityForm.reason"),
          value: formik.values.reason,
          type: "string",
          onChange: formik.handleChange,
          error: formik.touched.reason && !!formik.errors.reason,
          helperText: (_o = formik.touched.reason && formik.errors.reason) != null ? _o : " ",
          clearText: () => formik.setFieldValue("reason", ""),
          required: (_p = defaultFieldsMap["equity.reason"]) == null ? void 0 : _p.isRequired
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        loading: saving,
        colorVariant: "primary",
        sizeVariant: "large",
        fullWidth: true,
        disabled: !formik.isValid
      }
    )
  ] }) }) });
};
