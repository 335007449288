"use strict";
import { capitalize } from "lodash";
export var GoalOwnerType = /* @__PURE__ */ ((GoalOwnerType2) => {
  GoalOwnerType2["Company"] = "Company";
  GoalOwnerType2["Department"] = "Department";
  GoalOwnerType2["Individual"] = "Individual";
  return GoalOwnerType2;
})(GoalOwnerType || {});
export var GoalStatus = /* @__PURE__ */ ((GoalStatus2) => {
  GoalStatus2["Planned"] = "Planned";
  GoalStatus2["Active"] = "Active";
  GoalStatus2["Achieved"] = "Achieved";
  return GoalStatus2;
})(GoalStatus || {});
export const goalStatusOptions = [
  {
    value: "Planned" /* Planned */,
    label: capitalize("Planned" /* Planned */)
  },
  {
    value: "Active" /* Active */,
    label: capitalize("Active" /* Active */)
  },
  {
    value: "Achieved" /* Achieved */,
    label: capitalize("Achieved" /* Achieved */)
  }
];
export const goalOwnerTypeOptions = [
  {
    value: "Individual" /* Individual */,
    label: capitalize("Individual" /* Individual */)
  },
  {
    value: "Department" /* Department */,
    label: capitalize("Department" /* Department */)
  },
  {
    value: "Company" /* Company */,
    label: capitalize("Company" /* Company */)
  }
];
