"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import Check from "@mui/icons-material/Check";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ThemeStepConnector, ThemeStepIconRoot, ThemeStepper } from "@/v2/styles/stepper.styles";
function ThemeStepIcon(props) {
  const { active, completed, className } = props;
  return /* @__PURE__ */ jsx(ThemeStepIconRoot, { ownerState: { active }, className, children: completed ? /* @__PURE__ */ jsx(Check, { className: "ThemeStepIcon-completedIcon" }) : /* @__PURE__ */ jsx("div", { className: "ThemeStepIcon-circle" }) });
}
export const StepperComponent = ({
  activeStep,
  onlyShowActiveLabel,
  hideLabel,
  hideConnector,
  steps,
  sx,
  labelMapper
}) => {
  const activeStepIndex = typeof activeStep === "string" ? steps.indexOf(activeStep) : activeStep;
  return hideConnector ? /* @__PURE__ */ jsx(Fragment, {}) : /* @__PURE__ */ jsx(ThemeStepper, { sx, activeStep: activeStepIndex, connector: /* @__PURE__ */ jsx(ThemeStepConnector, {}), children: steps.map((label, idx) => /* @__PURE__ */ jsx(Step, { children: /* @__PURE__ */ jsx(StepLabel, { StepIconComponent: ThemeStepIcon, children: hideLabel ? "" : !onlyShowActiveLabel || idx === activeStepIndex ? labelMapper ? labelMapper(label) : label : "" }) }, label)) });
};
