"use strict";
export const INTERCOM_APP_ID = "b14crg9h";
export const INTERCOM_API_BASE = "https://api-iam.intercom.io";
export const getIntercomBootProps = (user, userHash, companyStats) => {
  var _a, _b;
  try {
    return {
      // userId: user.userId.toString(), // Don't enable userId here. We generate the HMAC key using the email., If userId is available, it takes priority, but the ids can be repeated in testzelt, go zelt, localhost, etc.
      email: user.emailAddress,
      company: {
        companyId: user.company.companyId.toString(),
        name: user.company.name,
        monthlySpend: companyStats.data2,
        // monthlySpend
        size: companyStats.data1
        // totalUsers
      },
      name: `${user.firstName} ${user.lastName}`,
      createdAt: Math.floor(new Date(user.createdAt).getTime() / 1e3),
      userHash,
      customAttributes: {
        zelt_user_id: user.userId,
        // ACCOUNT TYPES
        account_type_admin: Boolean((_a = user.accountType) == null ? void 0 : _a.Admin),
        account_type_manager: Boolean((_b = user.accountType) == null ? void 0 : _b.Manager)
      }
    };
  } catch (error) {
    console.error("Could not retrieve Intercom settings", error.message, error);
    return null;
  }
};
