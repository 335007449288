"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SettingsRouter } from "@v2/feature/settings/settings.router";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { CompanyAPI } from "@/api-client/company.api";
import FailureCloseTab from "@/component/FailureCloseTab";
import SuccessCloseTab from "@/component/SuccessCloseTab";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import {
  DASHBOARD_ROUTE,
  FAILURE_ROUTE,
  GROWTH_ROUTE,
  HIDDEN_ROUTE,
  MONEY_ROUTE,
  PARTNER_API_AUTHORISE_ROUTE,
  PEOPLE_ROUTE,
  REPORTS_ROUTE,
  SETTINGS_ROUTE,
  SUCCESS_ROUTE,
  SUPER_ADMIN_ROUTE,
  TECH_ROUTE,
  TIME_ROUTE
} from "@/lib/routes";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import { HiddenContentRouter } from "@/v2/feature/app-layout/features/hidden-content/hidden-content.router";
import { oldToNewRedirects } from "@/v2/feature/app-layout/features/main-content/main.util";
import { GrowthRouter } from "@/v2/feature/app-layout/features/v2/routers/growth.router";
import { ItRouter } from "@/v2/feature/app-layout/features/v2/routers/it.router";
import { MoneyRouter } from "@/v2/feature/app-layout/features/v2/routers/money.router";
import { PeopleRouter } from "@/v2/feature/app-layout/features/v2/routers/people.router";
import { ReportRouter } from "@/v2/feature/app-layout/features/v2/routers/report.router";
import { TimeRouter } from "@/v2/feature/app-layout/features/v2/routers/time.router";
import { DashboardRouter } from "@/v2/feature/dashboard/dashboard.router";
import { PublicAPIRouter } from "@/v2/feature/public-api/public-api.router";
import { SuperAdminRouter } from "@/v2/feature/super-admin/super-admin.router";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const MainRouter = ({ isSuperAdmin, userAPIResponse }) => {
  const routerLocation = useLocation();
  const routerHistory = useHistory();
  const [globalState, dispatch] = useContext(GlobalContext);
  const { user } = globalState;
  const { emailAddress, firstName, lastName } = user != null ? user : {};
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showMessage] = useMessage();
  const { update } = useIntercom();
  useEffect(() => {
    if (routerLocation.pathname) update();
  }, [update, routerLocation.pathname]);
  const { useInitLanguage } = usePolyglot();
  useInitLanguage();
  const hubspotData = useMemo(() => ({ email: emailAddress, firstName, lastName }), [
    emailAddress,
    firstName,
    lastName
  ]);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(user);
  const getChatSupportFlag = useCallback(() => __async(void 0, null, function* () {
    var _a, _b, _c, _d, _e;
    const hasPriority = yield CompanyAPI.priorityChatSupport();
    if (hasPriority) {
      try {
        const token = yield AppIntegrationAPI.generateHubSpotIdentificationToken(emailAddress, firstName, lastName);
        if (token) {
          console.info("Got hs token");
          window.hsConversationsSettings = {
            identificationEmail: hubspotData.email,
            identificationToken: token != null ? token : ""
          };
          window.hsConversationsOnReady = [
            () => {
              setHasLoaded(true);
            }
          ];
          let script = document.createElement("script");
          script.src = `//js-na1.hs-scripts.com/20337724.js`;
          script.async = true;
          console.info("Script loaded");
          document.body.appendChild(script);
          (_a = window.HubSpotConversations) == null ? void 0 : _a.clear({ resetWidget: true });
          (_c = (_b = window.HubSpotConversations) == null ? void 0 : _b.widget) == null ? void 0 : _c.open();
          (_e = (_d = window.HubSpotConversations) == null ? void 0 : _d.widget) == null ? void 0 : _e.load();
          console.info("Widget loaded", hasLoaded);
          return () => {
            var _a2;
            (_a2 = document == null ? void 0 : document.body) == null ? void 0 : _a2.removeChild(script);
            window.hsConversationsOnReady = [];
          };
        } else {
          console.info("2 - No visitor token or email available, so not initializing chat!");
        }
      } catch (error) {
        showMessage(`Encountered an error: ${error}`, "error");
      }
    } else {
      const timer = setTimeout(() => {
        var _a2, _b2;
        if ((_b2 = (_a2 = window.HubSpotConversations) == null ? void 0 : _a2.widget) == null ? void 0 : _b2.remove) {
          window.HubSpotConversations.widget.remove();
        }
      }, 2e3);
      return () => clearTimeout(timer);
    }
  }), [emailAddress, firstName, hasLoaded, hubspotData.email, lastName, showMessage]);
  useEffect(() => {
    getChatSupportFlag();
  }, [getChatSupportFlag]);
  useEffect(() => {
    (function() {
      return __async(this, null, function* () {
        var _a, _b, _c, _d, _e;
        const response = userAPIResponse;
        const showHiddenFeatures = (_a = response == null ? void 0 : response.showHiddenFeatures) != null ? _a : null;
        const paymentFailed = (_b = response == null ? void 0 : response.hasUnpaidInvoices) != null ? _b : false;
        const isTestModeEnabled = (_c = response == null ? void 0 : response.isTestModeEnabled) != null ? _c : false;
        const trialModeDetails = (_d = response == null ? void 0 : response.trialMode) != null ? _d : { enabled: false };
        const publicURLDetails = (_e = response == null ? void 0 : response.publicURL) != null ? _e : null;
        dispatch({
          type: GlobalStateActions.UPDATE_HIDDEN_FEATURE,
          payload: showHiddenFeatures
        });
        dispatch({
          type: GlobalStateActions.PAYMENT_FAILED,
          payload: paymentFailed
        });
        dispatch({
          type: GlobalStateActions.UPDATE_TEST_MODE,
          payload: isTestModeEnabled
        });
        dispatch({
          type: GlobalStateActions.LOG_OUT,
          payload: false
        });
        dispatch({
          type: GlobalStateActions.UPDATE_TRIAL_MODE,
          payload: trialModeDetails
        });
        dispatch({
          type: GlobalStateActions.UPDATE_PUBLIC_URL,
          payload: publicURLDetails
        });
      });
    })();
  }, [dispatch, userAPIResponse]);
  useEffect(() => {
    const { pathname } = routerLocation;
    oldToNewRedirects(pathname, routerHistory);
  }, [routerHistory, routerLocation]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { exact: true, path: SUCCESS_ROUTE, component: SuccessCloseTab }),
    /* @__PURE__ */ jsx(Route, { exact: true, path: FAILURE_ROUTE, component: FailureCloseTab }),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["user:read"],
        context: scopesContext,
        path: SETTINGS_ROUTE,
        component: SettingsRouter
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["user:read"], context: scopesContext, path: PEOPLE_ROUTE, component: PeopleRouter }),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["absence", "attendance"],
        context: scopesContext,
        path: TIME_ROUTE,
        component: TimeRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["payroll", "payments", "expenses", "pension", "insurance", "invoices", "payroll.selectedEntity"],
        context: scopesContext,
        path: MONEY_ROUTE,
        component: MoneyRouter
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["apps", "devices"], context: scopesContext, path: TECH_ROUTE, component: ItRouter }),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["reviews", "surveys", "goals"],
        context: scopesContext,
        path: GROWTH_ROUTE,
        component: GrowthRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["reports:manager"],
        context: scopesContext,
        path: REPORTS_ROUTE,
        component: ReportRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["company.settings:all"],
        context: scopesContext,
        path: PARTNER_API_AUTHORISE_ROUTE,
        component: PublicAPIRouter
      }
    ),
    isSuperAdmin && /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_ROUTE, component: SuperAdminRouter }),
    /* @__PURE__ */ jsx(Route, { path: HIDDEN_ROUTE, component: HiddenContentRouter }),
    /* @__PURE__ */ jsx(Route, { path: DASHBOARD_ROUTE, component: DashboardRouter }),
    /* @__PURE__ */ jsx(Route, { path: "/", children: /* @__PURE__ */ jsx(Redirect, { to: "/" }) })
  ] });
};
