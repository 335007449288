"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import {
  HelperRouter,
  SUPER_ADMIN_HELPER_ROUTE
} from "@v2/feature/super-admin/features/helper-dashboard/helper.router";
import { SuperAdminAppsPage } from "@v2/feature/super-admin/features/super-admin-apps/super-admin-apps.page";
import { SuperAdminDevicesRouter } from "@v2/feature/super-admin/features/super-admin-devices/super-admin-devices.router";
import { SuperAdminInsuranceRouter } from "@v2/feature/super-admin/features/super-admin-insurance/super-admin-insurance.router";
import { SuperAdminMdmMigratorPage } from "@v2/feature/super-admin/features/super-admin-mdm-migrator/super-mdm-miration.page";
import { SuperAdminRefinancingRouter } from "@v2/feature/super-admin/features/super-admin-refinancing/super-admin-refinancing.router";
import { Redirect, Route, Switch } from "react-router-dom";
import { PublishRequestsBackofficePage } from "../security/security-settings/features/developer-hub/components/publish-requests-backoffice.page";
import { CompanyAPI } from "@/api-client/index.api";
import AlertError from "@/component/widgets/AlertError";
import { nestErrorMessage } from "@/lib/errors";
import {
  SUPER_ADMIN_APPS_ROUTE,
  SUPER_ADMIN_BILLING_ROUTE,
  SUPER_ADMIN_BILLING_V2_ROUTE,
  SUPER_ADMIN_COMPANIES,
  SUPER_ADMIN_DEVICES_ROUTE,
  SUPER_ADMIN_ID_CHECKS_ROUTE,
  SUPER_ADMIN_INSURANCE_ROUTE,
  SUPER_ADMIN_LINKEDIN_USER_DATA_FETCH_ROUTE,
  SUPER_ADMIN_MDM_MIGRATION_ROUTE,
  SUPER_ADMIN_MONITORING_ROUTE,
  SUPER_ADMIN_PARTNER_APP_PUBLISH_REQUEST_API_ROUTE,
  SUPER_ADMIN_REFINANCING_ROUTE,
  SUPER_ADMIN_ROUTE,
  SUPER_ADMIN_SALARY_ROUTE,
  SUPER_ADMIN_STATISTICS_ROUTE,
  SUPER_ADMIN_USERS
} from "@/lib/routes";
import { IndividualCheckBackofficePage } from "@/v2/feature/id-check/individual-check-backoffice.page";
import { MonitoringPage } from "@/v2/feature/monitoring/monitoring.page";
import { SuperAdminBillingRouter } from "@/v2/feature/super-admin/features/super-admin-billing/super-admin-billing.router";
import { SuperAdminBillingV2Router } from "@/v2/feature/super-admin/features/super-admin-billing-v2/super-admin-billing-v2-new.router";
import { SuperAdminCompaniesPage } from "@/v2/feature/super-admin/features/super-admin-companies/super-admin-companies.page";
import { SuperAdminLinkedinImportPage } from "@/v2/feature/super-admin/features/super-admin-linkedin-import/super-admin-linkedin-import.page";
import { SuperAdminOverviewPage } from "@/v2/feature/super-admin/features/super-admin-overview/super-admin-overview.page";
import { SuperAdminSalaryRouter } from "@/v2/feature/super-admin/features/super-admin-salary/super-admin-salary.router";
import { SuperAdminUsersPage } from "@/v2/feature/super-admin/features/super-admin-users/super-admin-users.page";
import { UserAPI } from "@/v2/feature/user/user.api";
export const SuperAdminRouter = () => {
  const [users, setUsers] = useState([]);
  const [activeCompanies, setActiveCompanies] = useState([]);
  const [error, setError] = useState("");
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      const [{ users: users2 }, { companies }] = yield Promise.all([
        UserAPI.getAllUsersAsSuperAdmin({ shouldLimitResult: "false" }),
        CompanyAPI.getAllCompaniesAsSuperAdmin({ shouldLimitResult: "false" })
      ]);
      const activeCompanies2 = companies.filter((company) => company.isActive);
      setUsers(users2);
      setActiveCompanies(activeCompanies2);
    } catch (error2) {
      setError(nestErrorMessage(error2));
    }
  }), []);
  useEffect(() => {
    refresh();
  }, [refresh]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    error && /* @__PURE__ */ jsx(AlertError, { title: "There was an error while retrieving the users list.", error }),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_DEVICES_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminDevicesRouter, { users, companies: activeCompanies }) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_USERS, children: /* @__PURE__ */ jsx(SuperAdminUsersPage, {}) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_COMPANIES, children: /* @__PURE__ */ jsx(SuperAdminCompaniesPage, {}) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_STATISTICS_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminOverviewPage, {}) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_APPS_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminAppsPage, {}) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_BILLING_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminBillingRouter, {}) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_BILLING_V2_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminBillingV2Router, {}) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_SALARY_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminSalaryRouter, { companies: activeCompanies }) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_LINKEDIN_USER_DATA_FETCH_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminLinkedinImportPage, {}) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_MDM_MIGRATION_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminMdmMigratorPage, {}) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_INSURANCE_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminInsuranceRouter, { companies: activeCompanies, users }) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_MONITORING_ROUTE, children: /* @__PURE__ */ jsx(MonitoringPage, {}) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_PARTNER_APP_PUBLISH_REQUEST_API_ROUTE, children: /* @__PURE__ */ jsx(PublishRequestsBackofficePage, {}) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_ID_CHECKS_ROUTE, children: /* @__PURE__ */ jsx(IndividualCheckBackofficePage, {}) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_REFINANCING_ROUTE, children: /* @__PURE__ */ jsx(SuperAdminRefinancingRouter, { companies: activeCompanies }) }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_ROUTE, component: HelperRouter }),
      /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_ROUTE, children: /* @__PURE__ */ jsx(Redirect, { to: SUPER_ADMIN_USERS }) })
    ] })
  ] });
};
