"use strict";
function formatToOptionalDecimal(value) {
  return Number.isInteger(value) ? Number(value.toFixed(0)) : Number(value.toFixed(1));
}
export function formatDays(days, polyglot) {
  if (!days) return "";
  if (days < 30) {
    return polyglot.t("General.noOfDays", { smart_count: days });
  } else if (days < 365) {
    const months = formatToOptionalDecimal(days / 30);
    return polyglot.t("General.noOfMonths", { smart_count: months });
  } else {
    const years = formatToOptionalDecimal(days / 365);
    return polyglot.t("General.noOfYears", { smart_count: years });
  }
}
