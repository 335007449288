"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Badge, Box, Fade } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { StyledTooltip } from "@v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { decode } from "blurhash";
import { CompanyAPI } from "@/api-client/company.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { UNKNOWN_USER_ID } from "@/v2/components/table/user-cell.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { neutralColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const sizes = {
  xxlarge: { width: 135, height: 135 },
  x100: { width: 100, height: 100 },
  xlarge: { width: 80, height: 80 },
  large: { width: 66, height: 66 },
  medium: { width: 40, height: 40 },
  small: { width: 30, height: 30 },
  small28: { width: 28, height: 28 },
  xsmall: { width: 24, height: 24 },
  xxsmall: { width: 20, height: 20 },
  xxxsmall: { width: 14, height: 14 }
};
const fontSizeByAvatarSize = {
  xxlarge: themeFonts.title2,
  x100: themeFonts.title2,
  xlarge: themeFonts.title2,
  large: themeFonts.title2,
  medium: themeFonts.captionSmall,
  small: themeFonts.captionSmall,
  small28: themeFonts.captionSmall,
  xsmall: themeFonts.bodyTiny,
  xxsmall: themeFonts.bodyTiny,
  xxxsmall: themeFonts.bodyTiny
};
function stringToColor(avatarName) {
  if (!avatarName) return "white";
  let hash = 0;
  let i;
  for (i = 0; i < avatarName.length; i += 1) {
    hash = avatarName.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = hash >> i * 8 & 255;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return `${color}5c`;
}
const getInitials = (name) => {
  if (!name) return "";
  const nameParts = name.split(" ");
  if (nameParts.length === 1) {
    return (name.length >= 2 ? name.substring(0, 2) : name[0]).toUpperCase();
  }
  return `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase();
};
function AvatarWithInitials({
  user,
  sx = {},
  onClick,
  size = "small",
  showBadge,
  badge
}) {
  const initials = (user == null ? void 0 : user.userId) === UNKNOWN_USER_ID ? "?" : getInitials(user == null ? void 0 : user.displayName);
  const styles = __spreadProps(__spreadValues(__spreadValues({
    "&:hover": onClick && {
      cursor: "pointer",
      filter: "brightness(70%)"
    }
  }, sx), sizes[size]), {
    bgcolor: stringToColor(user == null ? void 0 : user.displayName),
    fontSize: fontSizeByAvatarSize[size],
    color: "white",
    fontWeight: "normal",
    textDecoration: "none"
  });
  return /* @__PURE__ */ jsx(
    Badge,
    {
      invisible: !showBadge,
      overlap: "circular",
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      badgeContent: badge,
      children: /* @__PURE__ */ jsx(Avatar, { sx: styles, alt: user == null ? void 0 : user.displayName, onClick, children: initials })
    }
  );
}
function AvatarWithImage({
  src,
  user,
  sx = {},
  onClick,
  size = "small",
  showBadge,
  badge
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [blurUrl, setBlurUrl] = useState(null);
  useEffect(() => {
    if (user == null ? void 0 : user.avatarHash) {
      const width = 32;
      const height = 32;
      const pixels = decode(user.avatarHash, width, height);
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      const imageData = ctx == null ? void 0 : ctx.createImageData(width, height);
      if (imageData) {
        imageData.data.set(pixels);
        ctx == null ? void 0 : ctx.putImageData(imageData, 0, 0);
        const blurDataUrl = canvas.toDataURL();
        setBlurUrl(blurDataUrl);
      }
    }
  }, [user == null ? void 0 : user.avatarHash]);
  const handleImageLoad = () => setIsLoaded(true);
  const styles = __spreadValues(__spreadValues({
    "&:hover": onClick && {
      cursor: "pointer",
      filter: "brightness(70%)"
    }
  }, sx), sizes[size]);
  return /* @__PURE__ */ jsx(
    Badge,
    {
      invisible: !showBadge,
      overlap: "circular",
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      badgeContent: badge,
      children: /* @__PURE__ */ jsx(
        Avatar,
        {
          src: isLoaded ? src : blurUrl || src,
          sx: styles,
          alt: user == null ? void 0 : user.displayName,
          onClick,
          onLoad: handleImageLoad
        }
      )
    }
  );
}
function UserAvatarComponent({
  user,
  tooltip = false,
  sx,
  size,
  onClick,
  tooltipText = "",
  showBadge = false,
  badge,
  publicURL
}) {
  var _a;
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const [publicURLString, setPublicURLString] = useState(void 0);
  const title = tooltipText ? tooltipText : (user == null ? void 0 : user.displayName) ? polyglot.t((_a = user == null ? void 0 : user.displayName) != null ? _a : "") : "";
  const fetchPublicUrl = useCallback(() => __async(this, null, function* () {
    try {
      const publicURL2 = yield CompanyAPI.getPublicURL();
      setPublicURLString(publicURL2);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  }), [polyglot, showMessage]);
  useEffect(() => {
    const urlToSet = state.publicURL || publicURL;
    if (urlToSet) {
      setPublicURLString(urlToSet);
    } else {
      fetchPublicUrl();
    }
  }, [state.publicURL, publicURL, fetchPublicUrl]);
  const avatarEl = useMemo(() => {
    const hasValidURL = publicURLString && (user == null ? void 0 : user.avatarFileName);
    const src = hasValidURL ? `${publicURLString}/${user.avatarFileName}` : void 0;
    return !hasValidURL ? /* @__PURE__ */ jsx(AvatarWithInitials, { user, onClick, size, sx, showBadge, badge }) : /* @__PURE__ */ jsx(
      AvatarWithImage,
      {
        user,
        src,
        onClick,
        size,
        sx,
        showBadge,
        badge
      }
    );
  }, [user, onClick, size, sx, showBadge, badge, publicURLString]);
  return tooltip && title ? /* @__PURE__ */ jsx(StyledTooltip, { title, children: avatarEl }) : avatarEl;
}
const UserAvatarLoader = ({
  user,
  sx = {},
  onClick,
  size = "small",
  showBadge = false,
  badge
}) => {
  return /* @__PURE__ */ jsx(AvatarWithInitials, { user, sx, onClick, size, showBadge, badge });
};
export function PlusAvatar({ count, size = "small", sx }) {
  return /* @__PURE__ */ jsxs(
    Avatar,
    {
      sx: __spreadValues(__spreadValues({
        bgcolor: neutralColors.n0,
        border: `2px solid ${neutralColors.n5}`,
        color: neutralColors.n5,
        fontSize: fontSizeByAvatarSize[size]
      }, sizes[size]), sx),
      children: [
        "+",
        count
      ]
    }
  );
}
export const UserAvatar = (props) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const {
    userId,
    userInfo,
    tooltip = true,
    showName = false,
    nameVariant = "caption",
    nameColor = "DarkGrey",
    name,
    nameSx
  } = props;
  const user = userInfo != null ? userInfo : getCachedUserById(userId);
  const displayName = useMemo(
    () => {
      var _a;
      return name && name.firstName && name.lastName ? polyglot.t(`${name.firstName} ${name.lastName}`) : user ? polyglot.t((_a = user.displayName) != null ? _a : "") : "";
    },
    [polyglot, user, name]
  );
  const typographyRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  useEffect(() => {
    const checkTruncation = () => {
      if (typographyRef.current) {
        const { clientWidth, scrollWidth } = typographyRef.current;
        setIsTruncated(scrollWidth > clientWidth);
      }
    };
    checkTruncation();
    const resizeObserver = new ResizeObserver(() => {
      checkTruncation();
    });
    if (typographyRef.current) resizeObserver.observe(typographyRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [displayName, nameSx]);
  const avatarComponent = useMemo(
    () => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.s1, alignItems: "center", minWidth: 0 }, children: [
      /* @__PURE__ */ jsx(UserAvatarComponent, __spreadProps(__spreadValues({}, props), { user, tooltip: !showName })),
      showName && user && /* @__PURE__ */ jsx(
        Typography,
        {
          ref: typographyRef,
          variant: nameVariant,
          color: nameColor,
          sx: __spreadValues({
            overflow: "hidden",
            textOverflow: "ellipsis"
          }, nameSx),
          children: displayName
        }
      )
    ] }),
    [props, user, showName, nameVariant, nameColor, nameSx, displayName]
  );
  const showTooltip = tooltip && (!showName && displayName || isTruncated);
  return /* @__PURE__ */ jsx(Suspense, { fallback: /* @__PURE__ */ jsx(UserAvatarLoader, __spreadProps(__spreadValues({}, props), { user })), children: /* @__PURE__ */ jsx(Fade, { timeout: 2e3, children: /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(StyledTooltip, { title: showTooltip ? displayName : "", children: avatarComponent }) }) }) });
};
