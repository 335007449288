"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, FormControl } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { GoalOwnerType, GoalStatus, goalOwnerTypeOptions, goalStatusOptions } from "../goal.interface";
import { GoalAPI } from "../goals.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const GoalMutationModal = ({
  goal,
  isOpen,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(GoalCreationModalContent, { goal, onClose, refresh }) });
};
const GoalCreationModalContent = ({
  goal,
  onClose,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      name: (_a = goal == null ? void 0 : goal.name) != null ? _a : "",
      description: (_b = goal == null ? void 0 : goal.description) != null ? _b : "",
      dueDate: (_c = goal == null ? void 0 : goal.dueDate) != null ? _c : null,
      ownerType: (_d = goal == null ? void 0 : goal.ownerType) != null ? _d : GoalOwnerType.Company,
      ownerIds: (_e = goal == null ? void 0 : goal.ownerIds) != null ? _e : [],
      factorIds: (_f = goal == null ? void 0 : goal.factorIds) != null ? _f : [],
      status: (_g = goal == null ? void 0 : goal.status) != null ? _g : GoalStatus.Planned
    },
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t("NewGoalDrawer.errorMessages.nameRequired")),
      description: yup.string().notRequired(),
      dueDate: yup.string().notRequired().default(null),
      ownerType: yup.string().required(polyglot.t("NewGoalDrawer.errorMessages.ownerTypeRequired")),
      ownerIds: yup.array().required(polyglot.t("NewGoalDrawer.errorMessages.ownerTypeRequired")),
      status: yup.string().notRequired()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        yield GoalAPI.createGoal(__spreadValues({}, values));
        yield refresh();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("NewGoalDrawer.newGoal") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("NewGoalDrawer.formFields.name"),
        value: formik.values.name,
        onChange: formik.handleChange,
        required: true,
        error: formik.touched.name && !!formik.errors.name,
        helperText: formik.touched.name && formik.errors.name,
        endAdornment: "none",
        fullWidth: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "description",
        label: polyglot.t("NewGoalDrawer.formFields.description"),
        value: formik.values.description,
        onChange: formik.handleChange,
        error: formik.touched.description && !!formik.errors.description,
        helperText: formik.touched.description && formik.errors.description,
        endAdornment: "none",
        fullWidth: true
      }
    ),
    /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_h = formik.values.dueDate) != null ? _h : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("dueDate", value);
          }
        },
        name: "dueDate",
        required: false,
        label: polyglot.t("NewGoalDrawer.formFields.dueDate"),
        error: !!formik.errors.dueDate && formik.touched.dueDate,
        helperText: formik.errors.dueDate && formik.touched.dueDate
      }
    ) }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "ownerType",
        label: polyglot.t("NewGoalDrawer.formFields.goalBelongsTo"),
        options: goalOwnerTypeOptions,
        value: formik.values.ownerType,
        onChange: formik.handleChange,
        required: true,
        compareValue: formik.values.ownerType,
        error: !!formik.errors.ownerType && formik.touched.ownerType,
        helperText: formik.touched.ownerType && formik.errors.ownerType
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "status",
        label: polyglot.t("NewGoalDrawer.formFields.status"),
        options: goalStatusOptions,
        value: formik.values.status,
        onChange: formik.handleChange,
        compareValue: formik.values.status,
        error: !!formik.errors.status && formik.touched.status,
        helperText: formik.touched.status && formik.errors.status
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
