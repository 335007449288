"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Autocomplete, Box, FormControl, IconButton, Paper, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { HolidayCalendarEndpoints } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { CustomCountryEnum } from "@v2/infrastructure/country/country.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { actionIconSize, iconSize } from "@v2/styles/table.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { ContractsAPI } from "@/api-client/contracts.api";
import { UserContractSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as ArrowDownACIcon } from "@/images/side-bar-icons/ArrowDownAC.svg";
import { ReactComponent as ChoseIcon } from "@/images/side-bar-icons/Chose.svg";
import { nestErrorMessage } from "@/lib/errors";
import { AttendanceScheduleSelect } from "@/v2/components/attendance-schedule-select.component";
import { ContractEndDatePickerComponent } from "@/v2/components/contract-end-date-picker.component";
import { EntitySelect } from "@/v2/components/entity-select.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { ClearIcon } from "@/v2/components/theme-components/clear-icon.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { getPublicHolidayCalendarOptions } from "@/v2/feature/absence/absence.util";
import {
  CustomFieldComponents,
  ProfileField
} from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import {
  getContractTypeOptions,
  getEmploymentTypeOptions,
  getUnitTypeOptions
} from "@/v2/feature/user/features/user-profile/user-profile.interface";
import { StyledAuto, StyledAutoTextfield } from "@/v2/styles/autocomplete.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
import { StyledMenuItem } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ContractForm = ({
  initialValues,
  userId,
  loadInitialData,
  rowModalMode,
  handleSubmit,
  setFormCurrentlyEditing,
  setIsModalOpen,
  setLoading,
  loading,
  onClose,
  usedForDataImport = false,
  importHandler = () => {
  },
  showEmployee = false
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const { data: holidayCalendars } = useApiClient(HolidayCalendarEndpoints.getHolidayCalendars(), { suspense: false });
  const { polyglot } = usePolyglot();
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [rowForDeletion, setRowForDeletion] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMessage] = useMessage();
  const defaultBlankModalValues = useMemo(
    () => ({
      effectiveDate: new LocalDate().toDateString(),
      contract: "Full-time",
      type: "Employee",
      ftePercent: 100,
      entityId: null,
      attendanceScheduleId: null,
      contractEndDate: null,
      customUpdates: [],
      publicHolidays: null,
      holidayCalendarId: null,
      noticePeriodUnit: null,
      probationPeriodUnit: null
    }),
    []
  );
  const customHolidayCalendarsOptions = useMemo(() => {
    if (!holidayCalendars) return [];
    return holidayCalendars.map((hc) => ({ label: hc.name, value: hc.id }));
  }, [holidayCalendars]);
  const formik = useFormik({
    initialValues: initialValues != null ? initialValues : defaultBlankModalValues,
    enableReinitialize: true,
    validationSchema: UserContractSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        if (!usedForDataImport) {
          if (!values.effectiveDate || !values.type) {
            showMessage(polyglot.t("ContractForm.errorMessages.createUpdate"), "error");
            return;
          }
          setLoading(true);
          if (rowModalMode === "add") {
            yield ContractsAPI.createUserContract(userId, values);
            showMessage(polyglot.t("ContractForm.successMessages.add"), "success");
          } else if (rowModalMode === "edit" && "id" in values) {
            yield ContractsAPI.updateById(userId, values);
            showMessage(polyglot.t("ContractForm.successMessages.update"), "success");
          } else {
            showMessage(polyglot.t("ContractForm.errorMessages.createUpdate"), "error");
            return;
          }
          yield loadInitialData();
          setIsModalOpen(false);
        } else {
          importHandler == null ? void 0 : importHandler(values);
        }
      } catch (error) {
        showMessage(`${polyglot.t("ContractForm.errorMessages.update")}: ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
        setFormCurrentlyEditing(false);
        handleSubmit(userId);
      }
    })
  });
  const deleteContractRow = (rowId) => {
    setIsRemovalModalOpen(true);
    setRowForDeletion(rowId);
  };
  const handleDeleteRow = () => __async(void 0, null, function* () {
    try {
      if (rowForDeletion) yield ContractsAPI.deleteById(userId, rowForDeletion);
      showMessage(polyglot.t("ContractForm.successMessages.delete"), "success");
    } catch (error) {
      showMessage(polyglot.t("ContractForm.errorMessages.delete"), "error");
    } finally {
      yield loadInitialData();
      yield handleSubmit(userId);
      setRowForDeletion(0);
      setFormCurrentlyEditing(false);
      formik.resetForm();
      setIsModalOpen(false);
      setIsRemovalModalOpen(false);
    }
  });
  const holidayCountries = useMemo(() => getPublicHolidayCalendarOptions({ includeCustom: true }), []);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    rowModalMode === "add" ? /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ContractForm.newContract") }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("ContractForm.editContract") }),
      "id" in formik.values && /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: iconCTAButtonSx,
          onClick: (event) => {
            if (!("id" in formik.values)) return;
            setAnchorEl(event.currentTarget);
            setIsRemovalModalOpen(true);
            deleteContractRow(formik.values.id);
          },
          children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
        }
      )
    ] }),
    showEmployee && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: polyglot.t("ContractForm.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId, nameSx: __spreadProps(__spreadValues({}, themeFonts.title4), { color: themeColors.DarkGrey }) })
    ] }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "contract.effectiveDate", children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_a = formik.values.effectiveDate) != null ? _a : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("effectiveDate", value);
          }
        },
        name: "effectiveDate",
        label: polyglot.t("ContractForm.effectiveDate"),
        error: !!formik.errors.effectiveDate && Boolean(formik.errors.effectiveDate),
        helperText: formik.touched.effectiveDate && formik.errors.effectiveDate
      }
    ) }) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "contract.effectiveDate", children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: polyglot.t("ContractForm.workerType"),
        options: getEmploymentTypeOptions(polyglot),
        value: formik.values.type,
        compareValue: formik.values.type,
        error: !!formik.errors.type && formik.touched.type,
        onChange: formik.handleChange,
        helperText: formik.touched.type && formik.errors.type
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "contract.contract", children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "contract",
        label: polyglot.t("ContractForm.employmentContract"),
        options: getContractTypeOptions(polyglot),
        value: formik.values.contract,
        compareValue: formik.values.contract,
        error: !!formik.errors.contract && formik.touched.contract,
        onChange: formik.handleChange,
        helperText: formik.touched.contract && formik.errors.contract
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "contract.entity", children: /* @__PURE__ */ jsx(
      EntitySelect,
      {
        name: "entityId",
        value: formik.values.entityId,
        onChange: formik.handleChange,
        error: !!formik.submitCount && !!formik.errors.entityId,
        helperText: formik.touched.entityId && formik.errors.entityId
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "contract.attendanceSchedule", children: /* @__PURE__ */ jsx(
      AttendanceScheduleSelect,
      {
        name: "attendanceScheduleId",
        label: polyglot.t("ContractForm.attendance"),
        value: formik.values.attendanceScheduleId,
        onChange: formik.handleChange,
        error: formik.touched.attendanceScheduleId && !!formik.errors.attendanceScheduleId,
        helperText: formik.touched.attendanceScheduleId && formik.errors.attendanceScheduleId
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "contract.publicHolidays", children: /* @__PURE__ */ jsx(
      Autocomplete,
      {
        options: holidayCountries,
        value: holidayCountries.find(({ value }) => value === formik.values.publicHolidays),
        getOptionLabel: (option) => option.label,
        onChange: (_, x) => formik.setFieldValue("publicHolidays", x == null ? void 0 : x.value),
        renderOption: (props, option) => {
          return option.value === formik.values.publicHolidays ? /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", children: option.label }),
            /* @__PURE__ */ jsx(ChoseIcon, __spreadValues({}, iconSize))
          ] }) })) : /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: option.label }) }));
        },
        renderInput: (params) => /* @__PURE__ */ jsx(
          StyledAutoTextfield,
          __spreadProps(__spreadValues({}, params), {
            variant: "standard",
            InputLabelProps: { shrink: true },
            placeholder: polyglot.t("ContractForm.holidayCal"),
            size: "small",
            label: polyglot.t("ContractForm.holidayCal"),
            name: "publicHolidays",
            error: formik.touched.publicHolidays && !!formik.errors.publicHolidays,
            helperText: formik.touched.publicHolidays && formik.errors.publicHolidays
          })
        ),
        PaperComponent: ({ children }) => /* @__PURE__ */ jsx(Paper, { sx: StyledAuto, children }),
        popupIcon: /* @__PURE__ */ jsx(ArrowDownACIcon, {}),
        clearIcon: /* @__PURE__ */ jsx(ClearIcon, {})
      }
    ) }),
    formik.values.publicHolidays === CustomCountryEnum.code && /* @__PURE__ */ jsx(ProfileField, { fieldStub: "contract.publicHolidays", children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "holidayCalendarId",
        label: polyglot.t("HolidayCalendarModule.customHolidayCalendar"),
        options: customHolidayCalendarsOptions,
        value: formik.values.holidayCalendarId,
        onChange: formik.handleChange,
        compareValue: formik.values.holidayCalendarId,
        error: !!formik.errors.holidayCalendarId && formik.touched.publicHolidays,
        helperText: formik.touched.publicHolidays && formik.errors.holidayCalendarId
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "contract.changeReason", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "changeReason",
        label: polyglot.t("ContractForm.changeReason"),
        value: formik.values.changeReason,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.changeReason && !!formik.errors.changeReason,
        helperText: (_b = formik.touched.changeReason && formik.errors.changeReason) != null ? _b : " ",
        clearText: () => formik.setFieldValue("changeReason", "")
      }
    ) }),
    /* @__PURE__ */ jsxs(
      ProfileField,
      {
        fieldStub: "contract.probationPeriod",
        sx: {
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          gap: "10px"
        },
        children: [
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "probationPeriodLength",
              label: polyglot.t("ContractForm.probationPeriod"),
              value: formik.values.probationPeriodLength,
              type: "number",
              onChange: formik.handleChange,
              error: formik.touched.probationPeriodLength && !!formik.errors.probationPeriodLength,
              helperText: (_c = formik.touched.probationPeriodLength && formik.errors.probationPeriodLength) != null ? _c : " ",
              clearText: () => formik.setFieldValue("probationPeriodLength", "")
            }
          ),
          /* @__PURE__ */ jsx(
            SelectComponent,
            {
              name: "probationPeriodUnit",
              label: polyglot.t("ContractForm.probationPeriodUnit"),
              options: getUnitTypeOptions(polyglot),
              value: formik.values.probationPeriodUnit,
              compareValue: formik.values.probationPeriodUnit,
              error: !!formik.errors.probationPeriodUnit && formik.touched.probationPeriodUnit,
              onChange: formik.handleChange,
              helperText: formik.touched.probationPeriodUnit && formik.errors.probationPeriodUnit
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsxs(
      ProfileField,
      {
        fieldStub: "contract.noticePeriod",
        sx: {
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          gap: "10px"
        },
        children: [
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "noticePeriodLength",
              label: polyglot.t("ContractForm.noticePeriod"),
              value: formik.values.noticePeriodLength,
              type: "number",
              onChange: formik.handleChange,
              error: formik.touched.noticePeriodLength && !!formik.errors.noticePeriodLength,
              helperText: (_d = formik.touched.noticePeriodLength && formik.errors.noticePeriodLength) != null ? _d : " ",
              clearText: () => formik.setFieldValue("noticePeriodLength", "")
            }
          ),
          /* @__PURE__ */ jsx(
            SelectComponent,
            {
              name: "noticePeriodUnit",
              label: polyglot.t("ContractForm.noticePeriodUnit"),
              options: getUnitTypeOptions(polyglot),
              value: formik.values.noticePeriodUnit,
              compareValue: formik.values.noticePeriodUnit,
              error: !!formik.errors.noticePeriodUnit && formik.touched.noticePeriodUnit,
              onChange: formik.handleChange,
              helperText: formik.touched.noticePeriodUnit && formik.errors.noticePeriodUnit
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: (_e = formik.values.customUpdates) != null ? _e : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        rowModalMode,
        formName: CustomProfileFormType.Contract
      }
    ),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "contract.contractEndDate", sx: { mb: "20px" }, children: /* @__PURE__ */ jsx(
      ContractEndDatePickerComponent,
      {
        name: "contractEndDate",
        label: "End date",
        effectiveDate: (_f = formik.values.effectiveDate) != null ? _f : new LocalDate().toDateString(),
        value: (_g = formik.values.contractEndDate) != null ? _g : null,
        onChange: (value) => formik.setFieldValue("contractEndDate", value),
        disabled: loading,
        error: !!formik.errors.contractEndDate,
        helperText: formik.errors.contractEndDate
      }
    ) }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isRemovalModalOpen,
        onClose: () => {
          setIsRemovalModalOpen(false);
          setRowForDeletion(0);
        },
        anchorEl,
        takeAction: handleDeleteRow,
        message: polyglot.t("ContractForm.confirmDelete"),
        callToAction: "Yes"
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] }) });
};
