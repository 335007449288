"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { isHTMLValid } from "@/v2/feature/growth/shared/utils/growth-common.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const OpenEndedQuestion = ({
  question,
  answerArray,
  handleAnswerChange,
  entry,
  showError
}) => {
  var _a;
  const currentAnswer = ((_a = answerArray == null ? void 0 : answerArray.find((ans) => ans.questionId === question.id)) == null ? void 0 : _a.comment) || "";
  if (!question || !entry) return /* @__PURE__ */ jsx(Fragment, {});
  const handleInputChange = (value) => {
    handleAnswerChange(question.id, { updatedAnswer: null, comment: value }, entry);
  };
  return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24 }, children: /* @__PURE__ */ jsx(
    RichTextField,
    {
      value: currentAnswer,
      onChange: (value) => {
        handleInputChange(value);
      },
      error: showError && !isHTMLValid(currentAnswer),
      helperText: showError && !isHTMLValid(currentAnswer) ? "This field is required" : ""
    },
    question.id
  ) });
};
