"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { DeviceOwnership, DevicePossessionType } from "@/v2/feature/device/device.interface";
import { DeviceOSValueLabelOptions } from "@/v2/feature/device/device.util";
import {
  deviceTermSx,
  deviceValueSx
} from "@/v2/feature/device/features/device-cards/components/devices-styles.layout";
import { EditTechSpecsDrawerContent } from "@/v2/feature/device/features/devices-company/components/edit-tech-specs-drawer.component";
import { EnrollmentStatus } from "@/v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const FieldValueComponent = ({ title, value }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex" }, children: [
    /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: title }),
    /* @__PURE__ */ jsx(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: value })
  ] });
};
export const TechSpecsViewModal = ({
  setIsOpen,
  isOpen,
  devicePossession,
  refresh,
  activeTransit
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  const [isEdit, setIsEdit] = useState(false);
  const [isEditEnrolledDevice, setIsEditEnrolledDevice] = useState(false);
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const { device, isPossessionActive } = useMemo(() => {
    const device2 = devicePossession.device;
    const isPossessionActive2 = !!devicePossession.startDate && !devicePossession.endDate;
    return { device: device2, isPossessionActive: isPossessionActive2 };
  }, [devicePossession]);
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      setIsOpen,
      isOpen,
      onClose: () => {
        setIsEdit(false);
        setIsEditEnrolledDevice(false);
      },
      children: /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { mb: spacing.m30 }), children: "Details" }),
        !(!isPossessionActive || !!activeTransit || ((_a = devicePossession.device) == null ? void 0 : _a.ownership) === DeviceOwnership.Company || devicePossession.possessionType === DevicePossessionType.ZeltStorage) && !isEdit && !isEditEnrolledDevice && /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mb40), children: [
          /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: "Order info" }),
          /* @__PURE__ */ jsx(Box, { component: "section", sx: { mt: spacing.m15 }, children: /* @__PURE__ */ jsxs(
            Box,
            {
              component: "dl",
              sx: { display: "flex", flexDirection: "column", margin: 0, padding: 0, gap: spacing.g15 },
              children: [
                devicePossession.startDate && /* @__PURE__ */ jsx(
                  FieldValueComponent,
                  {
                    title: "Delivered on",
                    value: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: getDateString(devicePossession.startDate) })
                  }
                ),
                devicePossession.deliveryAddress && /* @__PURE__ */ jsx(
                  FieldValueComponent,
                  {
                    title: "Delivered to",
                    value: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: devicePossession.deliveryAddress })
                  }
                ),
                ((_b = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _b.price) && [DeviceOwnership.Rental, DeviceOwnership.CompanyPurchase].includes(
                  (_c = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _c.ownership
                ) && /* @__PURE__ */ jsx(
                  FieldValueComponent,
                  {
                    title: ((_d = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _d.ownership) === DeviceOwnership.Rental ? "Monthly rent" : "Purchase price",
                    value: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: (_e = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _e.price })
                  }
                ),
                ((_f = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _f.contractLength) && [DeviceOwnership.Rental].includes((_g = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _g.ownership) && /* @__PURE__ */ jsx(
                  FieldValueComponent,
                  {
                    title: "Contract length",
                    value: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: (_h = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _h.contractLength })
                  }
                ),
                (devicePossession == null ? void 0 : devicePossession.device) && /* @__PURE__ */ jsx(
                  FieldValueComponent,
                  {
                    title: "Billed by",
                    value: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: ((_i = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _i.externallyInvoiced) ? "Two" : "Zelt" })
                  }
                )
              ]
            }
          ) })
        ] }),
        !isEdit && !isEditEnrolledDevice && /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: "Tech specs" }),
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g15, mt: spacing.m15 }, children: [
            /* @__PURE__ */ jsx(ScopesControl, { scopes: ["devices:all"], context: scopesContext, children: device.deviceName && /* @__PURE__ */ jsx(FieldValueComponent, { title: "Device name", value: device.deviceName }) }),
            device.manufacturer && /* @__PURE__ */ jsx(
              FieldValueComponent,
              {
                title: "Manufacturer",
                value: device.manufacturer.charAt(0).toUpperCase() + device.manufacturer.slice(1)
              }
            ),
            device.modelNumber && /* @__PURE__ */ jsx(FieldValueComponent, { title: "Model", value: device.modelNumber }),
            device.modelName && /* @__PURE__ */ jsx(FieldValueComponent, { title: "Model name", value: device.modelName }),
            device.serialNumber && /* @__PURE__ */ jsx(FieldValueComponent, { title: "Serial number", value: device.serialNumber }),
            device.os && /* @__PURE__ */ jsx(
              FieldValueComponent,
              {
                title: "OS",
                value: (_l = (_k = (_j = DeviceOSValueLabelOptions.find((opt) => opt.value === device.os)) == null ? void 0 : _j.label) != null ? _k : device.os) != null ? _l : "N/A"
              }
            ),
            device.osVersion && /* @__PURE__ */ jsx(FieldValueComponent, { title: "OS version", value: device.osVersion }),
            device.ram && /* @__PURE__ */ jsx(FieldValueComponent, { title: "RAM", value: `${device.ram}GB` }),
            device.cpu && /* @__PURE__ */ jsx(FieldValueComponent, { title: "CPU", value: `${device.cpu}-cores` }),
            device.storage && /* @__PURE__ */ jsx(FieldValueComponent, { title: "Storage", value: `${device.storage}GB` }),
            device.gpu && /* @__PURE__ */ jsx(FieldValueComponent, { title: "GPU", value: `${device.gpu}-cores` }),
            device.screenSize && /* @__PURE__ */ jsx(FieldValueComponent, { title: "Screen Size", value: `${device.screenSize}"` }),
            device.lastScanned && /* @__PURE__ */ jsx(
              FieldValueComponent,
              {
                title: "Last scanned",
                value: formatDistanceToNowStrict(new Date(device.lastScanned))
              }
            ),
            device.lastCheckIn && /* @__PURE__ */ jsx(
              FieldValueComponent,
              {
                title: "Last active",
                value: formatDistanceToNowStrict(new Date(device.lastCheckIn))
              }
            )
          ] }),
          device.ownership === DeviceOwnership.Company && !Boolean(
            device.enrollmentStatus === "enrolled" || device.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED
          ) && /* @__PURE__ */ jsx(ScopesControl, { scopes: ["devices:all"], context: scopesContext, children: /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt40), children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "secondary",
              onClick: () => setIsEdit(true),
              fullWidth: true,
              children: "Edit"
            }
          ) }) })
        ] }),
        !isEditEnrolledDevice && /* @__PURE__ */ jsx(Box, { children: device.ownership === DeviceOwnership.Company && Boolean(
          device.enrollmentStatus === "enrolled" || device.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED
        ) && /* @__PURE__ */ jsx(ScopesControl, { scopes: ["devices:all"], context: scopesContext, children: /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt40), children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "secondary",
            onClick: () => setIsEditEnrolledDevice(true),
            fullWidth: true,
            children: "Edit"
          }
        ) }) }) }),
        isEdit && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(EditTechSpecsDrawerContent, { devicePossessionId: devicePossession.id, device, refresh }) })
      ] })
    }
  );
};
