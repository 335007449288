"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Switch } from "react-router-dom";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { PEOPLE_COMPANY_ACTION_ITEMS, PEOPLE_ME_ACTION_ITEMS, PEOPLE_TEAM_ACTION_ITEMS } from "@/lib/routes";
import { ActionItemsAdminManagerPage } from "@/v2/feature/action-items/admin-manager/action-items-admin-manager.page";
import { ActionItemsMePage } from "@/v2/feature/action-items/me/action-items-me.page";
import { ReachType } from "@/v2/interfaces/general.interface";
export const ActionItemsRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["user:read:all"], path: PEOPLE_COMPANY_ACTION_ITEMS, children: /* @__PURE__ */ jsx(ActionItemsAdminManagerPage, { reach: ReachType.Company }) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["user:read:all"], path: PEOPLE_TEAM_ACTION_ITEMS, children: /* @__PURE__ */ jsx(ActionItemsAdminManagerPage, { reach: ReachType.Team }) }),
    /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["user:read:all"], path: PEOPLE_ME_ACTION_ITEMS, children: /* @__PURE__ */ jsx(ActionItemsMePage, { reach: ReachType.Me }) })
  ] });
};
