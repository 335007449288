"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@v2/components/typography/typography.component";
import { borders } from "@v2/styles/borders.styles";
import { themeColors } from "@v2/styles/colors.styles";
export const ChipComponentWithIcon = ({
  name,
  icon,
  onClick,
  textColor = "Grey",
  backgroundColor = "Background",
  border,
  textVariant = "caption",
  chipStyles
}) => {
  const chipStyle = __spreadValues({
    display: "inline-flex",
    alignItems: "center",
    padding: "2px 10px",
    borderRadius: "25px",
    backgroundColor: themeColors[backgroundColor],
    cursor: onClick ? "pointer" : "default",
    border: border ? borders[border] : void 0,
    gap: "8px"
  }, chipStyles);
  const iconStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };
  const handleClick = () => {
    if (onClick) onClick();
  };
  return /* @__PURE__ */ jsxs("div", { style: chipStyle, onClick: handleClick, children: [
    icon && /* @__PURE__ */ jsx("div", { style: iconStyle, children: icon }),
    /* @__PURE__ */ jsx(Typography, { variant: textVariant, color: textColor, children: name })
  ] });
};
