"use strict";
export const stripHtml = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};
export const isHTMLValid = (html) => {
  if (!html) return false;
  const strippedComment = stripHtml(html).trim();
  return strippedComment.length > 0;
};
