"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  GROWTH_COMPANY_GOALS_ROUTE,
  GROWTH_GOAL_BASE_ROUTE,
  GROWTH_ME_GOALS_ROUTE,
  GROWTH_ROUTE,
  GROWTH_TEAM_GOALS_ROUTE,
  REVIEWS_COMPANY_ROUTE,
  REVIEWS_ME_ROUTE,
  REVIEWS_TEAM_ROUTE,
  SURVEYS_COMPANY_ROUTE,
  SURVEYS_ME_ROUTE,
  SURVEYS_TEAM_ROUTE
} from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { GoalsRouter } from "@/v2/feature/growth/goals/goals.router";
import { ReviewsRouter } from "@/v2/feature/growth/reviews/reviews.router";
import { SurveysRouter } from "@/v2/feature/growth/surveys/surveys.router";
export const GrowthRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(user);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["reviews"],
        context: scopesContext,
        path: [REVIEWS_COMPANY_ROUTE, REVIEWS_TEAM_ROUTE, REVIEWS_ME_ROUTE],
        component: ReviewsRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["surveys"],
        context: scopesContext,
        path: [SURVEYS_COMPANY_ROUTE, SURVEYS_TEAM_ROUTE, SURVEYS_ME_ROUTE],
        component: SurveysRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["goals"],
        context: scopesContext,
        path: [GROWTH_COMPANY_GOALS_ROUTE, GROWTH_TEAM_GOALS_ROUTE, GROWTH_ME_GOALS_ROUTE, GROWTH_GOAL_BASE_ROUTE],
        component: GoalsRouter
      }
    ),
    canAccessScopes(user, ["reviews:all"]) && /* @__PURE__ */ jsx(Redirect, { from: GROWTH_ROUTE, to: REVIEWS_COMPANY_ROUTE }),
    canAccessScopes(user, ["reviews:manager"]) && /* @__PURE__ */ jsx(Redirect, { from: GROWTH_ROUTE, to: REVIEWS_TEAM_ROUTE }),
    canAccessScopes(user, ["reviews"]) && /* @__PURE__ */ jsx(Redirect, { from: GROWTH_ROUTE, to: REVIEWS_ME_ROUTE }),
    canAccessScopes(user, ["surveys:all"]) && /* @__PURE__ */ jsx(Redirect, { from: GROWTH_ROUTE, to: SURVEYS_COMPANY_ROUTE }),
    canAccessScopes(user, ["surveys:manager"]) && /* @__PURE__ */ jsx(Redirect, { from: GROWTH_ROUTE, to: SURVEYS_TEAM_ROUTE }),
    canAccessScopes(user, ["surveys"]) && /* @__PURE__ */ jsx(Redirect, { from: GROWTH_ROUTE, to: SURVEYS_ME_ROUTE })
  ] });
};
