"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ScaleQuestion = ({
  question,
  reviewType,
  answerArray,
  handleAnswerChange,
  entry,
  showError
}) => {
  const { currentAnswer, currentComment } = useMemo(() => {
    const answerObject = (answerArray == null ? void 0 : answerArray.find((ans) => ans.questionId === question.id)) || { answer: "", comment: "" };
    return { currentAnswer: answerObject.answer, currentComment: answerObject.comment };
  }, [answerArray, question.id]);
  if (!question || !reviewType || !entry) return /* @__PURE__ */ jsx(Fragment, {});
  const handleScaleChange = (value) => {
    handleAnswerChange(question.id, { updatedAnswer: value, comment: currentComment }, entry);
  };
  const handleCommentChange = (value) => {
    if (entry) {
      handleAnswerChange(question.id, { updatedAnswer: currentAnswer, comment: value }, entry);
    }
  };
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24 }, children: [
    question && /* @__PURE__ */ jsx(
      RatingScale,
      {
        scaleConfig: question.scaleConfig,
        handleScaleChange,
        currentAnswer,
        showError
      }
    ),
    question.hasComment && /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: spacing.g8,
          pt: spacing.p16,
          borderTop: `${borders.background}`
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: "Add comment" }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: question.isCommentRequired ? "Required" : "Optional" })
          ] }),
          /* @__PURE__ */ jsx(
            RichTextField,
            {
              value: currentComment,
              onChange: (value) => handleCommentChange(value),
              error: showError && question.hasComment && question.isCommentRequired,
              helperText: showError && question.hasComment && question.isCommentRequired && !currentComment ? "This field is required" : ""
            },
            question.id
          )
        ]
      }
    )
  ] });
};
const RatingScale = ({
  scaleConfig,
  handleScaleChange,
  currentAnswer,
  showError
}) => {
  const [scaleKey, selectedScaleKey] = useState(currentAnswer);
  const scaleItemStyle = (value) => ({
    flex: 1,
    height: "44px",
    display: "flex",
    width: "100%",
    cursor: "pointer",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    backgroundColor: currentAnswer === value ? themeColors.DarkGrey : themeColors.Background,
    borderRadius: radius.br8,
    userSelect: "none",
    border: showError && !currentAnswer ? `1px solid ${themeColors.darkRed}` : "none"
  });
  return /* @__PURE__ */ jsxs("div", { style: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
    (scaleConfig == null ? void 0 : scaleConfig.points) && /* @__PURE__ */ jsx(Box, { sx: { visibility: scaleKey ? "visible" : "hidden", minHeight: "20px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: scaleKey ? scaleConfig.points[scaleKey] : "" }) }),
    /* @__PURE__ */ jsx(Box, { style: { display: "flex", flexGrow: 1, gap: spacing.g4, height: "44px" }, children: (scaleConfig == null ? void 0 : scaleConfig.value) && Object.entries(scaleConfig.value).map(([key, value]) => /* @__PURE__ */ jsx(
      Box,
      {
        style: scaleItemStyle(key),
        onClick: () => {
          handleScaleChange(key);
          selectedScaleKey(key);
        },
        children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: currentAnswer === key ? "Background" : "DarkGrey", children: value })
      },
      key
    )) }),
    showError && !currentAnswer && /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: showError ? themeColors.darkRed : themeColors.DarkGrey }, children: "This field is required" })
  ] });
};
