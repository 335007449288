"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/Action.svg";
import { ReactComponent as Plus } from "@/images/fields/Plus.svg";
import { ReactComponent as Trash } from "@/images/fields/Trash.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { nestErrorMessage } from "@/lib/errors";
import { canAccessScopes } from "@/lib/scopes";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { MultiUserAvatar } from "@/v2/components/theme-components/multi-user-avatar.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ParticipantsAddReviewerModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-add-reviewer-modal.component";
import { ParticipantsDeleteRevieweeModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-delete-reviewee-modal.component";
import { ParticipantsRemoveReviewerModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-remove-reviewer-modal.component";
import { useManagerTeam } from "@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-manager-team.hook";
import {
  ReviewerTypes
} from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { iconSize } from "@/v2/styles/menu.styles";
import { selectColumnSize } from "@/v2/styles/table.styles";
export const ParticipantsSharedTable = ({
  reviewCycle,
  reviewParticipants,
  reviewParticipantsLoading,
  refresh,
  isEditable
}) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [isRevieweeDeleteOpen, setIsRevieweeDeleteOpen] = useState(false);
  const [isReviewerAddOpen, setIsReviewerAddOpen] = useState(false);
  const [isReviewerDeleteOpen, setIsReviewerDeleteOpen] = useState(false);
  const [selectedReviewee, setSelectedReviewee] = useState(void 0);
  const [showMessage] = useMessage();
  const { usersContainManagersTeam } = useManagerTeam();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const isAdmin = canAccessScopes(user, ["reviews:all"]);
  const isManager = canAccessScopes(user, ["reviews:manager"]);
  const isManagerTeamInvolved = useMemo(() => {
    var _a;
    return usersContainManagersTeam((_a = reviewCycle == null ? void 0 : reviewCycle.revieweeId) != null ? _a : []);
  }, [
    reviewCycle,
    usersContainManagersTeam
  ]);
  const showAction = useMemo(() => {
    var _a, _b, _c;
    return reviewCycle && (((_a = reviewCycle.reviewerSelect) == null ? void 0 : _a.includes(ReviewerTypes.Peer)) || ((_b = reviewCycle.reviewerSelect) == null ? void 0 : _b.includes(ReviewerTypes.Upward)) || ((_c = reviewCycle.reviewerSelect) == null ? void 0 : _c.includes(ReviewerTypes.Manager))) && isEditable;
  }, [reviewCycle, isEditable]);
  const isFullyEditable = useMemo(() => {
    return Boolean(
      reviewCycle && (reviewCycle.state === CycleState.Draft || reviewCycle.state === CycleState.Scheduled || reviewCycle.state === CycleState.Ongoing) && Boolean(isAdmin || isManager && reviewCycle.owner === user.userId || isManager && isManagerTeamInvolved)
    );
  }, [reviewCycle, isAdmin, isManager, user, isManagerTeamInvolved]);
  const deleteParticipants = useCallback(
    (selectionModel2) => __async(void 0, null, function* () {
      try {
        if (reviewCycle && [CycleState.Draft, CycleState.Scheduled].includes(reviewCycle.state)) {
          yield ReviewCycleAPI.deleteRevieweesToPreOngoing(reviewCycle.id, selectionModel2);
          setSelectionModel([]);
          showMessage("Successfully deleted the participants", "success");
          yield refresh();
        }
        if (isManagerTeamInvolved || isAdmin) {
          setSelectionModel(selectionModel2);
        }
        if ((reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Ongoing) {
          setIsRevieweeDeleteOpen(true);
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      }
    }),
    [reviewCycle, refresh, setSelectionModel, showMessage, isManagerTeamInvolved, isAdmin]
  );
  const tableColumns = useMemo(
    () => {
      var _a, _b, _c;
      return [
        ...isEditable ? [
          {
            id: "select",
            enableSorting: false,
            minSize: selectColumnSize,
            size: selectColumnSize,
            header: () => {
              const displayedSelfId = new Set(reviewParticipants == null ? void 0 : reviewParticipants.map(({ selfId }) => selfId));
              const allSelected = selectionModel.length > 0 && selectionModel.length === displayedSelfId.size && selectionModel.every((id) => displayedSelfId.has(id));
              return /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  label: void 0,
                  name: "allSelected",
                  checked: allSelected,
                  value: "allSelceted",
                  onChange: (_, checked) => {
                    setSelectionModel(checked ? [...displayedSelfId] : []);
                  }
                }
              );
            },
            cell: ({ row: { original } }) => {
              var _a2, _b2;
              return /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  label: void 0,
                  name: (_a2 = original.selfId) == null ? void 0 : _a2.toString(),
                  checked: selectionModel.includes(original.selfId),
                  value: (_b2 = original.selfId) == null ? void 0 : _b2.toString(),
                  onChange: () => {
                    let finalArray = [];
                    if (selectionModel == null ? void 0 : selectionModel.includes(original.selfId)) {
                      finalArray = selectionModel.filter((sm) => sm !== original.selfId);
                    } else finalArray = [...selectionModel, original.selfId];
                    setSelectionModel(finalArray);
                  }
                }
              );
            }
          }
        ] : [],
        {
          header: () => "Participants",
          accessorFn: (row) => row,
          id: "participants",
          enableSorting: false,
          cell: ({
            row: {
              original: { selfId }
            }
          }) => selfId ? /* @__PURE__ */ jsx(UserCell, { userId: selfId }, selfId) : /* @__PURE__ */ jsx(EmptyCell, {})
        },
        ...reviewCycle && ((_a = reviewCycle.reviewerSelect) == null ? void 0 : _a.includes(ReviewerTypes.Manager)) ? [
          {
            header: () => "Manager",
            accessorFn: (row) => row,
            id: "managerId",
            enableSorting: false,
            cell: ({
              row: {
                original: { managerIds }
              }
            }) => managerIds && managerIds.length > 0 ? /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: managerIds, showLimit: 3 }) : /* @__PURE__ */ jsx(EmptyCell, {})
          }
        ] : [],
        ...reviewCycle && ((_b = reviewCycle.reviewerSelect) == null ? void 0 : _b.includes(ReviewerTypes.Upward)) ? [
          {
            header: () => "Upwards",
            accessorFn: (row) => row,
            id: "upwards",
            enableSorting: false,
            cell: ({
              row: {
                original: { upwardIds }
              }
            }) => upwardIds && upwardIds.length > 0 ? /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: upwardIds, showLimit: 3 }) : /* @__PURE__ */ jsx(EmptyCell, {})
          }
        ] : [],
        ...reviewCycle && ((_c = reviewCycle.reviewerSelect) == null ? void 0 : _c.includes(ReviewerTypes.Peer)) ? [
          {
            header: () => "Peers",
            accessorFn: (row) => row,
            id: "peers",
            enableSorting: false,
            cell: ({
              row: {
                original: { peerIds }
              }
            }) => peerIds && peerIds.length > 0 ? /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: peerIds, showLimit: 3 }) : /* @__PURE__ */ jsx(EmptyCell, {})
          }
        ] : [],
        ...showAction ? [
          {
            id: "action",
            header: () => "",
            accessorFn: (row) => row,
            enableSorting: false,
            cell: ({ row: { original } }) => original.isEditable ? /* @__PURE__ */ jsx(
              ActionMenu,
              {
                canEditParticipants: isFullyEditable,
                canEditReviewers: isFullyEditable,
                original,
                setIsReviewerAddOpen,
                setSelectedReviewee,
                setIsReviewerDeleteOpen,
                deleteParticipants
              }
            ) : null
          }
        ] : []
      ];
    },
    [
      reviewCycle,
      reviewParticipants,
      selectionModel,
      setSelectionModel,
      showAction,
      setIsReviewerAddOpen,
      setSelectedReviewee,
      setIsReviewerDeleteOpen,
      isEditable,
      deleteParticipants,
      isFullyEditable
    ]
  );
  if (!reviewCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: [
          {
            handler: () => __async(void 0, null, function* () {
              return deleteParticipants(selectionModel);
            }),
            label: "Remove participants",
            disabled: false
          }
        ],
        sx: {
          visibility: isEditable && selectionModel.length > 0 ? "visible" : "hidden",
          opacity: selectionModel.length > 0 ? 1 : 0,
          transition: "opacity 0.3s ease, visibility 0.3s ease"
        },
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: "Actions",
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end"
        }
      }
    ) }),
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: reviewParticipants != null ? reviewParticipants : [],
        columnData: tableColumns,
        loading: reviewParticipantsLoading,
        fixedSecondColumn: !!tableColumns.find((c) => c.id === "select")
      }
    ),
    /* @__PURE__ */ jsx(
      ParticipantsDeleteRevieweeModal,
      {
        reviewCycle,
        toDeleteRevieweeIds: selectionModel,
        isOpen: isRevieweeDeleteOpen,
        setIsOpen: setIsRevieweeDeleteOpen,
        onClose: () => {
          setIsRevieweeDeleteOpen(false);
          setSelectionModel([]);
        },
        refresh
      }
    ),
    /* @__PURE__ */ jsx(
      ParticipantsAddReviewerModal,
      {
        reviewCycle,
        selectedReviewee,
        isOpen: isReviewerAddOpen,
        setIsOpen: setIsReviewerAddOpen,
        onClose: () => setIsReviewerAddOpen(false),
        refresh
      }
    ),
    /* @__PURE__ */ jsx(
      ParticipantsRemoveReviewerModal,
      {
        reviewCycle,
        selectedReviewee,
        isOpen: isReviewerDeleteOpen,
        setIsOpen: setIsReviewerDeleteOpen,
        onClose: () => setIsReviewerDeleteOpen(false),
        refresh
      }
    )
  ] });
};
const ActionMenu = ({
  original,
  setIsReviewerAddOpen,
  setSelectedReviewee,
  setIsReviewerDeleteOpen,
  deleteParticipants,
  canEditReviewers,
  canEditParticipants
}) => {
  const getActionsOptions = useCallback(() => {
    const menuOptions = canEditParticipants ? [
      {
        icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          yield deleteParticipants([original.selfId]);
        }),
        label: "Remove participant",
        disabled: false
      }
    ] : [];
    if (canEditReviewers) {
      menuOptions.push({
        icon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
        handler: () => {
          setIsReviewerAddOpen(true);
          setSelectedReviewee(original);
        },
        label: "Add reviewers",
        disabled: false
      });
    }
    if ((original.peerIds && original.peerIds.length > 0 || original.managerIds && original.managerIds.length > 0 || original.upwardIds && original.upwardIds.length > 0) && canEditReviewers) {
      menuOptions.push({
        icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
        handler: () => {
          setIsReviewerDeleteOpen(true);
          setSelectedReviewee(original);
        },
        label: "Remove reviewers",
        disabled: false
      });
    }
    return menuOptions;
  }, [
    setIsReviewerAddOpen,
    setSelectedReviewee,
    setIsReviewerDeleteOpen,
    original,
    deleteParticipants,
    canEditReviewers,
    canEditParticipants
  ]);
  return /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "flex-end", alignItems: "center" }, children: /* @__PURE__ */ jsx(
    StyledMenuComponent,
    {
      options: getActionsOptions(),
      actionButtonDetails: {
        type: "iconButton",
        colorVariant: "secondary",
        sizeVariant: "small",
        title: "actions",
        icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
      }
    }
  ) });
};
