"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import "@/v2/scss/components/progress-bar.scss";
import { spacing } from "@/v2/styles/spacing.styles";
export const ProgressBar = ({
  progress,
  label,
  showRed,
  color,
  styles
}) => {
  const percentage = Math.min(100, Math.max(0, progress));
  const colorClasses = showRed ? "progress-border-red" : "progress-no-border";
  return /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", flexDirection: "column", gap: spacing.g8, width: "100%" }, styles), children: [
    /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", alignItems: "center", minHeight: "6px" }, children: /* @__PURE__ */ jsx("div", { className: `progress-bar ${color != null ? color : colorClasses}`, children: /* @__PURE__ */ jsx(
      "div",
      {
        className: `progress-bar-fill ${color != null ? color : colorClasses}`,
        style: { width: `${percentage}%`, backgroundColor: color }
      }
    ) }) }),
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: label })
  ] });
};
