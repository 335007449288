"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { EditableTitle } from "@v2/components/forms/editable-title.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ReportCopyShareLinkDrawer } from "@v2/feature/reports/reports-advanced/components/report-copy-share-link-drawer.component";
import { ReportDeleteDrawer } from "@v2/feature/reports/reports-advanced/components/report-delete-drawer.component";
import { ReportTable } from "@v2/feature/reports/reports-advanced/components/report-table.component";
import { ReportsAPI } from "@v2/feature/reports/reports.api";
import { exportReportCSV } from "@v2/feature/reports/util/report.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { ReactComponent as Report } from "@/images/side-bar-icons/Report.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE, REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE } from "@/lib/routes";
export const ReportViewResult = ({
  report,
  isLoading,
  reportResponse,
  selectedColumns,
  selectedFilters,
  refreshReportsList,
  refreshReport,
  target
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const history = useHistory();
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const [isCopyDrawerOpen, setIsCopyDrawerOpen] = useState(false);
  const [reportName, setReportName] = useState((_a = report == null ? void 0 : report.name) != null ? _a : "New report");
  const [isSaving, setIsSaving] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const saveNewReport = useCallback(
    (name, columns, filters) => __async(void 0, null, function* () {
      if (!name) {
        showMessage("Please type a valid name before saving the report.", "error");
        return;
      }
      try {
        setIsSaving(true);
        yield ReportsAPI.saveAdvancedReport({ name, columns, filters });
        yield refreshReportsList();
        if (target === "all") history.push(REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE);
        else history.push(REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
      setIsSaving(false);
    }),
    [refreshReportsList, polyglot, showMessage, history, target]
  );
  const updateReport = useCallback(
    (reportId, name, columns, filters) => __async(void 0, null, function* () {
      if (!name) {
        showMessage("Please type a valid name before saving the report.", "error");
        return;
      }
      try {
        setIsSaving(true);
        yield ReportsAPI.updateAdvancedReport(reportId, { name, columns, filters });
        yield refreshReportsList();
        showMessage("Successfully updated report.", "success");
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
      setIsSaving(false);
    }),
    [refreshReportsList, polyglot, showMessage]
  );
  const exportCsv = useCallback(() => __async(void 0, null, function* () {
    if (!reportResponse) return;
    try {
      exportReportCSV(reportName, reportResponse);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  }), [polyglot, reportName, reportResponse, showMessage]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flex: 1,
        overflowY: "auto",
        overflowX: "hidden"
      },
      children: [
        /* @__PURE__ */ jsx(
          TopHeader,
          {
            title: /* @__PURE__ */ jsx(
              EditableTitle,
              {
                variant: "title2",
                value: reportName,
                onChange: (value) => {
                  setReportName(value);
                },
                maxLength: 64
              }
            ),
            showAction: true,
            actions: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
              /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  onClick: () => __async(void 0, null, function* () {
                    setIsRefreshing(true);
                    yield refreshReport();
                    setIsRefreshing(false);
                  }),
                  loading: isRefreshing,
                  children: /* @__PURE__ */ jsx(RefreshIcon, { sx: { width: "18px", height: "18px" } })
                }
              ),
              /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: [
                    {
                      icon: /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
                      handler: exportCsv,
                      label: polyglot.t("General.exportAsCSV")
                    },
                    {
                      icon: /* @__PURE__ */ jsx(Report, __spreadValues({}, iconSize)),
                      handler: () => __async(void 0, null, function* () {
                        yield saveNewReport(reportName, selectedColumns, selectedFilters);
                      }),
                      label: "Save as new report",
                      hidden: !report
                    },
                    {
                      icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
                      handler: () => {
                        setIsCopyDrawerOpen(true);
                      },
                      label: "Share report as URL"
                    },
                    {
                      icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
                      handler: () => {
                        setIsDeleteDrawerOpen(true);
                      },
                      label: polyglot.t("General.delete"),
                      disabled: false,
                      hidden: !report
                    }
                  ],
                  actionButtonDetails: {
                    type: "button",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: polyglot.t("General.actions"),
                    icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                    iconPosition: "end"
                  }
                }
              ),
              /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  colorVariant: "primary",
                  sizeVariant: "small",
                  onClick: () => __async(void 0, null, function* () {
                    if (report) yield updateReport(report.id, reportName, selectedColumns, selectedFilters);
                    else yield saveNewReport(reportName, selectedColumns, selectedFilters);
                  }),
                  loading: isSaving,
                  children: report ? "Update" : "Save"
                }
              )
            ] })
          }
        ),
        /* @__PURE__ */ jsxs(
          ContentWrapper,
          {
            loading: isLoading,
            hideFooter: true,
            loaderSx: { minHeight: "inherit" },
            sx: { paddingBottom: 0, overflow: "hidden" },
            noHorizontalPadding: true,
            border: false,
            children: [
              /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }, children: /* @__PURE__ */ jsx(ReportTable, { reportResponse, stickyHeader: true }) }),
              report && /* @__PURE__ */ jsx(
                ReportDeleteDrawer,
                {
                  isOpen: isDeleteDrawerOpen,
                  setIsOpen: setIsDeleteDrawerOpen,
                  report,
                  refresh: refreshReportsList,
                  target
                }
              ),
              /* @__PURE__ */ jsx(
                ReportCopyShareLinkDrawer,
                {
                  isOpen: isCopyDrawerOpen,
                  setIsOpen: setIsCopyDrawerOpen,
                  selectedColumns,
                  selectedFilters
                }
              )
            ]
          }
        )
      ]
    }
  );
};
