"use strict";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LocalDate } from "@/v2/util/local-date";
dayjs.extend(utc);
export const sortNumeric = (a, b, getValue) => {
  const aValue = getValue(a.original);
  const bValue = getValue(b.original);
  if (typeof aValue === "number" && typeof bValue === "number") {
    return aValue - bValue;
  }
  return typeof aValue === "number" ? 1 : -1;
};
export const sortBoolean = (a, b, getValue, reverseBool = false) => {
  const aValue = getValue(a.original);
  const bValue = getValue(b.original);
  if (aValue === bValue) {
    return 0;
  }
  if (reverseBool) {
    return aValue ? -1 : 1;
  }
  return aValue ? 1 : -1;
};
export const sortString = (a, b, getValue, options) => {
  const aValue = getValue(a.original);
  const bValue = getValue(b.original);
  if (aValue === bValue) {
    return 0;
  }
  if (typeof aValue === "string" && typeof bValue === "string") {
    return bValue.localeCompare(aValue, void 0, options);
  }
  return typeof aValue === "string" ? -1 : 1;
};
export const sortDate = (a, b, getValue) => {
  const aValue = getValue(a.original);
  const bValue = getValue(b.original);
  const aDate = aValue ? new LocalDate(aValue).getDate() : null;
  const bDate = bValue ? new LocalDate(bValue).getDate() : null;
  if (aDate && bDate) {
    return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
  }
  if (aDate) return -1;
  if (bDate) return 1;
  return 0;
};
