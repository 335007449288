"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomProfileFormType } from "@v2/feature/custom-fields/custom-profile-fields.dto";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { capitalize, keyBy } from "lodash";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DietaryRestrictionsSelect } from "@/v2/components/dietary-select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserCustomField } from "@/v2/feature/user/components/user-custom-field.component";
import { useUserFieldsContext } from "@/v2/feature/user/context/user-fields.context";
import { UserAPI, UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { translateCustomField, translateCustomFieldCategory } from "@/v2/infrastructure/i18n/translate.util";
import { spacing } from "@/v2/styles/spacing.styles";
const UserAboutSchema = (defaultFieldsMap, polyglot) => Yup.object().shape({
  about: defaultFieldsMap["about"] && defaultFieldsMap["about"].isRequired ? Yup.string().nullable().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired(),
  hobbies: defaultFieldsMap["hobbies"] && defaultFieldsMap["hobbies"].isRequired ? Yup.string().nullable().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired(),
  social: defaultFieldsMap["social"] && defaultFieldsMap["social"].isRequired ? Yup.string().nullable().matches(/http[s]:\/\/\S+/, polyglot.t("OnboardingByUser.validations.validProfileURL")).required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired(),
  foodPreferences: defaultFieldsMap["foodPreferences"] && defaultFieldsMap["foodPreferences"].isRequired ? Yup.string().nullable().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired(),
  dietaryRestrictions: defaultFieldsMap["dietaryRestrictions"] && defaultFieldsMap["dietaryRestrictions"].isRequired ? Yup.string().nullable().required(polyglot.t("OnboardingByUser.validations.requiredField")) : Yup.string().notRequired()
});
export const UserAboutSection = ({ onNext, sx, userId }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const { polyglot } = usePolyglot();
  const { data: aboutInfo } = useApiClient(UserEndpoints.getUserAboutByUserid(userId), {
    suspense: false
  });
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedDefaultFieldsByFormName, getCachedCustomFieldsByFormName } = useUserFieldsContext();
  const defaultFields = getCachedDefaultFieldsByFormName(CustomProfileFormType.About);
  const customFields = getCachedCustomFieldsByFormName(CustomProfileFormType.About);
  const defaultFieldsMap = useMemo(() => {
    return keyBy(defaultFields, "fieldStub");
  }, [defaultFields]);
  const customFieldIds = useMemo(() => new Set((customFields != null ? customFields : []).map((field) => field.fieldId)), [customFields]);
  const initialValues = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2;
    const values = {};
    if (defaultFieldsMap["about"]) values.about = (_a2 = aboutInfo == null ? void 0 : aboutInfo.about) != null ? _a2 : "";
    if (defaultFieldsMap["hobbies"]) values.hobbies = (_b2 = aboutInfo == null ? void 0 : aboutInfo.hobbies) != null ? _b2 : "";
    if (defaultFieldsMap["social"]) values.social = (_c2 = aboutInfo == null ? void 0 : aboutInfo.social) != null ? _c2 : "";
    if (defaultFieldsMap["foodPreferences"]) values.foodPreferences = (_d2 = aboutInfo == null ? void 0 : aboutInfo.foodPreferences) != null ? _d2 : "";
    if (defaultFieldsMap["dietaryRestrictions"]) values.dietaryRestrictions = (_e2 = aboutInfo == null ? void 0 : aboutInfo.dietaryRestrictions) != null ? _e2 : "";
    values.customUpdates = (_g2 = (_f2 = aboutInfo == null ? void 0 : aboutInfo.customUpdates) == null ? void 0 : _f2.filter((update) => customFieldIds.has(update.fieldId))) != null ? _g2 : [];
    return values;
  }, [defaultFieldsMap, aboutInfo, customFieldIds]);
  const formik = useFormik({
    initialValues,
    validationSchema: UserAboutSchema(defaultFieldsMap, polyglot),
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        yield UserAPI.patchUserAboutInfo(userId, values);
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.emergencyContactNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: (_a = capitalize(translateCustomFieldCategory(CustomProfileFormType.About, polyglot))) != null ? _a : polyglot.t("OnboardingByUser.aboutYou") }),
    defaultFieldsMap["about"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "about",
        label: (_b = translateCustomField(defaultFieldsMap["about"].fieldName, polyglot)) != null ? _b : polyglot.t("OnboardingByUser.tellUsAboutYou"),
        value: formik.values.about,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("about", ""),
        helperText: hasSubmitted && formik.errors.about,
        error: hasSubmitted && !!formik.errors.about,
        autoFocus: true,
        required: (_c = defaultFieldsMap["about"]) == null ? void 0 : _c.isRequired
      }
    ),
    defaultFieldsMap["hobbies"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "hobbies",
        label: (_d = translateCustomField(defaultFieldsMap["hobbies"].fieldName, polyglot)) != null ? _d : polyglot.t("OnboardingByUser.hobbies"),
        value: formik.values.hobbies,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("hobbies", ""),
        helperText: hasSubmitted && formik.errors.hobbies,
        error: hasSubmitted && !!formik.errors.hobbies,
        required: (_e = defaultFieldsMap["hobbies"]) == null ? void 0 : _e.isRequired
      }
    ),
    defaultFieldsMap["social"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "social",
        label: (_f = translateCustomField(defaultFieldsMap["social"].fieldName, polyglot)) != null ? _f : polyglot.t("OnboardingByUser.LinkedIn"),
        value: formik.values.social,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("social", ""),
        helperText: hasSubmitted && formik.errors.social,
        error: hasSubmitted && !!formik.errors.social,
        required: (_g = defaultFieldsMap["social"]) == null ? void 0 : _g.isRequired
      }
    ),
    defaultFieldsMap["foodPreferences"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "foodPreferences",
        label: (_h = translateCustomField(defaultFieldsMap["foodPreferences"].fieldName, polyglot)) != null ? _h : polyglot.t("OnboardingByUser.favouriteFoods"),
        value: formik.values.foodPreferences,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("foodPreferences", ""),
        helperText: hasSubmitted && formik.errors.foodPreferences,
        error: hasSubmitted && !!formik.errors.foodPreferences,
        required: (_i = defaultFieldsMap["foodPreferences"]) == null ? void 0 : _i.isRequired
      }
    ),
    defaultFieldsMap["dietaryRestrictions"] && /* @__PURE__ */ jsx(
      DietaryRestrictionsSelect,
      {
        name: "dietaryRestrictions",
        value: formik.values.dietaryRestrictions,
        disabled: saving,
        onChange: (_, value) => formik.setFieldValue("dietaryRestrictions", value),
        required: (_j = defaultFieldsMap["dietaryRestrictions"]) == null ? void 0 : _j.isRequired,
        hidePlaceholder: true
      }
    ),
    /* @__PURE__ */ jsx(
      UserCustomField,
      {
        values: (_k = formik.values.customUpdates) != null ? _k : [],
        formFields: customFields != null ? customFields : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        sizeVariant: "large",
        colorVariant: "primary",
        loading: saving,
        fullWidth: true
      }
    )
  ] }) }) });
};
