"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class PeopleAnalyticsAPI {
  static getAverageTurnoverRate() {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/reports-analytics/people/turnover-rate-avg`)).data;
    });
  }
  static getTurnoverRateOverTime() {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/reports-analytics/people/turnover-rate-over-time`)).data;
    });
  }
  static getAverageAttritionRate() {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/reports-analytics/people/attrition-rate-avg`)).data;
    });
  }
  static getAttritionRateOverTime() {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/reports-analytics/people/attrition-rate-over-time`)).data;
    });
  }
  static getAverageRetentionRate() {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/reports-analytics/people/retention-rate-avg`)).data;
    });
  }
  static getRetentionRateOverTime() {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/reports-analytics/people/retention-rate-over-time`)).data;
    });
  }
  static getAverageTenure() {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/reports-analytics/people/tenure-avg`)).data;
    });
  }
  static getWorkforceMetricsOverTime() {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/reports-analytics/people/workforce-metrics-over-time`)).data;
    });
  }
  static getCacheCreationDate() {
    return __async(this, null, function* () {
      return yield (yield axios.get(`/apiv2/reports-analytics/people/cache-creation-date`)).data;
    });
  }
  static refreshCache() {
    return __async(this, null, function* () {
      return yield axios.patch(`/apiv2/reports-analytics/people/cache-refresh`);
    });
  }
}
