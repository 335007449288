"use strict";
import { useMemo } from "react";
import { isHTMLValid } from "@/v2/feature/growth/shared/utils/growth-common.util";
export const useProgress = (questions, answerArray) => {
  return useMemo(() => {
    if (!questions || !answerArray) return { progress: 0, answeredRequired: 0, totalRequired: 0 };
    const totalRequiredQuestions = questions.length;
    const totalCommentRequiredQuestions = questions.filter((q) => q.isCommentRequired).length;
    const answeredRequiredQuestions = questions.filter((q) => {
      var _a, _b;
      const answer = answerArray.find((a) => a.questionId === q.id);
      if (!answer) return false;
      if (q.type === "openEnded") {
        return answer && answer.comment && isHTMLValid(answer.comment);
      } else {
        return answer && answer.answer && ((_b = (_a = answer.answer) == null ? void 0 : _a.trim()) == null ? void 0 : _b.length) > 0;
      }
    }).length;
    const validComments = questions.filter((q) => {
      if (!q.isCommentRequired) return false;
      const answer = answerArray.find((a) => a.questionId === q.id);
      return answer && answer.comment && isHTMLValid(answer.comment);
    }).length;
    const totalAnswered = answeredRequiredQuestions + validComments;
    const grandTotal = totalRequiredQuestions + totalCommentRequiredQuestions;
    return {
      progress: grandTotal > 0 ? Math.round(totalAnswered / grandTotal * 100) : 100,
      answeredRequired: answeredRequiredQuestions,
      totalRequired: totalRequiredQuestions,
      validComments,
      totalCommentsRequired: totalCommentRequiredQuestions
    };
  }, [questions, answerArray]);
};
