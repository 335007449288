"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { ChartBar } from "@/v2/components/charts/components/chart-bar.component";
import { ChartLine } from "@/v2/components/charts/components/chart-line.component";
import { MixedChart } from "@/v2/components/charts/components/chart-mixed.component";
import { ChartPolarArea } from "@/v2/components/charts/components/chart-polar-area.component";
import { ChartRadial } from "@/v2/components/charts/components/chart-radial.component";
class Chart {
}
class LineChart extends Chart {
  render(data, options) {
    const chartOptions = __spreadProps(__spreadValues({}, options), {
      yAxisOptions: {
        decimalsInFloat: data.decimalsInFloat
      }
    });
    return /* @__PURE__ */ jsx(
      ChartLine,
      {
        series: data.series,
        categories: data.categories,
        categoriesType: data.categoriesType,
        options: chartOptions
      }
    );
  }
}
class BarChart extends Chart {
  render(data, options) {
    const chartOptions = __spreadProps(__spreadValues({}, options), {
      yAxisOptions: {
        decimalsInFloat: data.decimalsInFloat
      }
    });
    return /* @__PURE__ */ jsx(
      ChartBar,
      {
        series: data.series,
        categories: data.categories,
        categoriesType: data.categoriesType,
        options: chartOptions
      }
    );
  }
}
class MixedChartClass extends Chart {
  render(data, options) {
    const chartOptions = __spreadValues({}, options);
    return /* @__PURE__ */ jsx(
      MixedChart,
      {
        series: data.series,
        categories: data.categories,
        categoriesType: data.categoriesType,
        yAxis: data.yAxis,
        options: chartOptions
      }
    );
  }
}
class RadialChart extends Chart {
  render(data, options) {
    return /* @__PURE__ */ jsx(ChartRadial, { series: data.series, categories: data.categories, options });
  }
}
class PolarAreaChart extends Chart {
  render(data, options) {
    return /* @__PURE__ */ jsx(ChartPolarArea, { series: data.series, categories: data.categories, options });
  }
}
export class ChartFactory {
  static createChart(type) {
    switch (type) {
      case "line":
        return new LineChart();
      case "bar":
        return new BarChart();
      case "mixed":
        return new MixedChartClass();
      case "radial":
        return new RadialChart();
      case "polarArea":
        return new PolarAreaChart();
      default:
        throw new Error(`Unknown chart type: ${type}`);
    }
  }
}
