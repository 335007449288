"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
import "@/v2/components/charts/styles/apex-charts.styles.css";
export const ChartBoxPlot = ({
  series,
  categories,
  customTooltip
}) => {
  const { polyglot } = usePolyglot();
  const seriesData = [{ data: series }];
  const chartOptions = {
    chart: {
      toolbar: {
        show: false
      }
    },
    title: {
      align: "left"
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: "#FFCD6D",
          lower: "#74CF8C"
        },
        stroke: {
          show: true,
          width: 0
        }
      }
    },
    xaxis: {
      labels: {
        show: categories.length > 0,
        style: {
          colors: Array(categories.length).fill(themeColors.Grey),
          fontFamily: "Inter, sans-serif !important",
          fontWeight: 400,
          fontSize: "12px"
        }
      },
      min: 0,
      crosshairs: {
        show: false
      }
    },
    yaxis: {
      labels: {
        align: "center",
        style: {
          fontFamily: "Inter, sans-serif !important",
          fontWeight: 400,
          fontSize: "12px"
        },
        formatter: function(val) {
          return val ? formatCurrency(val, { wholeNumber: true }) : "";
        }
      },
      legend: {
        show: true,
        horizontalAlign: "right",
        position: "top",
        fontFamily: "Inter, sans-serif !important",
        fontWeight: 400,
        fontSize: "12px",
        labels: { colors: [themeColors.DarkGrey] },
        markers: {
          width: 10,
          height: 10,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: void 0,
          radius: 16,
          customHTML: void 0,
          onClick: void 0
        }
      },
      stroke: {
        show: true,
        width: 0,
        colors: ["transparent"]
      },
      crosshairs: {
        show: false
      }
    },
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: false
      },
      y: {
        formatter: (val) => `${formatCurrency(val, { wholeNumber: true })}`
      },
      style: { fontFamily: "Inter, sans-serif !important", fontWeight: 400, fontSize: "12px" },
      custom: customTooltip ? customTooltip : function({ series: series2, seriesIndex, dataPointIndex }) {
        return `<div class="arrow_box">${series2[seriesIndex][dataPointIndex]}</div>`;
      }
    },
    noData: { text: polyglot.t("Charts.noData") }
  };
  return /* @__PURE__ */ jsx(ReactApexChart, { type: "boxPlot", series: seriesData, options: chartOptions, height: "250px" });
};
export const ChartBoxPlotLoading = () => {
  return /* @__PURE__ */ jsx(Box, { sx: { height: "250px", width: "100%" }, children: [1, 2, 3, 4, 5].map(() => /* @__PURE__ */ jsx(
    SkeletonLoader,
    {
      variant: "rectangular",
      width: "90%",
      height: "10px",
      sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
    }
  )) });
};
