"use strict";
export const isDefined = (value) => value !== void 0 && value !== null && value !== "";
export function filterUserOnboardingDocuments(documents) {
  return documents.filter((d) => !d.contractId);
}
export function filterPendingOnboardingContracts(documents) {
  return documents.filter((d) => d.contractId);
}
export function areAllValuesNull(values) {
  const checkValue = (value) => {
    if (Array.isArray(value)) {
      return value.every(checkValue);
    } else if (value !== null && typeof value === "object") {
      return Object.values(value).every(checkValue);
    } else {
      return value === null || value === void 0 || value === "";
    }
  };
  return Object.values(values).every(checkValue);
}
