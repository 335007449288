"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box, FormControl } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import {
  KeyResultOwnerType,
  keyResultOwnerTypeOptions,
  KeyResultProgressType,
  keyResultProgressTypeOptions,
  KeyResultStatus,
  keyResultStatusOptions
} from "./key-result.interface";
import { KeyResultAPI } from "./key-results.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldNumberComponent } from "@/v2/components/forms/textfield-number.component";
import { MultiUserSelect } from "@/v2/components/forms/user-select/multi-user-select.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const KeyResultMutationModal = ({
  keyResult,
  goalId,
  isOpen,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(KeyResultCreationModalContent, { goalId, keyResult, onClose, refresh }) });
};
const KeyResultCreationModalContent = ({
  goalId,
  keyResult,
  onClose,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { cachedUsers } = useCachedUsers({ refresh: true });
  const formik = useFormik({
    initialValues: {
      name: (_a = keyResult == null ? void 0 : keyResult.name) != null ? _a : "",
      description: (_b = keyResult == null ? void 0 : keyResult.description) != null ? _b : "",
      dueDate: (_c = keyResult == null ? void 0 : keyResult.dueDate) != null ? _c : null,
      ownerType: (_d = keyResult == null ? void 0 : keyResult.ownerType) != null ? _d : KeyResultOwnerType.Individual,
      ownerIds: (_e = keyResult == null ? void 0 : keyResult.ownerIds) != null ? _e : [],
      factorIds: (_f = keyResult == null ? void 0 : keyResult.factorIds) != null ? _f : [],
      progressType: (_g = keyResult == null ? void 0 : keyResult.progressType) != null ? _g : KeyResultProgressType.Percentage,
      progressTarget: (_h = keyResult == null ? void 0 : keyResult.progressTarget) != null ? _h : 0,
      status: (_i = keyResult == null ? void 0 : keyResult.status) != null ? _i : KeyResultStatus.Planned
    },
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t("NewKeyResultDrawer.errorMessages.nameRequired")),
      description: yup.string().notRequired(),
      dueDate: yup.string().notRequired().default(null),
      ownerType: yup.string().required(polyglot.t("NewKeyResultDrawer.errorMessages.ownerTypeRequired")),
      ownerIds: yup.array().required(polyglot.t("NewKeyResultDrawer.errorMessages.ownerTypeRequired")),
      status: yup.string().notRequired()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        yield KeyResultAPI.createKeyResult(goalId, __spreadValues({}, values));
        yield refresh();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  const selectableUsers = useMemo(() => {
    return cachedUsers.map((u) => __spreadValues({ value: u.userId, label: u.displayName }, u));
  }, [cachedUsers]);
  const multiUserSelectValue = useMemo(() => {
    return formik.values.ownerIds.map((id) => {
      var _a2;
      const user = cachedUsers.find((u) => u.userId === id);
      return { value: id, label: (_a2 = user == null ? void 0 : user.displayName) != null ? _a2 : "" };
    });
  }, [cachedUsers, formik.values.ownerIds]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("NewKeyResultDrawer.newKeyResult") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("NewKeyResultDrawer.formFields.name"),
        value: formik.values.name,
        onChange: formik.handleChange,
        required: true,
        error: formik.touched.name && !!formik.errors.name,
        helperText: formik.touched.name && formik.errors.name,
        endAdornment: "none",
        fullWidth: true
      }
    ),
    /* @__PURE__ */ jsx(
      RichTextField,
      {
        label: polyglot.t("NewKeyResultDrawer.formFields.description"),
        value: (_j = formik.values.description) != null ? _j : "",
        onChange: (value) => formik.setFieldValue("description", value),
        error: formik.touched.description && !!formik.errors.description,
        helperText: formik.touched.description && formik.errors.description
      }
    ),
    /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_k = formik.values.dueDate) != null ? _k : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("dueDate", value);
          }
        },
        name: "dueDate",
        required: false,
        label: polyglot.t("NewKeyResultDrawer.formFields.dueDate"),
        error: !!formik.errors.dueDate && formik.touched.dueDate,
        helperText: formik.errors.dueDate && formik.touched.dueDate
      }
    ) }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "ownerType",
        label: polyglot.t("NewKeyResultDrawer.formFields.keyResultBelongsTo"),
        options: keyResultOwnerTypeOptions,
        value: formik.values.ownerType,
        onChange: formik.handleChange,
        required: true,
        compareValue: formik.values.ownerType,
        error: !!formik.errors.ownerType && formik.touched.ownerType,
        helperText: formik.touched.ownerType && formik.errors.ownerType
      }
    ),
    /* @__PURE__ */ jsx(
      MultiUserSelect,
      {
        id: "multiownership",
        limitTags: 5,
        options: selectableUsers,
        value: multiUserSelectValue,
        onChange: (_, values) => {
          formik.setFieldValue(
            "ownerIds",
            values.map((v) => Number(v.value))
          );
        },
        isOptionEqualToValue: (x, y) => x.value === y.value,
        getOptionLabel: ({ label }) => label,
        label: polyglot.t("DocumentFormModal.belongs")
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "progressType",
        label: polyglot.t("NewKeyResultDrawer.formFields.progressMeasure"),
        options: keyResultProgressTypeOptions,
        value: formik.values.progressType,
        onChange: formik.handleChange,
        compareValue: formik.values.progressType,
        error: !!formik.errors.progressType && formik.touched.progressType,
        helperText: formik.touched.progressType && formik.errors.progressType
      }
    ),
    formik.values.progressType === KeyResultProgressType.Number ? /* @__PURE__ */ jsx(
      TextfieldNumberComponent,
      {
        name: "progressTarget",
        label: polyglot.t("NewKeyResultDrawer.formFields.progressTarget"),
        value: formik.values.progressTarget,
        onChange: formik.handleChange,
        required: true,
        error: formik.touched.progressTarget && !!formik.errors.progressTarget,
        helperText: formik.touched.progressTarget && formik.errors.progressTarget,
        endAdornment: "none",
        fullWidth: true
      }
    ) : null,
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "status",
        label: polyglot.t("NewKeyResultDrawer.formFields.status"),
        options: keyResultStatusOptions,
        value: formik.values.status,
        onChange: formik.handleChange,
        compareValue: formik.values.status,
        error: !!formik.errors.status && formik.touched.status,
        helperText: formik.touched.status && formik.errors.status
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("NewKeyResultDrawer.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
