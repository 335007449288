"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  REPORTS_COMPANY_INSIGHTS_ROUTE,
  REPORTS_COMPANY_PEOPLE_ANALYTICS_ROUTE,
  REPORTS_COMPANY_SALARY_ANALYTICS_ROUTE
} from "@/lib/routes";
import { PayrollAnalyticsPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-analytics/payroll-analytics.page";
import { PeopleAnalyticsPage } from "@/v2/feature/reports-analytics/features/people-analytics/people-analytics.page";
const AnalyticsInsightsPeopleScopes = ["company.settings:all"];
export const AnalyticsCompanyRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext, hasScopesAccess } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const hasCompanySettingsScope = hasScopesAccess(AnalyticsInsightsPeopleScopes);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context: scopesContext,
        scopes: AnalyticsInsightsPeopleScopes,
        path: REPORTS_COMPANY_PEOPLE_ANALYTICS_ROUTE,
        component: PeopleAnalyticsPage
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context: scopesContext,
        path: REPORTS_COMPANY_SALARY_ANALYTICS_ROUTE,
        scopes: ["payroll:all"],
        children: /* @__PURE__ */ jsx(PayrollAnalyticsPage, {})
      }
    ),
    /* @__PURE__ */ jsx(
      Redirect,
      {
        from: REPORTS_COMPANY_INSIGHTS_ROUTE,
        to: hasCompanySettingsScope ? REPORTS_COMPANY_PEOPLE_ANALYTICS_ROUTE : REPORTS_COMPANY_SALARY_ANALYTICS_ROUTE
      }
    )
  ] });
};
