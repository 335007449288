"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Link, Stack } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { drawerContentSx, fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { ReactComponent as ZincIcon } from "@/images/side-bar-icons/zinc.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { IDCheckAPI } from "@/v2/feature/id-check/id-check.api";
import {
  customIdCheckPackages
} from "@/v2/feature/id-check/id-check.interface";
import {
  ZINC_CHECKS_LINK,
  ZINC_TERMS_LINK
} from "@/v2/feature/onboarding/onboarding-items/id-verify/edit-id-verify.component";
import { OnboardingEndpoints } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { underlinedLinkLight } from "@/v2/styles/buttons.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatAsGBPCurrency } from "@/v2/util/string-format.util";
export const NewIdCheckPackageDrawer = ({
  isOpen,
  setIsOpen,
  currentUser,
  idCheckPackage,
  individualChecks,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const { data: packageCurrentlyUsedInOnboarding } = useApiClient(
    (idCheckPackage == null ? void 0 : idCheckPackage.id) ? OnboardingEndpoints.isIdCheckPackageCurrentlyUsedInOnboarding(idCheckPackage.id) : { url: void 0 },
    {
      suspense: false
    }
  );
  const formik = useFormik({
    initialValues: {
      name: idCheckPackage ? idCheckPackage.name : "",
      price: idCheckPackage ? idCheckPackage.price : 0
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t("IdCheckPackage.errorMessages.missingName")).min(2, polyglot.t("IdCheckPackage.errorMessages.missingName")),
      packageDetails: yup.object().required().required(polyglot.t("IdCheckPackage.errorMessages.missingPackageDetails"))
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      const { name, packageDetails } = values;
      if (!name || !packageDetails) {
        showMessage("Please fill in all fields", "error");
        setLoading(false);
        return;
      }
      try {
        if (idCheckPackage == null ? void 0 : idCheckPackage.id) {
          yield IDCheckAPI.updatePackage(idCheckPackage.id, { name, packageDetails });
          showMessage("Successfully updated Id check package", "success");
        } else {
          yield IDCheckAPI.createPackage({ name, packageDetails });
          showMessage("Successfully created new Id check package", "success");
        }
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Encountered an error while trying to save package: ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  const deletePackage = useCallback(() => __async(void 0, null, function* () {
    if (!(idCheckPackage == null ? void 0 : idCheckPackage.id)) return;
    try {
      setLoading(true);
      if (idCheckPackage) yield IDCheckAPI.deletePackageById(idCheckPackage.id);
      showMessage("Successfully deleted Id check package", "success");
      yield refresh();
      setIsOpen(false);
      setIsRemovalModalOpen(false);
    } catch (error) {
      showMessage(`Could not delete Id check package. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [idCheckPackage, refresh, setIsOpen, showMessage]);
  const isDefaultIdCheckPackage = useMemo(() => {
    if (idCheckPackage && !idCheckPackage.companyId && !idCheckPackage.createdBy) return true;
    return false;
  }, [idCheckPackage]);
  const MemoizedCheckBoxesForIndividualChecks = ({
    usesCustomPackage: usesCustomPackage2,
    individualChecks: individualChecks2,
    polyglot: polyglot2
  }) => {
    var _a2;
    const [packageDetailsSet, setPackageDetailsSet] = useState(
      new Set(Object.keys((_a2 = idCheckPackage == null ? void 0 : idCheckPackage.packageDetails) != null ? _a2 : []).map((eachCheck) => eachCheck))
    );
    const checkBoxesForIndividualChecks = useMemo(() => {
      return individualChecks2.sort((a, b) => a.id.localeCompare(b.id)).filter((eachCheck) => {
        return !(eachCheck.id in customIdCheckPackages);
      }).map((eachCheck) => {
        const eachLabelFromId = polyglot2.t(`IdCheckPackage.id.${eachCheck.id}`);
        const labelWithPrice = `${eachLabelFromId} (${formatAsGBPCurrency(eachCheck.price, 2)})`;
        const isChecked = packageDetailsSet.has(eachCheck.id);
        const handleCheckboxChange = () => {
          setPackageDetailsSet((prevSet) => {
            const newSet = new Set(prevSet);
            if (prevSet.has(eachCheck.id)) {
              newSet.delete(eachCheck.id);
            } else {
              newSet.add(eachCheck.id);
            }
            return newSet;
          });
        };
        return /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            sx: { mb: spacing.mb15 },
            label: labelWithPrice,
            value: eachCheck.id,
            name: eachCheck.id,
            checked: isChecked,
            disabled: isDefaultIdCheckPackage || usesCustomPackage2,
            onChange: handleCheckboxChange
          }
        ) }, eachCheck.id);
      });
    }, [individualChecks2, packageDetailsSet, polyglot2, usesCustomPackage2]);
    const convertSetToObject = useMemo(() => {
      return [...packageDetailsSet].reduce((acc, eachCheck) => {
        acc[eachCheck] = { enabled: true };
        return acc;
      }, {});
    }, [packageDetailsSet]);
    useEffect(() => {
      let price = 0;
      individualChecks2.forEach((eachCheck) => {
        if (packageDetailsSet.has(eachCheck.id)) {
          price += eachCheck.price;
        }
      });
      formik.setFieldValue("price", price);
    }, [individualChecks2, packageDetailsSet]);
    useEffect(() => {
      formik.setFieldValue("packageDetails", convertSetToObject);
    }, [convertSetToObject, packageDetailsSet]);
    return /* @__PURE__ */ jsx(Fragment, { children: checkBoxesForIndividualChecks });
  };
  const usesCustomPackage = (idCheckPackage == null ? void 0 : idCheckPackage.packageDetails) && ((_a = Object.keys(idCheckPackage.packageDetails)) == null ? void 0 : _a.length) > 0 ? Object.keys(idCheckPackage.packageDetails).includes("customPackage") : false;
  const anyPackagesSelected = formik.values.packageDetails ? Object.keys(formik.values.packageDetails).length > 0 : false;
  const excludingVatText = ((_b = formik == null ? void 0 : formik.values) == null ? void 0 : _b.price) && ((_c = formik == null ? void 0 : formik.values) == null ? void 0 : _c.price) >= 0 ? ` (${polyglot.t("IdCheckPackage.excludingVAT")})` : "";
  const PACKAGE_IN_USE_CONFIRMATION_MESSAGE = useMemo(() => {
    const defaultMessage = polyglot.t("IdCheckPackageConfirmRemove.normalConfirmMessage");
    if (!packageCurrentlyUsedInOnboarding) {
      return defaultMessage;
    } else if (packageCurrentlyUsedInOnboarding) {
      return polyglot.t("IdCheckPackageConfirmRemove.packageUsedInOnboardingConfirmMessage");
    }
    return defaultMessage;
  }, [packageCurrentlyUsedInOnboarding, polyglot]);
  return /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
    /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t(
          !idCheckPackage ? "IdCheckPackage.newPackage" : isDefaultIdCheckPackage ? "IdCheckPackage.existingPackage" : "IdCheckPackage.editPackage"
        ) }),
        !isDefaultIdCheckPackage && !usesCustomPackage && idCheckPackage && /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.onboard:all"], context: { userId: currentUser == null ? void 0 : currentUser.userId }, children: /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => setIsRemovalModalOpen(true), children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) }) })
      ] }),
      idCheckPackage ? /* @__PURE__ */ jsx(Typography, { variant: "title3", children: idCheckPackage == null ? void 0 : idCheckPackage.name }) : /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "name",
          label: polyglot.t("IdCheckPackage.name"),
          value: formik.values.name,
          onChange: formik.handleChange,
          error: formik.touched.name && !!formik.errors.name,
          helperText: (_d = formik.touched.name && formik.errors.name) != null ? _d : " "
        }
      ) }),
      /* @__PURE__ */ jsxs(Box, { sx: fieldSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t(!idCheckPackage ? "IdCheckPackage.selectChecks" : "IdCheckPackage.existingChecks") }),
        /* @__PURE__ */ jsxs(Typography, { variant: "caption", sx: { mt: spacing.mt10 }, children: [
          polyglot.t("EditIdVerify.poweredBy"),
          " ",
          /* @__PURE__ */ jsx(ZincIcon, __spreadValues({}, iconSize)),
          " ",
          polyglot.t("EditIdVerify.zincMex")
        ] }),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              mt: spacing.mt20,
              maxHeight: "400px",
              overflowY: "auto"
            },
            children: MemoizedCheckBoxesForIndividualChecks({
              individualChecks,
              usesCustomPackage,
              polyglot
            })
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxDrawerSx), { mt: spacing.mt30 }), children: /* @__PURE__ */ jsxs(Stack, { direction: "column", children: [
        /* @__PURE__ */ jsxs(Typography, { variant: "title3", children: [
          polyglot.t("IdCheckPackage.totalPrice"),
          ":",
          " ",
          usesCustomPackage && idCheckPackage ? formatAsGBPCurrency(idCheckPackage.price, 2) : formatAsGBPCurrency(
            ((_e = formik == null ? void 0 : formik.values) == null ? void 0 : _e.price) && ((_f = formik == null ? void 0 : formik.values) == null ? void 0 : _f.price) >= 0 ? formik.values.price : 0,
            2
          ),
          excludingVatText
        ] }),
        /* @__PURE__ */ jsxs(Stack, { direction: "row", sx: { gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(Link, { sx: __spreadValues({}, underlinedLinkLight), target: "_blank", href: ZINC_TERMS_LINK, children: polyglot.t("EditIdVerify.zinc") }),
          /* @__PURE__ */ jsx(Link, { sx: __spreadValues({}, underlinedLinkLight), target: "_blank", href: ZINC_CHECKS_LINK, children: polyglot.t("EditIdVerify.zincChecksInfo") })
        ] })
      ] }) }),
      !isDefaultIdCheckPackage && /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          type: "submit",
          sizeVariant: "medium",
          colorVariant: "primary",
          onClick: () => formik.handleSubmit(),
          disabled: loading || !formik.isValid || !anyPackagesSelected || usesCustomPackage,
          fullWidth: true,
          children: polyglot.t("General.save")
        }
      ) })
    ] }) }),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("IdCheckPackageConfirmRemove.title"),
        description: PACKAGE_IN_USE_CONFIRMATION_MESSAGE,
        isOpen: isRemovalModalOpen,
        setIsOpen: setIsRemovalModalOpen,
        loading,
        action: deletePackage
      }
    )
  ] });
};
