"use strict";
import { PaySchedules, SalaryBasis } from "@/lib/employment";
import * as Yup from "yup";
import {
  UserContractKinds,
  UserContractTypes
} from "@/component/dashboard/userDetails/validations/userFormDefinitions";
import { dateFieldTest } from "@/v2/infrastructure/date/date-format.util";
import {
  COUNTRY_ISO_CODE_MAPPING,
  CustomCountryEnum,
  PublicHolidayCountryCodes
} from "@/v2/infrastructure/country/country.interface";
import { SalaryBasisEnum } from "@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto";
export const getAccountNumberValidation = (polyglot) => Yup.string().matches(/^[0-9a-zA-Z-]*$/, polyglot.t("UserBankInfoForm.schema.onlyLettersAndNumbers")).when("currency", {
  is: (currency) => currency === "GBP",
  then: (schema) => (
    // either an 8-digit UK bank account number or a 15-34 digit IBAN
    schema.matches(/^[0-9]{8}$|^[a-zA-Z0-9]{15,34}$/, polyglot.t("UserBankInfoForm.schema.invalidAccountNumber")).required(
      polyglot.t("UserBankInfoForm.schema.requiredField", {
        field: polyglot.t("UserBankInfoForm.accountNumber")
      })
    )
  ),
  otherwise: (schema) => schema.required(
    polyglot.t("UserBankInfoForm.schema.requiredField", { field: polyglot.t("UserBankInfoForm.accountNumber") })
  )
});
export const getSortCodeValidation = (polyglot) => Yup.string().matches(/^[0-9a-zA-Z-]*$/, polyglot.t("UserBankInfoForm.schema.onlyLettersAndNumbers")).matches(/^\S*$/, polyglot.t("UserBankInfoForm.schema.onlyLettersAndNumbers")).when("currency", {
  // if accountNumber is a numeric value (UK bank account number), the sort code must be a 6-digit value
  is: (currency) => currency === "GBP",
  then: (schema) => schema.when("accountNumber", {
    // if accountNumber is a numeric value (UK bank account number), the sort code must be a 6-digit value
    is: (value) => /^[0-9]{8}$/.test(value),
    then: (schema2) => schema2.matches(/^[0-9]{6}$/, polyglot.t("UserBankInfoForm.schema.invalidSortCode")).matches(/^\S*$/, polyglot.t("UserBankInfoForm.schema.invalidSortCode"))
  }).when("accountNumber", {
    // if accountNumber is alphanumeric (IBAN), the sort code must be a BIC value
    is: (value) => /[a-zA-Z]+/.test(value),
    // if value contains letters
    then: (schema2) => schema2.matches(/^[a-zA-Z]{6}[a-zA-Z0-9]{2,5}$/, polyglot.t("UserBankInfoForm.schema.invalidSortCode")).matches(/^\S*$/, polyglot.t("UserBankInfoForm.schema.invalidSortCode"))
  })
}).required(polyglot.t("UserBankInfoForm.schema.requiredField", { field: polyglot.t("UserBankInfoForm.sortCode") }));
export const UserEquitySchema = Yup.object().shape({
  grantDate: Yup.string().required("Required").test(dateFieldTest),
  type: Yup.string().required("Required"),
  amount: Yup.number().min(1, "At least one equity unit is required for a grant"),
  vestingStart: Yup.date().nullable().notRequired().typeError("Invalid date format."),
  vestingPeriod: Yup.number().nullable().notRequired(),
  vestingCliff: Yup.number().nullable().notRequired(),
  unitPrice: Yup.number().nullable().notRequired(),
  currency: Yup.string().nullable().notRequired(),
  reason: Yup.string().nullable().notRequired()
});
export const UserEquitySchemaForImport = Yup.object().shape({
  grantDate: Yup.string().test(dateFieldTest).nullable().notRequired(),
  equityType: Yup.string().nullable().notRequired(),
  amount: Yup.number().min(1, "At least one equity unit is required for a grant").nullable().notRequired(),
  vestingStart: Yup.date().nullable().notRequired().typeError("Invalid date format."),
  vestingPeriod: Yup.number().nullable().notRequired(),
  vestingCliff: Yup.number().nullable().notRequired(),
  unitPrice: Yup.number().nullable().notRequired(),
  equityCurrency: Yup.string().nullable().notRequired(),
  reason: Yup.string().nullable().notRequired()
});
export const UserEventSchema = Yup.object().shape({
  effectiveDate: Yup.string().test(dateFieldTest).required("Required"),
  changeReason: Yup.string().required("Required"),
  effectiveEndDate: Yup.string().test(dateFieldTest).nullable().notRequired()
});
export const UserLifecycleSchemaForImport = Yup.object().shape({
  startDate: Yup.string().test(dateFieldTest).nullable().when("leaveDate", {
    is: (val) => Boolean(val),
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required("Set at least one of the start date and leave date")
  }).nullable(),
  leaveDate: Yup.string().test(dateFieldTest).nullable(),
  changeReason: Yup.string().notRequired()
});
export const getUserCompensationSchema = (polyglot) => Yup.object().shape({
  effectiveDate: Yup.string().test(dateFieldTest).required(polyglot.t("validation.requiredField")),
  paySchedule: Yup.string().trim().oneOf(PaySchedules(polyglot).map((eachSchedule) => eachSchedule.value)).required(polyglot.t("validation.requiredField")),
  salaryBasis: Yup.string().trim().oneOf(SalaryBasis(polyglot).map((eachBasis) => eachBasis.value)).required(polyglot.t("validation.requiredField")),
  rate: Yup.number().typeError(polyglot.t("validation.selectValid")).min(0, polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")),
  units: Yup.number().nullable().notRequired().when(["paySchedule", "salaryBasis"], {
    is: (paySchedule, salaryBasis) => paySchedule && [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes(salaryBasis),
    then: (schema) => schema.typeError(polyglot.t("validation.selectValid")).min(1, polyglot.t("validation.greaterThanZero")).required(polyglot.t("validation.requiredField"))
  }),
  currency: Yup.string().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField"))
});
export const UserCompensationSchemaForImport = (polyglot) => Yup.object().shape({
  effectiveDate: Yup.string().test(dateFieldTest).notRequired(),
  paySchedule: Yup.string().oneOf(PaySchedules(polyglot).map((eachSchedule) => eachSchedule.value)).notRequired(),
  salaryBasis: Yup.string().oneOf(SalaryBasis(polyglot).map((eachBasis) => eachBasis.value)).notRequired(),
  rate: Yup.number().min(0, "Rate must be a number").notRequired(),
  units: Yup.number().nullable().notRequired(),
  currency: Yup.string().typeError("Currency is required").notRequired()
});
export const UserContractSchema = (polyglot) => Yup.object().shape({
  effectiveDate: Yup.string().test(dateFieldTest).required("Required"),
  contract: Yup.string().oneOf([...UserContractKinds(polyglot).map((c) => c.value), null]).nullable().notRequired(),
  type: Yup.string().oneOf([...UserContractTypes(polyglot).map((c) => c.value), null]).nullable().required("Required"),
  ftePercent: Yup.number().nullable().min(0, "FTE must be greater than or equal 0").max(100, "FTE must be less than or equal to 100").notRequired(),
  publicHolidays: Yup.string().nullable().notRequired(),
  holidayCalendarId: Yup.string().when("publicHolidays", {
    is: (val) => val === CustomCountryEnum.code,
    then: (schema) => schema.required("Holiday calendar is required"),
    otherwise: (schema) => schema.nullable().notRequired()
  }),
  changeReason: Yup.string().nullable().notRequired(),
  entityId: Yup.number().nullable().required("Select a company entity"),
  attendanceScheduleId: Yup.number().integer().nullable().notRequired(),
  contractEndDate: Yup.string().test(dateFieldTest).nullable().notRequired()
});
export const UserContractImportSchema = (polyglot) => Yup.object().shape({
  effectiveDate: Yup.string().test(dateFieldTest).required("Required"),
  contract: Yup.string().oneOf([...UserContractKinds(polyglot).map((c) => c.value), null]).nullable().notRequired(),
  type: Yup.string().oneOf([...UserContractTypes(polyglot).map((c) => c.value), null]).nullable().required("Required"),
  entityName: Yup.string().nullable().notRequired(),
  ftePercent: Yup.number().min(0, "FTE must be greater than or equal 0").max(100, "FTE must be less than or equal to 100").required("Required"),
  holidayCalendar: Yup.string().oneOf(
    PublicHolidayCountryCodes,
    `Holiday calendar must be one of these countries: ${Object.keys(COUNTRY_ISO_CODE_MAPPING).map((countryName) => countryName).join(", ")}`
  ).nullable().notRequired(),
  changeReason: Yup.string().nullable().notRequired(),
  contractEndDate: Yup.date().nullable().when(["effectiveDate"], (effectiveDate, schema) => {
    return schema.min(effectiveDate, "Invalid end date. Please chose a date equal or greater than the effective date.").nullable().notRequired();
  })
});
export const UserAddressSchema = (polyglot) => Yup.object().shape({
  effectiveDate: Yup.string().test(dateFieldTest).required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.effectiveDate") })),
  addressLine1: Yup.string().max(
    30,
    polyglot.t("AddressCard.schema.maxLength", { field: polyglot.t("AddressCard.addressLine1"), maxLength: 30 })
  ).nullable().required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.addressLine1") })),
  addressLine2: Yup.string().max(
    30,
    polyglot.t("AddressCard.schema.maxLength", { field: polyglot.t("AddressCard.addressLine2"), maxLength: 30 })
  ).nullable().notRequired(),
  postcode: Yup.string().max(20, polyglot.t("AddressCard.schema.maxLength", { field: polyglot.t("AddressCard.postcode"), maxLength: 20 })).nullable().required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.postcode") })),
  city: Yup.string().max(30, polyglot.t("AddressCard.schema.maxLength", { field: polyglot.t("AddressCard.city"), maxLength: 30 })).nullable().required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.city") })),
  country: Yup.string().max(50, polyglot.t("AddressCard.schema.maxLength", { field: polyglot.t("AddressCard.country"), maxLength: 50 })).nullable().required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.country") }))
});
export const UserAddressSchemaForImport = (polyglot) => Yup.object().shape({
  effectiveDate: Yup.string().test(dateFieldTest).nullable().notRequired(),
  addressLine1: Yup.string().max(30, polyglot.t("AddressCard.schema.maxLength", { field: "Address line 1", maxLength: 30 })).nullable().notRequired(),
  addressLine2: Yup.string().max(30, polyglot.t("AddressCard.schema.maxLength", { field: "Address line 2", maxLength: 30 })).nullable().notRequired(),
  postcode: Yup.string().max(20, polyglot.t("AddressCard.schema.maxLength", { field: "Post code", maxLength: 20 })).nullable().notRequired(),
  city: Yup.string().max(30, polyglot.t("AddressCard.schema.maxLength", { field: "City", maxLength: 30 })).nullable().notRequired(),
  country: Yup.string().max(50, polyglot.t("AddressCard.schema.maxLength", { field: "Country", maxLength: 50 })).nullable().notRequired()
});
export const UserBankAccountFormSchema = (polyglot) => Yup.object().shape({
  effectiveDate: Yup.string().test(dateFieldTest).required(
    polyglot.t("UserBankInfoForm.schema.requiredField", { field: polyglot.t("UserBankInfoForm.effectiveDate") })
  ),
  accountName: Yup.string().matches(/^[0-9a-zA-Z '.-]*$/, polyglot.t("UserBankInfoForm.schema.limitedNameCharacters")).max(30, polyglot.t("UserBankInfoForm.schema.maxLength", { field: "Account Name", maxLength: 30 })).required(
    polyglot.t("UserBankInfoForm.schema.requiredField", { field: polyglot.t("UserBankInfoForm.accountName") })
  ),
  accountNumber: getAccountNumberValidation(polyglot),
  sortCode: getSortCodeValidation(polyglot),
  bankName: Yup.string().matches(/^[0-9a-zA-Z '.-]*$/, polyglot.t("UserBankInfoForm.schema.limitedNameCharacters")).max(30, polyglot.t("UserBankInfoForm.schema.maxLength", { field: "Bank Name", maxLength: 30 })).required(
    polyglot.t("UserBankInfoForm.schema.requiredField", { field: polyglot.t("UserBankInfoForm.bankName") })
  ),
  country: Yup.string().nullable().required(polyglot.t("UserBankInfoForm.schema.requiredField", { field: polyglot.t("UserBankInfoForm.country") })),
  currency: Yup.string().required(
    polyglot.t("UserBankInfoForm.schema.requiredField", { field: polyglot.t("UserBankInfoForm.currency") })
  )
});
export const UserBankAccountFormSchemaForImport = (polyglot) => Yup.object().shape({
  effectiveDate: Yup.string().test(dateFieldTest).notRequired(),
  sortCode: getSortCodeValidation(polyglot),
  accountName: Yup.string().matches(/^[0-9a-zA-Z '.-]*$/, polyglot.t("UserBankInfoForm.schema.limitedNameCharacters")).max(30, polyglot.t("UserBankInfoForm.schema.maxLength", { field: "Account Name", maxLength: 30 })).notRequired(),
  accountNumber: getAccountNumberValidation(polyglot),
  bankName: Yup.string().matches(/^[0-9a-zA-Z '.-]*$/, polyglot.t("UserBankInfoForm.schema.limitedNameCharacters")).max(30, polyglot.t("UserBankInfoForm.schema.maxLength", { field: "Bank Name", maxLength: 30 })).notRequired(),
  country: Yup.string().nullable().notRequired(),
  currency: Yup.string().required(
    polyglot.t("UserBankInfoForm.schema.requiredField", { field: polyglot.t("UserBankInfoForm.currency") })
  )
});
